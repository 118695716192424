@import './../../../color.scss';

.fakeModal{
  background-color: rgba(0, 0, 0, 0.30);
  position: absolute;
  top:10px;left: 0;
  height: calc(100% - 10px);
  width: 100%;
  z-index: 2;
  padding: 0;
  border-radius:10px 10px 3px 3px;
  .content{
    background: $blanco;
    padding:10px;
    border-radius:5px;
    .title{
      color: $naranjaNivel2;
      padding: 0 0 0 5px;
    }
    .body{
    }
    .foot{
      padding: 10px 0px 0px 0px;
      .btn{margin-left: 20px;}
      .btn2{
        background: $blanco;
        color: $grisNivel3;
        border:1px solid $grisNivel3;
        &:hover{
          color: $naranjaNivel2;
          box-shadow: 0px 0px 10px 1px transparentize($grisNivel2 , .5);
        }
      }
    }
  }
}

.headGris{
  font-size: 15px;
  background: $grisNivel2;
  padding: 5px 10px;
  margin-top:10px;
  font-weight: bold;
  &.mini{ margin: 0 5px; }
}

.notificacion{
    padding: 5px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px transparentize($colorGrisMedio , .5);
    transition: all .5s;
    cursor: pointer;
    margin: 5px;
    &:hover{ box-shadow: 0px 0px 5px 1px transparentize($colorGrisMedio , .5);}
    padding: 5px;

    img{
      height: 55px;
      padding: 10px;
      &.basurero{height: 25px;padding: 0;}
    }

    .titulo{
      font-size: 15px;
      font-weight: bold;
      width: calc(100% - 40px);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .descripcion{
      font-size: 14px;
      line-height: 15px;
      max-height: 60px;
      overflow: hidden;
      white-space: pre-wrap;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;  
    }
    .hace{}
    &.estatus1{background-color: transparentize($grisNivel1,.2);}
    &.estatus2{}

    &.mini{
      img{
        height: 35px;
        margin-right: 5px;
        padding: 0;
        &.basurero{height: 25px;padding: 0;}
      }
      .descripcion{
        padding-left:0px;
        line-height: 17px;
        max-height: 119px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        -webkit-line-clamp: 7!important;
      }
      .info{
        flex-grow: 1;
        max-width: 360px;
      }
      .titulo{
        line-height: 16px;
        max-height: 32px;
        overflow: hidden;
        white-space: pre-wrap;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

  }

  .btn{
    height: 35px;
    padding:10px ;
    border-radius: 5px;
    transition: all .5s;
    background-color:$naranjaNivel2;
    color: white;
    font-size: 14px;
    &:hover{ box-shadow: 0px 0px 10px 1px transparentize($colorGrisBajo,0.5) }
  }