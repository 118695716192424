/* FUENTES */
@font-face{
	font-family: "Poppins-Black";
    src: url("./utilities/fonts/Poppins-Black.ttf");
}
@font-face{
	font-family: "Poppins-BlackItalic";
    src: url("./utilities/fonts/Poppins-BlackItalic.ttf");
}
@font-face{
	font-family: "Poppins-Bold";
    src: url("./utilities/fonts/Poppins-Bold.ttf");
}
@font-face{
	font-family: "Poppins-BoldItalic";
    src: url("./utilities/fonts/Poppins-BoldItalic.ttf");
}
@font-face{
	font-family: "Poppins-ExtraBold";
    src: url("./utilities/fonts/Poppins-ExtraBold.ttf");
}
@font-face{
	font-family: "Poppins-ExtraBoldItalic";
    src: url("./utilities/fonts/Poppins-ExtraBoldItalic.ttf");
}
@font-face{
	font-family: "Poppins-ExtraLight";
    src: url("./utilities/fonts/Poppins-ExtraLight.ttf");
}
@font-face{
	font-family: "Poppins-ExtraLightItalic";
    src: url("./utilities/fonts/Poppins-ExtraLightItalic.ttf");
}
@font-face{
	font-family: "Poppins-Italic";
    src: url("./utilities/fonts/Poppins-Italic.ttf");
}
@font-face{
	font-family: "Poppins-Light";
    src: url("./utilities/fonts/Poppins-Light.ttf");
}
@font-face{
	font-family: "Poppins-LightItalic";
    src: url("./utilities/fonts/Poppins-LightItalic.ttf");
}
@font-face{
	font-family: "Poppins-Medium";
    src: url("./utilities/fonts/Poppins-Medium.ttf");
}
@font-face{
	font-family: "Poppins-MediumItalic";
    src: url("./utilities/fonts/Poppins-MediumItalic.ttf");
}
@font-face{
	font-family: "Poppins-Regular";
    src: url("./utilities/fonts/Poppins-Regular.ttf");
}
@font-face{
	font-family: "Poppins-SemiBold";
    src: url("./utilities/fonts/Poppins-SemiBold.ttf");
}
@font-face{
	font-family: "Poppins-SemiBoldItalic";
    src: url("./utilities/fonts/Poppins-SemiBoldItalic.ttf");
}
@font-face{
	font-family: "Poppins-Thin";
    src: url("./utilities/fonts/Poppins-Thin.ttf");
}
@font-face{
	font-family: "Poppins-ThinItalic";
    src: url("./utilities/fonts/Poppins-ThinItalic.ttf");
}

*{font-family: "Poppins-Regular";}

/* CLASES DE USO GENERAL*/
.limiter{
  width: 85%;
  height: 100%;
}
.flexcenter{
  display: flex;
  align-items: center;
  &.Fspaceb{justify-content: space-between;}
  &.Fspacea{justify-content: space-around;}
  &.Fstart{justify-content:flex-start;}
  &.Fend{justify-content: flex-end;}
  &.Fcenter{justify-content:center;}
}

.flextop{
  display: flex;
  align-items: flex-start;
  &.Fspaceb{justify-content: space-between;}
  &.Fspacea{justify-content: space-around;}
  &.Fstart{justify-content:flex-start;}
  &.Fend{justify-content: flex-end;}
  &.Fcenter{justify-content:center;}
}

.flexend{
  display: flex;
  align-items: flex-end;
  &.Fspaceb{justify-content: space-between;}
  &.Fspacea{justify-content: space-around;}
  &.Fstart{justify-content:flex-start;}
  &.Fend{justify-content: flex-end;}
  &.Fcenter{justify-content:center;}
}

.fww{ flex-wrap: wrap; }



.ant-modal.modalNotPhone{
    width: 95vw!important;
    height:95vh;
    .ant-modal-content{
        width: 100%;
        height: 100%;
    }
}