@import './../../../color.scss';


#inputCosto{
    &::placeholder{
        color:$grisNivel2;
    }
}

.fakemask{
    position:absolute;
    top:0;left: 0;
    width: 100%;
    height: 100%;
    background: transparentize($grisNivel2, .7);
    z-index: 5;
    cursor: not-allowed;
}

.fakeNumber{
    &::-webkit-inner-spin-button,&::-webkit-outer-spin-button{
        -webkit-appearance: none;
        margin: 0; 
    }
}

.markerg{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 35px;
}

.newProductFoot.newSucutFoot{
    .dosBtones{
        display: flex;
        justify-content: center;
    }
}

.findme{
    margin-right: 10px;
    height: 40px;
    width: 40px;
    background-image:url(https://bubook.nibirusystem.com/img/iconos/ic_direccion2.png);
    background-size: 80%;
    cursor: pointer;
    background-color: white;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 4px;
}

.findme2{
    height: 40px;
    width: 40px;
    background-image:url(https://bubook.nibirusystem.com/img/iconos/get_direccion2.png);
    background-size: 80%;
    cursor: pointer;
    background-color: white;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 20px;
    border-radius: 4px;
    
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    span{
        position: absolute;
        top: 120%;left: 60%;
        transform: translate(-50%,-50%);
        display: block;
        height: 20px;
        width: 120px;
        font-size: 13px;
        line-height: 13px;
        background-color: white;
        border-radius: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $naranjaStart;
        color: white;
    }
}

.masker{
    position: absolute;
    top: 0;left: 0;
    transition: all 2s;
    height: 100%;
    width: 100%;
    background: rgba(0,0,0,.5);
    z-index: 2;
}

.gm-style-cc{ z-index: -99999; display: none;}
.gm-fullscreen-control { z-index: -99999; display: none;  }

.myrange {
    height: 25px;
    -webkit-appearance: none;
    margin: 10px 0;
    width: 100%;
    border: none!important;
    box-shadow: none;
    cursor: pointer;
}

.contSwitch{
    position: relative;
    .panelAux{
        cursor: pointer;
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: none;
        z-index: 2;
    }
}

#formulasucursalGogle,#formulasucursalContactos{
    padding: 30px;
}

.myrange::-webkit-slider-runnable-track {
width: 100%;
height: 2px;
background: $colorGrisMuyBajo;
border-radius: 1px;
}
.myrange::-webkit-slider-thumb {
    border: 1px solid $naranjaEnd;
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: $naranjaStart;
    -webkit-appearance: none;
    margin-top: -9px;
}

.labelValueRange{
    position: absolute;
    transform: translate(-50%,-50%);
    top: 105%;left: 50%;
}

.contSucursal{
    width: 80%;
    max-width: 1400px;
    margin: 20px auto;

    position:relative;

    box-shadow: 0px 0px 5px 2px $grisNivel2;
    border-radius:10px;

    .titleSucursal{
        text-align: center;
        margin: 30px 0;
        color: $naranjaEnd;
        font-size: 23px;
    }
    .subtitleForm{
        font-size: 17px;
        font-weight: bolder;
        color: $colorGrisFuerte;
        display: block;
    }
    .subtitleForm-span{
        display: block;
        text-align: left;
        color: $colorGrisMedio;
        margin-top: 10px;
        margin-bottom: -10px;
    }
    form{ padding: 20px; }
    .inputs-group{
        position: relative;
        .ant-select.ant-select-enabled,
        .ant-select.ant-select-open.ant-select-focused.ant-select-enabled,
        .ant-select.ant-select-focused.ant-select-enabled{
            .ant-select-selection.ant-select-selection--single,.ant-select-selection--multiple{
                box-shadow: none;
                border: none;
                outline: none;
                border-bottom: 1px solid $colorGrisBajo;
                border-radius: 0;
                &:focus{ border-bottom: 2px solid $naranjaEnd; }
            }
            svg{
                fill: $naranjaEnd;
                height: 20px;
                width: 15px;
            }
        }
    }
    .inputs-group{
        display: block;
        margin-top: 20px;
        position: relative;
        transition: all .3s;
        .inputs-label{
           font-size: 12px;
           font-weight: bold;
           color: $colorGrisFuerte;
        }
        .inputs-input{
            margin-top: 10px;
            display: block;
            font-size: 14px;
            height: 30px;
            width: 100%;
            border:none;
            outline: none;
            position: relative;
            padding-left: 10px;
            border-bottom: 1px solid $colorGrisMedio;
            transition: all .3s;
            color: $colorGrisMedio;
            &:focus{
                border-bottom-color: $naranjaEnd; border-bottom-width: 2px;
            }
        }
        .inputs-btn{
            margin: 5px auto;
            height: 35px;
            width: 170px;
            border-radius: 5px;
            border: 1px solid $colorGrisMuyBajo;
            box-shadow: 0px 0px 10px 0px $colorGrisMuyBajo;
            border-radius: 5px;
            text-align: center;
            transition: all .5s;
            background-image: linear-gradient(to right, $naranjaStart , $naranjaEnd );
            color: white;
            font-size: 15px;
            &:hover{ box-shadow: 0px 0px 5px 3px transparentize($colorGrisBajo,0.5) }
            &:disabled{
                cursor:not-allowed;
                transform: scale(.95);
            }
            &.inputs-btn2{
                background: none;
                color: $colorGrisBajo;
                font-weight: bolder;
                opacity: .7;
                margin-top: -5px;
                margin-bottom: 30px;
                &:hover{ color: $naranjaEnd}
            }
            &.inputs-btn1{ font-weight: bold; }
        }
    }
    .inputs-group.map{
        height: 400px;
        width: 100%;
        position: relative;
    }
    .fechaSexo{
        display: flex;
        justify-content: space-between;
        .inputs-group.inputs-group-inline{ 
            flex-grow: 1;
            &:first-child{
                margin-right: 15px;
            }
        }
    }
    .inputs-groupSwitch{
        margin-right: 15px;
        .myswitch{
            &.ant-switch-checked{
                background-color:#22b473;
            }
            &.ant-switch-disabled{
                cursor: pointer;
                opacity:1;
                &::after{cursor: inherit;}
            }
        }
        .inputs-label{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .mytimers{
            margin-top: 5px;
            display: none;
            align-items: center;
            justify-content: space-around;
            .ant-time-picker{
                box-shadow: none;
                border: none;
                input{
                    border: none;
                    border-bottom: 1px solid $colorGrisBajo;
                    border-radius: 0;
                }
            }
        }
    }
    .inputs-btn{margin-bottom: 10px;}
    .fatherContRadios{
        margin-top: 20px;
        .contRadios{
            margin-bottom: 10px;
            .ask-Radios{
                font-size: 12px;
                color: $colorGrisFuerte;
                display: block;
                font-weight: bold;
            }
            .labelForRadio{
                font-size: 12px;
                font-weight: bold;
                align-self: center;
                margin-left: 10px;
            }
            .radiocontrol{
                display: flex;
                justify-content:flex-start;
                align-items: center;
                padding: 10px;
                .radiocontrol1{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-right: 15px;
                }
                .radiocontrol2{
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    width: 50%;
                }
            }
            .radiocontrol3{
                margin-left: 10px;
            }
        }
        .inputIncrustado{
            display: block;
            font-size: 14px;
            height: 30px;
            width: 80%;
            border:none;
            outline: none;
            position: relative;
            border-bottom: 1px solid $colorGrisMedio;
            transition: all .3s;
            margin-bottom: 5px;
            text-align: center;
            &:focus{
                border-bottom-color: $naranjaEnd;
                border-bottom-width: 2px;
            }
        }
    }
}
/*lista sucursales*/
.cont-sucursales{
    position: relative;
    top: 0;left: 0;
    height: 100%;
    width: 100%;
}
.cont-sucursales2{ height: 100%; }

.cont-sucursales3{
    height: 100%;
    overflow: auto;
    &::-webkit-scrollbar { width: 8px; }
    &::-webkit-scrollbar-button{ background:white; }
    &::-webkit-scrollbar-track {background:white;} 
    &::-webkit-scrollbar-thumb { background-color:$naranjaEnd; border-radius: 10px;}

}
.tituloPanel{
    color: $naranjaEnd;
    font-size: 23px;
    text-align: left;
    width: 80%;
    max-width: 1400px;
    margin: auto;
    padding: 5px 10px;
}
.cont-instancias-Suc{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 10px auto;
    width: 80%;
    max-width: 1400px;
    margin-bottom: 50px;
    .tarjetaSucursal{
        transition: all .5s;
        cursor: pointer;
        border-radius: 5px;
        height: auto;
        width: 230px;
        margin: 5px 10px;
        box-shadow: 0px 0px 5px $colorGrisMedio;
        position: relative;
        padding: 15px 5px;
        &:hover{
            &:hover{ box-shadow: 0px 0px 5px 1px transparentize($colorGrisMedio , .5); }
            .options{
                i{ svg{ fill:$naranjaStart; } }
            }
        }

        img{
            height: 20px;
            margin: 0 5px;
            &.suci{
                border-radius: 100%;
            }
        }

        .metaInfo{
            display: flex;
            align-items: center;
            justify-content:flex-start;
            height: 25%;
            margin: 5px 0;
            .spnNombre{
                width: 100%;
                font-size: 14px;
                line-height: 17px;
                max-height: 17px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
            &.col{
                align-items: center;
                overflow: hidden;
                text-overflow: ellipsis!important;
                width: 100%;
                height: 90px;
                white-space:normal;
            }
            .direSuc{
              //background: red;

              white-space: pre-wrap;
              display: -webkit-box;
              -webkit-line-clamp: 4;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
            }
        }
        .srcSucursalNew{
            display: block;
            margin:30px auto
        }
        .labelNew{
            display: block;
            margin:auto;
            text-align: center;
            color: $colorGrisBajo;
            font-size: 15px;
            font-weight: bold;
        }
        .options2{
            position: absolute;
            top: 50px;left: 92%;
            transform: translate(-50%,-50%);
            z-index: 20;
            cursor: pointer;
        }
        .options{
            position: absolute;
            top: 15px;left: 92%;
            transform: translate(-50%,-50%);
            z-index: 20;
        }
        &.new{ height: 210px; }
    }
}
.pop-Suc{
    .ant-popover-inner{
        .ant-popover-title{
            font-weight: bold;
            text-align: center;
        }
        .ant-popover-inner-content{
            padding: 0;
        }
    }
    .op-Suc{
        display: block;
        padding: 1px;
        label{
            padding: 2px 5px;
            display: block;
            cursor: pointer;
            &:hover{
                background-color: transparentize($colorGrisMuyBajo, $amount: 0.8);
                color: $naranjaEnd;
            }
        }
    }
}

.modalGMaps{
    width:100%!important;
    max-width: 800px;
    &.modalGMaps2{
        max-width: 500px;           
    }
    .ant-modal-content{  }
    .ant-modal-header{
        padding: 0;
        .ant-modal-title{
            color: $naranjaEnd;
            font-size: 17px;
            text-align: center;
            font-weight: bold;
            padding: 15px;
        }
    }
    .ant-modal-body{padding: 0;}
    .ant-modal-footer{
        padding:10px;
    }
}


.contentModalMapa{
    padding: 10px;
    .divmap{
        height: 300px;
        width: 100%;
        position: relative;
    }

     .inputs-group{
        display: block;
        position: relative;
        transition: all .3s;
        margin-top: 10px;
        .inputs-label{
           font-size: 12px;
           font-weight: bold;
           color: $colorGrisMedio;
           &.labelValueRange{
                position: relative;
            }
        }
        .inputs-input{
            display: block;
            font-size: 14px;
            height: 30px;
            width: 100%;
            min-width: 300px;
            border:none;
            outline: none;
            position: relative;
            padding-left: 10px;
            border-bottom: 1px solid $colorGrisMedio;
            transition: all .3s;
            color: $colorGrisMedio;
            &:focus{ border-bottom-color: $naranjaEnd; border-bottom-width: 2px; }
        }
        .myrange{
            //width: 70%;
            min-width: 300px;
            display: block;
        }   
    }

    .inputs-groupSwitch{
            margin-right: 15px;
            .myswitch{
                &.ant-switch-checked{
                    background-color:#22b473;
                }
                &.ant-switch-disabled{
                    cursor: pointer;
                    opacity:1;
                    &::after{cursor: inherit;}
                }
            }
            .inputs-label{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            .mytimers{
                margin-top: 5px;
                display: none;
                align-items: center;
                justify-content: space-around;
                .ant-time-picker{
                    box-shadow: none;
                    border: none;
                    input{
                        border: none;
                        border-bottom: 1px solid $colorGrisBajo;
                        border-radius: 0;
                    }
                }
            }
        }
}


.miunidad2{
    box-shadow: 0px 0px 10px 0px $colorGrisMuyBajo;
    transition: all .5s;
    border-radius: 5px;
    margin: 5px;
    padding: 5px;
    height: 35px;
    width: 250px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:hover{
        box-shadow: 0px 0px 10px 5px $colorGrisMuyBajo;
        color: $naranjaEnd;
        cursor: pointer;
    }
    .nombre{
        display: block;
        width: 60%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .delete{
        cursor: pointer;
        width: 25px;
        img{height: 25px;}
    }
    .gratis{
        width: 40%;
        font-weight: bold;
        color: #22B573;
    }
    .nogratis{
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 40%;
    }
}


.myAutocomplete{
    width: 100%!important;
    .inputs-input.ant-select-search__field{ border-radius:0;}
    .ant-select-selection.ant-select-selection--single{
        border-bottom:1px solid black!important;
        margin-top: 8px;
    }
    .ant-select-selection__rendered{}
}