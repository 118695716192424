@import './../../../color.scss';
.subGrafica{
	margin: 10px 0;
	padding: 5px;
	.titleGrafica{
		font-size: 17px;
		color: $naranjaEnd;
		display: block;
	}

	.ant-select.ant-select-enabled,
    .ant-select.ant-select-open.ant-select-focused.ant-select-enabled,
    .ant-select.ant-select-focused.ant-select-enabled{
        .ant-select-selection.ant-select-selection--single,
        .ant-select-selection--multiple{
            box-shadow: none;
            border: none;
            outline: none;
            border-bottom: 1px solid $colorGrisMedio;
            border-radius: 0;
            font-size: 14px;
            width: 250px;
            margin: 10px 0;
            &:focus{border-bottom: 2px solid $naranjaEnd;}
        }
        svg{
            fill: $naranjaEnd;
            height: 20px;
            width: 15px;
        }
    }
    .metadata{
        .meta{
            background: $colorGrisMuyBajo;
            padding: 5px;
            text-align: center;
            font-weight: bold;
            font-size: 16px;
        }
        .data{
            padding: 5px;
            text-align: left;
            font-size: 16px;
        }
        &:nth-child(odd){
            background: rgba(0,0,0,.05);
        }
    }
    .tablaHead{
    	background: $colorGrisMuyBajo;
    	padding: 5px;
    	text-align: center;
    	font-weight: bold;
    	font-size: 16px;
    }
    
    .tablaBody{
		padding: 5px;
    	text-align: center;
    	font-size: 16px;
        transition: all .5s;
        .notOverflow{
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        .nombre{
            text-align: left;
            padding-left: 20px;
        }
        &:nth-child(odd){
            background: rgba(0,0,0,.05);
        }
	}

    .metChart{
    	display: flex;
    	.meta{
    		flex-grow: 1;
    		text-align: center;
    		font-size: 15px;
    		margin: 10px auto;
    		.orangeM{ font-weight: bold; color: $naranjaEnd;}
    		.orangeH{ color: $naranjaStart; font-weight: bold;}
    	}
    }
}
.datosPublico{
	display: flex;
	background-color: rgba(0,0,0,.05);
	padding: 20px;
	.info{
		flex-grow: 1;
		text-align: center;
		font-size: 15px;
		.number{ font-weight: bold; }
		.txt{}
	}
    &.empty{margin: 5px;}
}

.chart{
	width: 80%;
	max-width: 800px;
	max-height: 400px;
	margin: auto;
}


@media screen and (max-width: 920px){
    .colinle{
        width: 100%!important;
        .titleGrafica{text-align: left!important;}
    }
}

@media screen and (max-width: 520px){
    .tablaHead{font-size: 14px!important; }
    .tablaBody{font-size: 14px!important; }
}

.colinle{
    width: 50%;
    min-width: 300px;
    .titleGrafica{
        text-align: center;
        &.titleGraficaleft{
            text-align: left;
        }
    }
}
.inlineinline{
    display: flex!important;
    flex-wrap: wrap;
}

.tablainlinePapa{}

.tablainline{
    margin: 5px;
    max-height: 542px;
    overflow-y: auto;
    transition: height 1s;
    &::-webkit-scrollbar { width: 8px; }
    &::-webkit-scrollbar-button{ background:white; }
    &::-webkit-scrollbar-track {background:white;} 
    &::-webkit-scrollbar-thumb { background-color:$colorGrisBajo; border-radius: 10px;}
}

.spanver{
    text-decoration: underline;
    font-size: 15px;
    font-weight: bold;
    text-align: right;
    width: 95%;
    display: block;
    cursor: pointer;
}