@import '../color.scss';


#down{
  display:none;
  opacity:0;
  visibility: hidden;
}
.mainswitchGroup{

}
.switchGroup-enter{
   opacity: 0;
}
.switchGroup-exit{
   opacity: 1;
}
.switchGroup-enter-active{
   opacity: 1;
}
.switchGroup-exit-active{
   opacity: 0;
}
.switchGroup-enter-active,
.switchGroup-exit-active{
   transition: opacity 500ms;
}

.wapperIndex{
  background-color: $grisNivel1;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
  transition: all .5s;
  &::-webkit-scrollbar{ width:10px; }
  &::-webkit-scrollbar-button{ display: none; }
  &::-webkit-scrollbar-track {background:#F3F0F0;} 
  &::-webkit-scrollbar-thumb { background-color:#dad8d8; &:hover{ background-color:#8F969B;} }
}

.contentIndexWrapper{
  //background-color: deepskyblue;
}

.headIndex{
  .orangeBar{
    height: 60px;
    background-image: linear-gradient(to right,$naranjaStart,$naranjaNivel2);
    .groupBar1{
      height: 100%;
      padding-right:20px;
      position: relative;
      /*&:after{
        content: '';
        display: block;
        position: absolute;
        top: 20%; left: 100%;
        height: 60%;
        width: 2px;
        background: $blanco;
      }*/
    }
    .groupBar2{
      height: 100%;
      padding-left:20px;
      flex-grow: 1;
    }
  }
  
  .mediumBar{height: 10px;background-color: $naranjaNivel2;}

  .blackBar{ background-color: $grisNivel5; }
  
  .searchResult{
    position: absolute;
    top: 100%;left: 50%;
    transform: translate(-50%,0%);
    width: 100%;
    background: white;
    border-radius:5px;
    padding: 5px;
    max-height: 400px;
    z-index: 2;
    padding: 0;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar{width: 12px;background-color:$blanco; }
    &::-webkit-scrollbar-button{ display: none; }
    &::-webkit-scrollbar-track, &::-webkit-scrollbar-thumb{
      border:3px solid rgba(255,255,0,0);
      background-clip: padding-box;
    }
    
    &::-webkit-scrollbar-track{ background-color: transparentize($grisNivel5, .5);}
    &::-webkit-scrollbar-thumb{
      background-color:$grisNivel5;
      border-radius: 10px;
    }
  }

  .listResults{
    padding: 5px;
    cursor: pointer;
    img{
      border-radius: 100%;
      height: 40px!important;
      margin-right: 10px;
    }
    &:hover{
      background: $grisNivel1;
    }
  }

  .noResults{
    padding: 5px 10px;
    display: block;
    text-align: center;
  }


  .searchInput{
    position: relative;
    background-color: $grisNivel1;
    padding: 5px 10px;
    border-radius: 20px;
    transition: all .5s;
    border: 1px solid transparent;
    &:hover{background-color: $grisNivel2;}
    &.active{
      background-color: $grisNivel1;
      border: 1px solid $grisNivel3;
      &:hover{background-color:$blanco;}
    }
    .searchResult{display: none;}
    i.anticon{
      font-size: 15px;
      font-weight: bold;
    }
    input{
      font-size: 14px;
      border: none;
      outline: none;
      background: none;
      margin-left: 5px;
      width: 300px;
      width: 25vw;
      transition: all .5s;
    }
  }
  
  .itemOrange{
    img{
      height: 70px;cursor: pointer;
      &.logo{transform: translateX(-20px);}
      &.bell{height: 30px;}
    }

    .carrito{
      fill:$blanco;
      height: 25px;
      margin: 0 20px;
      transform: translateY(3px);
      position: relative;
      cursor: pointer;
    }

    .coraFavs{
      fill:red;
      stroke:$grisNivel5;
      stroke-width:5px;
      height: 25px;
      margin: 0 10px;
      transform: translateY(3px);
      position: relative;      
    }

    .dividerBar{
      width: 2px;
      height: 40px;
      background: $blanco;
      display: block;
      margin: 0 10px;
    }
  }

  .barAnchor{width: 100%;}
  .itemBlack{
    height: 35px;
    color: $blanco;
    cursor: pointer;
    font-size: 14px;
    transition: all .3s;
    i{svg{fill:$naranjaNivel2;}margin-left: 5px;}
    &:hover{color:$naranjaNivel2;}
  }
}

.myBadge{
  cursor: pointer;
  sup{ background: $grisNivel5; }
  &.lime{
    sup{ background: #5ac18e;  }
  }

  &.fter{
    position:absolute;
  }
}

.carrousel{
  $ancho: unquote('min(100vw,1920px)');
  $alto:  calc( #{$ancho} * 0.19739583333 );
  width: $ancho;
  height: $alto;
  position: relative;
  margin: auto;
  overflow: hidden;
  &:hover{
    .control{opacity: 1;}
  }
  .control{
    height: 100px;
    width: 50px;
    background-color: $naranjaNivel2;
    position:absolute;
    top: 50%;
    transform: translateY(-50%);
    //left: 50%;
    cursor: pointer;
    transition: all .5s;
    opacity: 0;
    z-index: 2;
    border-radius: 30%;
    &.cotrolLeft{ left: -11px;}
    &.cotrolRigth{left: calc(100% - 55px); }
    i{ 
      font-size: 25px;
      svg{
        fill:$blanco; stroke-width:50; stroke:white;
      }
      transition: all .3s;
    }
    &:hover{i{transform: scale(1.3);}}
  }
  .carrouselContent{
    width:100%;
    height: 100%;
    background: salmon;
  }
}

.banner{
  .holiboli{
    position: relative;
    $ancho: unquote('min(100vw,1920px)');
    $alto:  calc( #{$ancho} * 0.19739583333 );
    width: $ancho;
    height: $alto;
    &.help{
      display: flex!important;
      justify-content: center;
    }
    img{
      transition: all .5s;
      position: absolute;
      top:50%;left: 50%;
      transform: translate(-50%,-50%);
      width: 100%;
      display: block;
      &.holiboli2{
        position: relative!important;
        width: 420px!important;
        border-radius: 10px!important;
        transition: all .0s!important;
        top:initial; left:initial;
        transform: none;
      }
      //height: 100%;
    }
  }
  .slick-dots{
    &.slick-dots-bottom{
      bottom:20px!important;
    }
    li{
      button{
        background: white!important;
        opacity: 1!important;
        border-radius: 100%!important;
        width: 8px!important;
        height: 8px!important;
        margin: 0 5px;
        border:1px solid $grisNivel1!important;
      }
      &.slick-active{
        button{ background: $naranjaNivel2!important; }
      }
    }
  }
}

.btnBlack{
  background-color:$grisNivel5;
  font-size: 14px;
  color: $blanco;
  font-family: "Poppins-Regular";
  padding: 7px 35px;
  border-radius: 20px;
  transition: all .5s;
  &:hover{color:$naranjaNivel2;}
}

.cascaderWithIcon{
  background: $blanco;
  border-radius: 20px;
  padding: 0 10px;
  .MyIcon{
    height: 20px!important;
    fill:$naranjaNivel2!important;
  }

  .cascaderCity{
    @include clearBorder;
    border-radius: 20px!important;
    span{ @include clearBorder; }
    input{
      @include clearBorder;
      &::placeholder{
        text-align: center;
        font-family: "Poppins-Medium";
        color:$grisNivel5;
      }
    }
    i{display: none;}
  }
  &.inverted{
    background: $grisNivel2;
    border-radius: 5px!important;
    .MyIcon{
      fill:$naranjaNivel2!important;
    }
    .cascaderCity{
      border-radius: 5px!important;
      background: none;
      width: 100%;
      color:$grisNivel5;
      input::placeholder{
        text-align: center;
        font-family: "Poppins-Medium";
        //color:$grisNivel5;
        color:red;
      }
    }
  }
}

.cascaderCityOptions{
  .ant-cascader-menu{
    &:nth-child(1){
      background-color:$grisNivel5;
      svg{fill:$blanco;}
    }

    &:nth-child(2){
      color:$grisNivel5;
      .ant-cascader-menu-item.ant-cascader-menu-item-active{color: $grisNivel5;}
    }

    .ant-cascader-menu-item{
      background: none;
      &:hover{background: none;}
      &.ant-cascader-menu-item-expand{color: $blanco;}
      &.ant-cascader-menu-item-active{
        color: $naranjaNivel2;
        background: none;
      }
    }
  }
}

@keyframes prox {
  0%{transform: translateY(-5px);}
  50%{transform: translateY(5px);}
  100%{transform: translateY(-5px);}
}

.triggerPerfil{
  padding: 5px;
  cursor: pointer;
  img{
    width: 40px!important;
    height: 40px!important;
    border-radius: 100%;
  }

  span{
    color:$blanco;
    font-family: "Poppins-SemiBold";
    font-size: 14px;
    max-width: 100px;
    display:block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0 10px;
  }
  .anticon{
    font-size: 17px!important;
    svg{fill:$grisNivel5!important;}
  }
}

.pageSection{
  position: relative;
  padding-bottom: 20px;

  .starCalf{
    width: 15px;
    transform: translate(-5px,-1px);
    fill:$amarillo;
  }

  .limiter{margin: auto;}
  .titleSection{
    text-align: center;
    margin: 20px 0;
    transition: all .5s;
    font-size: 40px;
    .light{color: $naranjaNivel2;font-family: "Poppins-Bold";}
    .dark{color: $grisNivel5;font-family: "Poppins-Bold";}
  }
  .descSection{
    text-align: center;
    font-family: "Poppins-SemiBold";
    color:$grisNivel5;
    margin-bottom: 20px;
  }
  .separadorRects{
        height: 20px;
        margin: 15px 0;
        div{background: black; margin: 0 5px;}
        .rect1{ width: 10px; height: 6px; border-radius: 2px;}
        .rect2{ width: 30px; height: 6px; border-radius: 2px;}
        .rect3{ width: 60px; height: 6px; border-radius: 2px;}
  }
  .contentSection{


    .iconInteract{
      cursor: pointer;
      position: absolute;
      top: 100%;left: 50%;
      i{font-size: 30px;svg{fill:$naranjaNivel2;}}
    }
    position: relative;

    .txtTemporal{
      text-align: center;
      margin: 20px 0;
      transition: all .5s;
      font-size: 40px;
      font-family: "Poppins-Regular";
      color: $grisNivel5;
      font-style: italic;
      animation-name: prox;
      animation-duration: 2s;
      transition: all .5s;
      animation-iteration-count: infinite;
    }
    
    .txtNota{
      font-family: "Poppins-SemiBold";
      color: $grisNivel5;
      font-size: 14px;
    }

    position: relative;   
    .tarjetaVerticalEmpresa{
      background-color: $blanco;
      border-radius: 10px;
      width: 170px;
      height: 265px;
      display: inline-block;
      margin: 0 20px;
      cursor: pointer;
      .head{
        font-family: "Poppins-SemiBold";
        font-size: 14px;
        height: 30px;
        .topPlace{
          background-color: $naranjaNivel2;
          color:$blanco;
          width: 60%;
          border-top-left-radius: 10px;
          font-family: inherit;
          height: 100%;
        }
        .calf{
          width: 40%;
          font-family: inherit;
          color: $grisNivel5;
          height: 100%;
        }
      }
      .contentCard{
        img{
          width: 120px;
          display: block;
          margin:10px auto;
          border-radius: 5px;
          box-shadow: 0px 0px 5px $colorGrisBajo;
        }
        .nombreCard{
          font-family: "Poppins-SemiBold";
          font-size: 14px;
          line-height: 16px;
          color:$grisNivel5;
          width: 120px;
          height: 32px;
          
          margin:auto;

          white-space: pre-wrap;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .catCard{
          font-family: "Poppins-Medium";
          font-size: 11px;
          line-height: 12px;
          //max-height: 24px;
          height: 24px;
          width: 120px;
          margin: auto;
          color: $grisNivel3;

          margin-top: 3px;
          white-space: pre-wrap;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .catFrom{
          font-family: "Poppins-Light";
          font-size: 11px;
          line-height: 12px;
          width: 120px;
          color: $grisNivel3;
          margin: 5px auto;
          
          .notshow{
            display: none!important;
            visibility: hidden!important;
            opacity: 0!important;
          }

          .loading{
            font-size: 20px;
            svg{fill:$naranjaNivel2;}
          }

        }
      }
    }

    .tarjetaHorizontalEmpresa{
      background-color: $blanco;
      padding: 10px;
      margin: 10px;
      border-radius: 5px;
      box-shadow: 0px 0px 3px $colorGrisBajo;
      cursor: pointer;
      .leftCard{
        height: 90px;
        width: 90px;
        border-radius: 5px;
        img{width: 100%;border-radius: 5px;}
      }
      .contentCard{
        height: 90px;
        margin-left: 10px;
        .head{
          .nombreCard{
            font-family: "Poppins-SemiBold";
            font-size: 14px;
            line-height: 16px;
            color:$grisNivel5;
            width: 120px;
            height: 32px;

            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .calf{
              font-family: "Poppins-SemiBold";
              color: $grisNivel5;
              font-size: 11px;
          }
        }
        .catCard{
          font-family: "Poppins-Medium";
          font-size: 11px;
          line-height: 12px;
          height: 45px;
          width: 120px;
          color: $grisNivel3;
          
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .footCard{
          .catFrom{
            font-family: "Poppins-Light";
            font-size: 11px;
            line-height: 12px;
            color: $grisNivel3;
          }
          .notshow{
            display: none!important;
            visibility: hidden!important;
            opacity: 0!important;
          }
          .loading{
            font-size: 20px;
            svg{fill:$naranjaNivel2;}
          }
        }
      }
    }

    .corazon{
      width: 18px;
      height: 18px;
      stroke:$naranjaNivel2;
      stroke-width:10;
      cursor:pointer;
      fill:none;
      z-index: 99;
      &.filled{fill: $naranjaNivel2;}
    }

  }
  .innerContentSection{
    width: 90%;
    margin: auto;
    overflow-x: auto;
    padding:10px 0;
    white-space: nowrap;
    &::-webkit-scrollbar {display: none;}
    scroll-behavior: smooth;
    text-align: center;
  }
  .control{
    position:absolute;
    top: 50%;
    cursor: pointer;
    z-index: 2;
    &.cotrolLeft{ left: 0; border-radius: 0 20% 20% 0; }
    &.cotrolRigth{ border-radius: 20% 0 0 20%; left:calc(100% - 50px); }
    i{ font-size: 30px;svg{fill:$naranjaNivel2;} transition: all .3s; }
    &:hover{i{transform: scale(1.3);}}
  }
}

.popCateg{
  background: none;
  &.ant-popover-placement-bottomLeft{ .ant-popover-arrow{ border-top-color: $grisNivel5!important; border-left-color: $grisNivel5!important; } }
  .ant-popover-inner{padding: 0;border-radius: 10px;}
  .ant-popover-inner-content{padding: 0;border-radius: 10px;}
  .contenedorCategorias{
    border-radius: 10px;
    //width: min-content;
    height: 500px;
    overflow: hidden;
    .contBlack{
      width: 250px;
      height: 100%;
      background-color: $grisNivel5;
      color: $blanco;
      border-radius: 10px;
      padding: 15px;
      .contBlackContent{
        width: 100%;
        height: 100%;
        background: none;

        overflow-y: auto;
        &::-webkit-scrollbar { width: 4px; }
        &::-webkit-scrollbar-button{ display: none; }
        &::-webkit-scrollbar-track {background:transparentize($blanco,.6);} 
        &::-webkit-scrollbar-thumb { background-color:$blanco; border-radius: 10px;}
      }
      .itemCategoria:hover{color:$naranjaNivel2;cursor: pointer;}
    }
    .contWhite{
      height: 100%;
      width: 300px;
      color: $grisNivel4;
      border-radius: 0 10px 10px 0;
      padding: 15px;
      .contWhiteContent{
        height: 100%;
        width: 100%;
        background: $blanco;
        overflow-y: auto;
        &::-webkit-scrollbar { width: 4px; }
        &::-webkit-scrollbar-button{ display: none; }
        &::-webkit-scrollbar-track {background:transparentize($grisNivel5,.6);} 
        &::-webkit-scrollbar-thumb { background-color:$grisNivel5; border-radius: 10px;}
      }
      .title{
        font-size: 20px;
        border-bottom: 1px solid $grisNivel5;
        margin-bottom: 10px;
        padding-left: 5px;
      }
    }
    .itemCategoria{
      font-family: "Poppins-Regular";
      font-size: 14px;
      height: 45px;
      cursor: pointer;
      padding-right: 10px;
      &:hover{color:$grisNivel5;}
    }
  }
}

.popPerfil{
  background: none;
  &.rd{ .ant-popover-inner{padding: 0;border-radius: 10px;} }
  .ant-popover-inner{padding: 0;border-radius: 0px;}
  .ant-popover-inner-content{padding: 0;border-radius: 0px;}
  .contenedorCategorias{
    width: max-content;
    overflow: hidden;
    padding: 0;
    &.wior{ overflow: initial; }
    .contWhite{
      height: 100%;
      color: $grisNivel5;
      padding: 0px;
      .contWhiteContent{
        height: 100%;
        width: 100%;
        background: none;
      }
    }
    .itemCategoria{
      font-family: "Poppins-Regular";
      font-size: 14px;
      padding: 10px 15px;
      transition: all .5s;
      cursor: pointer;
      &:hover{
        background-color: $grisNivel5;
        color:$blanco;
      }
    }
    .separador{
      height: 1px; background: transparentize($grisNivel5, .5);
      margin: 5px 0;
      &.nmg{margin:0;}
    }

    .conTiposEntrea{
      background: $grisNivel5;
      position: relative;
      &:before{
        content: '';
        height: 15px;
        width: 15px;
        position: absolute;
        top:-15px;right: 5px;
        clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
        background: $grisNivel5;
      }
      .conTiposEntreaContent{
        color:$blanco;

      }
      .intemEntrega{
        transition:all .5s;
        padding: 10px 15px;
        cursor: pointer;
        &:hover{background: $blanco;color:$grisNivel5;}
        &.selected{
          color:$naranjaNivel2;
        }
      }
    }
    
  }
}

.popReg{
  background: none;
  width: 300px;
  &.ant-popover-placement-bottom{.ant-popover-arrow{ border-top-color: $grisNivel1!important; border-left-color: $grisNivel1!important; } }
  .ant-popover-inner{padding: 0;border-radius: 0px;}
  .ant-popover-inner-content{padding: 0;border-radius: 0px;}
  .contenedorCategorias{
    width:100%;
    overflow: hidden;
    .contWhite{
      height: 100%;
      background: $grisNivel1;
      color: $grisNivel5;
      .contWhiteContent{
        height: 100%;
        width: 100%;
        background: none;
      }
    }
    .itemCategoria{
      font-family: "Poppins-Regular";
      font-size: 14px;
      padding: 10px 15px;
      cursor: pointer;;
      &:hover{
        background-color: $grisNivel2;
        color:$blanco;
      }
    }
  }
}

.modalBaseBuBooker{
  &.mxw4{ width: 600px; }
  &.mxw3{
    max-width: 400px;
  }
  &.ftrblack{
    .ant-modal-footer{ background: $grisNivel1; border-radius: 15px; }
  }
  .ant-modal-content{ border-radius: 10px;}
  .ant-modal-body{ padding: 0; }
  .ant-modal-footer{ border: none; }
  .btn{
      height: 35px;
      width: 150px;
      border: 1px solid $colorGrisMuyBajo;
      box-shadow: 0px 0px 5px 0px $colorGrisMuyBajo;
      border-radius: 5px;
      transition: all .5s;
      background-image: linear-gradient(to right, $naranjaStart , $naranjaEnd );
      background-color: $naranjaNivel2;
      color: white;
      font-size: 12px;
      font-family: "Poppins-Medium";
      cursor: pointer;
      &:hover{
        box-shadow: 0px 0px 5px 1px transparentize($colorGrisBajo,0.5);
        border-color: $colorGrisMuyBajo;
        background-image: linear-gradient(to right, $naranjaStart , $naranjaEnd );
      }
      &.btn1{font-weight: bold;}
      &.btn2{
        background-image: none;
        background-color: $blanco;
        color: $colorGrisBajo;
        font-weight: bolder;
        opacity: .7;
        &:hover{ color: $naranjaEnd}
      }
  }
  &.mxw4{ .ant-modal-content{ width: 600px; background: $grisNivel1;} }
}

.coberturasSelector{
  padding: 10px 10px 15px 10px;
  position: relative;
  .head{
    padding:10px 15px;
    padding-bottom: 0;
    color: $naranjaNivel2;
    h3{
      color: $naranjaNivel2;
      font-size: 16px;
      font-family: "Poppins-Medium";
      letter-spacing: 1px;
      text-align: center;
    }
    .iconCloseModal{
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 12px;
      cursor: pointer;
      background:transparentize($grisNivel5, 0);
      padding: 5px;
      border-radius: 100%;
      svg{fill:white;}
    }
  }
  .inputs-group{
    padding: 0px 15px;
    margin-bottom: 10px;
    label{
      display: block;
      font-family: "Poppins-SemiBold";
      color: $grisNivel5;
      font-size: 14px;
    }

    .ant-input.inputs-input{
      @include clearBorder;
      border-radius: 0!important;
      border-bottom: 1px solid $grisNivel3!important;
      transition:  all .5s;
      &:focus{ border-bottom: 2px solid $naranjaNivel2!important; }
    }
    
    .inputs-input.ant-select.ant-select-enabled{
      display: block;
      .ant-select-selection.ant-select-selection--single{
        @include clearBorder;
        border-radius: 0!important;
        border-bottom: 1px solid $grisNivel3!important;
        transition:  all .5s;
        &:focus{ border-bottom: 2px solid $naranjaNivel2!important; }
      }
    }

    .inputs-input.ant-calendar-picker{
      display: block;
      .ant-calendar-picker-input.ant-input{
        @include clearBorder;
        border-radius: 0!important;
        border-bottom: 1px solid $grisNivel3!important;
        transition:  all .5s;
        &:focus{ border-bottom: 2px solid $naranjaNivel2!important; }
      }
    }

    .ant-time-picker.inputs-input{
      display: block;width: 100%;
      .ant-time-picker-input{
        @include clearBorder;
        border-radius: 0!important;
        border-bottom: 1px solid $grisNivel3!important;
        transition:  all .5s;
        &:focus{ border-bottom: 2px solid $naranjaNivel2!important; }
      }
    }

  }

  .ntaError{
    color:$rojoNibiru;
    margin: 5px auto;
    &.noerror{color:$grisNivel5;}
    &.free{ color: #22B573; }
  }

  .contentMap{
    padding: 0px 15px;
    height: 400px;
  }

  .listTarjetas{
    .itemCard{
      width: 95%;
      box-shadow: 0 0 10px 1px $grisNivel2;
      border-radius: 5px;
      padding: 5px;
      cursor: pointer;
      &:hover{ box-shadow: 0 0 10px 2px $grisNivel2; }
      margin: 5px auto;
    }
    
    .experimental{
      height: 40px;
      width: 105px;
      img{
        max-width: 100%;
        max-height: 100%;
      }
    }

    .data{ flex-grow: 1; padding: 10px 15px; }
    .radioFake{
      height: 18px;
      width: 18px;
      background: $blanco;
      border-radius: 100%;
      border: 1px solid $naranjaNivel2;
      position: relative;
      &.selected{
        &:after{
          content: '';
          position: absolute;
          top: 50%;left: 50%;
          transform: translate(-50%,-50%);
          background: $naranjaNivel2;
          height:12px;
          width: 12px;
          border-radius: 100%;
        }
      }
    }
  }

}

.selectFind{
  border-radius: 10px;
  background: $grisNivel1;
  *{transition: all .5s;}

  .iconCloseModal{
    font-size: 12px;
    cursor: pointer;
    background:transparentize($grisNivel5, 0);
    padding: 5px;
    border-radius: 100%;
    svg{fill:white;}
  }


  .head{
    border-radius: 10px 10px 0 0;
    background-color:$naranjaNivel2;
    padding: 7px;
    img{height: 60px;}
  }
  .body{
    padding: 15px;
    margin: auto;
    width: 560px;
    h4{
      font-family: "Poppins-SemiBold";
      font-size: 14px;
    }
    label{
      font-size: 12px;
      font-family: "Poppins-Medium";
      margin-bottom: 10px;
      display: block;
    }
  }

  .head2{
    width: 89%;
    margin: auto;
    img{height: 60px;   }
    padding-top: 15px;
    i{
      font-size: 12px;
      cursor: pointer;
      background:transparentize($grisNivel5, 0);
      padding: 5px;
      border-radius: 100%;
      svg{fill:white;}
    }
  }

  .compartir{
      width:40px;
      height:40px;
      cursor:pointer;      
      fill:$grisNivel5;
      border-radius:100%;
  }

  .body2{
    padding: 5px 15px;
    margin-top: 10px;
    .title{
      font-size: 20px;
      font-family: "Poppins-Bold";
      text-align: center;
      color: $grisNivel5;
      &.black{color: $naranjaNivel2;}
      &.mdmd{font-family: "Poppins-Medium";}
    }

    .imgCheck{
      img{
        margin:10px auto;
        height:50px;
      }
    }

    .visible{
      transition: all .5s!important;
      &#franjaNaranja{
        background: #f77400;
        height: 25px;
        margin-bottom: 10px;
      }
      &#franjaGris{
        background: #444d52;
        height: 45px;
        margin-top:70px;
        margin-bottom:10px;
      }
    }


    &#capture{
      background-size:auto 100%;
      background-image: url(https://bubook.nibirusystem.com/img/iconos/fondo.jpg);
      padding: 5px;
      overflow: hidden;
    }    
    .btnGroup{
      text-align: center;
      padding: 15px 0;
    }


    .separatore{
      margin: 10px 0;
      font-weight: bold;
      display: block;
      position: relative;
      &:after{
        content: '';
        display: block;
        height: 1px;
        width: 45%;
        background: $grisNivel3;
        position: absolute;
        top: 50%;
      }
      &:before{
        content: '';
        display: block;
        height: 1px;
        width: 45%;
        background: $grisNivel3;
        position: absolute;
        top: 50%;
        left: 55%;
      }
    }
    .btn{
      margin: auto;
      width: 200px;
      font-weight: normal;
      font-family: "Poppins-Medium";
      &.btn1{
        background-image:none;
        background-color: $naranjaNivel2;
      }
      &.btn2{
        background-color: $grisNivel5;
        opacity: 1;
        color: $blanco;
      }
    }

    .infoPedido{
      
      img{
        height:70px;
        border-radius:100%;
      }

      .info{
        text-align:center;
        font-family: "Poppins-Medium";
        font-size: 16px;
        color:$grisNivel5;
        margin: 10px auto;
        .meta,.datac{font-size: 14px;}
        .meta{
          padding: 0 0 0 5px;
          font-family: "Poppins-SemiBold";
          color: $grisNivel5;
        }
        .datac{
          padding: 0 5px 0 0;
          &.bold{
            font-size: 16px;
            font-family: "Poppins-SemiBold";
          }
          &.link{color: $naranjaNivel2;text-decoration: underline;cursor: pointer;}
        }

        &.status{
          color:red;
          &.pagoconfirmado{
            color:#22B573;
          }
        }
        &.breBot{border-bottom: 1px solid $grisNivel5;}
        &.breTop{border-top: 1px solid $grisNivel5;}

        &.clr *{color:$naranjaNivel2;}
      }
    }
    .ntaPed{
      text-align: center;
      font-size: 12px;
      line-height: 14px;
      font-family: "Poppins-Medium";
      color:$grisNivel4;
    }

  }

  &.viewMora{
    padding: 20px;
    .title{
      line-height: 22px;
    }
    &.bgplayfull{
      background-size:auto 100%;
      background-image: url(https://bubook.nibirusystem.com/img/iconos/fondo.jpg);
    }
  }
}

.sombraBaseHover{
  transition: all .5s;
  &:hover{
    box-shadow: 0px 0px 10px 3px $grisNivel2!important;
  }
}

.footer.notAlways{
  height: auto;
  position: relative;
  margin-top: 20px;
  .container.showHide{
    height: 0px;
    opacity: 0;
    *{height: 0px;}
  }
  .content{height: auto;}
  .footer2{height: auto; padding: 15px;}
}