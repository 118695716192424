@import './../../../color.scss';


.imgnonResult{
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -1;
    .nonResultChild{
        position: absolute;
        text-align: center;
        
        position: absolute;
        top: 50%;left: 50%;
        transform: translate(-50%,-50%);

        height: 400px;
        img{
            height: 400px;
        }
        &:after{
            content: '! No se encontraron resultados !';
            color: $colorGrisBajo;
            font-size:23px;
            display: block;
            background: white;
            position: absolute;
            top: 110%;left: 50%;
            transform: translate(-50%,-50%);
            width: 400px;
        }
    }
}

.metaControles{
    margin: auto;
    width: 80%;
    max-width: 1400px;
    margin-top: 5px;
    .tituloPanel{
        color: $naranjaEnd;
        font-size: 23px;
        width: initial;
        margin:initial;
        padding: 0!important;
        font-weight: bold;
    }
}

.tituloYControles{
    margin: auto;
    width: 80%;
    max-width: 1400px;
    padding:0 5px;
    .metaControles{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 5px;
        .tituloPanel{
            color: $naranjaEnd;
            font-size: 23px;
            width: initial;
            margin:initial;
            padding: 0!important;
            font-weight: bold;
        }
        .toggleFiltrar{
            display: block;
            font-size: 18px;
            padding:5px 10px;
            box-shadow: 0px 0px 5px $colorGrisBajo;
            position: relative;
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
            cursor: pointer;
            &::after{
                content: '';
                display: none;
                width: 100%;
                height: 11px;
                background-color: white;
                position: absolute;
                bottom:-6px; left: 0;
                z-index: 99;
            }
            &.toggleFiltrar2{&::after{display: block;}}
        }
    }
    .contControles{
        background-color: white;
        .inputs-group{
            display: block;
            position: relative;
            transition: all .3s;
            padding: 5px 0;
            transition: all.5s;
            .inputs-input{
                display: block;
                font-size: 14px;
                height: 20px;
                width: 100%;
                border:none;
                outline: none;
                position: relative;
                padding-left: 10px;
                border-bottom: 1px solid $colorGrisMedio;
                transition: all .3s;
                color: $colorGrisMedio;
                margin-top: -5px;
                &:focus{
                    border-bottom-color: $naranjaEnd;
                    border-bottom-width: 2px;
                    ~.divFlotante{
                        overflow-y: auto;
                        height: 120px;
                        box-shadow: 0px 0px 10px 0px transparentize($colorGrisMuyBajo,0);
                    }
                    ~.iconcion{
                        transform: rotate(180deg);
                    }
                }
            }
            .iconcion{
                font-size: 16px;
                svg{fill: $naranjaEnd;}
                position: absolute;
                top: 0;left: 90%;
                transition: all .5s;
                z-index: 99;
            }
            .divFlotante{
                display: block;
                position: absolute;
                left: 0%;top: 100%;
                width: 100%;
                background: white;
                z-index: 3;
                height: 0;
                padding: 3px 0px;
                overflow-y: hidden;
                transition: all .5s;
                &::-webkit-scrollbar { width: 8px;}
                &::-webkit-scrollbar-button{ background:white; }
                &::-webkit-scrollbar-track {background:white;} 
                &::-webkit-scrollbar-thumb { background-color:$naranjaEnd; border-radius: 5px; }
                .sucuFlotante{
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    margin-top: 2px;
                }
            }
        }

        display: flex;
        justify-content: flex-end;
        height: 0;
        width: 0;
        transition: height .5s;
        .contControlesHijo{
            transition: all .5s;
            box-shadow: 0px 0px 5px $colorGrisBajo;
            height: 0px;
            width: 0px;
            margin-top: -1px;
            border-top-left-radius: 3px;
            border-bottom-right-radius: 3px;
            border-bottom-left-radius: 3px;
        }
        .pState{
            label{margin-right: 15px;}
            display: none;
            font-size: 15px;
            color: $naranjaEnd;
            font-weight: bold;
            margin-top: 5px;
            .myCheckProd{
                color: $colorGrisMedio;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                font-weight: normal;
            }
        }
        .cleanDiv{
            display: none;
            height: 70px;
            justify-content: flex-end;
            align-items: center;
            .cleanBut{
                font-size: 15px;
                color: transparentize($colorGrisBajo,.2);
                font-weight: bolder;
                box-shadow: 0px 0px 10px 0px transparentize($colorGrisMuyBajo,0);
                border-radius: 5px;
                padding: 6px;
                &:hover{
                    color: $naranjaEnd;
                }
            }

            .FillBut{
                font-size: 15px;
                color: $blanco;
                font-weight: bolder;
                box-shadow: 0px 0px 10px 0px transparentize($colorGrisMuyBajo,0);
                border-radius: 5px;
                padding: 6px;
                background-image: linear-gradient(to right, $naranjaStart,$naranjaEnd);
                width: 100px;
                margin-left: 20px;
            }
        }
        .tiposFiltrados{
            display: none;
            align-items: flex-start;
            justify-content: space-between;
            margin-top: 10px;
            //max-width: 1200px;
            margin: auto;
            &.tiposFiltrados2{
                padding-top: 10px;
                height: 220px;
                &.tiposFiltrados3{border: 1px solid red !important;}
                .cleanDiv{
                    border: none;
                    height: auto;
                }
            }
        }

        .bloqueFiltro{
            //width: 300px;
            //padding:10px;
            flex-grow: 1;
            margin: 0 10px;
            height: 185px;
            padding-top: 0;
            .filTle{
                font-size: 15px;
                color: $naranjaEnd;
                display: block;
                font-weight: bold;
            }
            .opFiltro{
                display: flex;
                justify-content: space-between;
                font-size: 14px;
                color: $colorGrisMedio;
                margin: 3px 0;
                input[type='radio']{margin-right: 15px;}
            }
            .separador{
                height: 1px;
                width: 100%;
                margin: 5px auto;
                margin-left: 0px;
                background-color: $colorGrisMedio;
            }
        }

        &.contControles2{
            height: auto;
            width: 100%;
            //transition: all .5s;
            .contControlesHijo{
                height: auto;
                width: 100%;
            }
            .filtradosExtras{display: flex;}
            .pState{display: block;}
            .cleanDiv{display: flex;}
            .tiposFiltrados{ display: flex; }
        }
    }
}

.filtradosExtras{
  display: none;
  justify-content: center;
  //max-width: 1200px;
  margin:auto;
  .fechaSexoEstadisticas{
    display: flex;
    width: 50%;
    .inputs-group.inputs-group-inline{
      &:first-child{ margin-right: 10px; }
    }
  }
  .inputs-group-inline{
    flex-grow: 1;
    padding:10px;
    .inputs-label{
      font-size: 12px;
      font-weight: bold;
      color: $colorGrisMedio;
    }
    .inputs-input{
      display: block;
      font-size: 14px;
      height: 30px;
      width: 100%;
      border:none;
      outline: none;
      position: relative;
      //padding-left: 10px;
      border-bottom: 1px solid $colorGrisMedio;
      transition: all .3s;
      color: $colorGrisMedio;
      &:focus{ border-bottom-color: $naranjaEnd; border-bottom-width: 2px; }
    }
    .ant-select.ant-select-enabled,
    .ant-select.ant-select-open.ant-select-focused.ant-select-enabled,
    .ant-select.ant-select-focused.ant-select-enabled{
      .ant-select-selection.ant-select-selection--single,
      .ant-select-selection--multiple{
        box-shadow: none;
        border: none;
        outline: none;
        border-bottom: 1px solid $colorGrisMedio;
        border-radius: 0;
        display: block;
        font-size: 14px;
        height: 30px;
        width: 100%;
        &:focus{
          border-bottom: 2px solid $naranjaEnd;
        }
      }
      svg{
        fill: $naranjaEnd;
        height: 20px;
        width: 15px;
      }
    }
  }
  &.outer{
    width: 80%;
    margin: auto;
    max-width: 1400px;
    margin-top: 5px;
    transition: all .5s;
    *{transition: all .5s;}
  }
}

.tipoProducto{
    height: 100vh;
    width: 100%;
    background-color: white;
    position: absolute;
    top: -10px;
    z-index: 2;
    display: block;
    .titleTypeProducto{
        font-size: 23px;//titulos
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        color: $colorGrisFuerte;
        margin: 45px 0;
    }
    .bodyTypes{
        margin-top: 50px;
        width: 100%;
        display: flex;
        justify-content: center;
        text-align: center;
        flex-wrap: wrap;
        .productTypeCard{
            margin:5px 40px;
            box-shadow: 0px 0px 15px 5px $colorGrisBajo;
            border-radius: 15px;
            transition: all .3s;
            transform: scale(.9);
            img{ width: 250px; cursor: pointer; }
            &:hover{
                transform: scale(1);
            }
        }
    }
}


.miAntModal.newVar.alertMy{

    &.flexfoot{
        .ant-modal-footer{
            display: block;
            padding: 10px;
            div{
                display:flex;
                justify-content:flex-end;
                align-items:center;
                button{
                    display:flex;
                    justify-content:center;
                    align-items:center;
                }
            }
        }
    }

    .ant-modal-content{
        border-radius: 10px!important;
        @media(max-width: 750px){
            width: 100%;
            max-width: 600px;
        }
    }
    .ant-modal-header{
        padding: 10px;
        .ant-modal-title{
            text-align: center;
            color: $colorGrisFuerte;
            font-size: 17px;
        }
    }
    .ant-modal-body{
        padding: 0px;
        color:$colorGrisMedio;
        font-size: 15px;
    }
    .ant-modal-footer{
        display: block;
        padding: 10px;
    }
    /*mi clase xd*/
    .myAlertBody{
        padding: 10px;
        padding-top: 0;
        .txtAlert{
            display: block;
            text-align: center;
        }

        .cont-Cropper{
            height: 380px;
            width: 380px;
            @media(max-width: 750px){
                height: 300px;
                width: 100%;
            }
        }
    }
}


.buttomFlotanding{
    z-index: 999;
    font-size: 50px;
    line-height: 50px;
    height: 50px;width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    position: fixed;
    bottom: 25px;
    right: 80px;
    background: $naranjaEnd;
    border: 1px solid $colorGrisMuyBajo;
    color: white;
    transition: all .5s;
    cursor: pointer;
    &:hover{transform: scale(1.1);}
}

.miAntModal.newVar{
    .ant-modal-content{
        position: absolute;
        top: 50%;left: 50%;
        transform:translate(-50%,-50%);
        width: 400px;
        @media(max-width: 750px){
            width: 100%;
            max-width: 600px;
        }
    }
    .ant-modal-footer{ display: none; }
    .newProductFoot{
        margin-bottom: 0px;
        .dosBtones{}
        .tresbotones{}
    }
    
    .contSrcs{
        margin-top: -50px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        height: 120px;
        label{
            position: relative;
            height: 92px;
            width: 120px;
            border: 1px solid $colorGrisMuyBajo!important;
            box-shadow: 0px 0px 10px 0px $colorGrisMuyBajo;
            transition: all .5s;
            border-radius: 5px;
            cursor: pointer;
            .mask{
                position: absolute;
                top: 0px; left: 0px;
                width: 100%; height: 100%;
                background-color: transparent;
                z-index: 200;
            }
            &:hover{
                .mask{
                    opacity: 1;
                }
            }
            img{
                top: 50%;left: 50%;
                transform: translate(-50%,-50%);
                position: absolute;
                border-radius: 5px;
                max-width: 115px;
                max-height: 87px;
            }
        }
        input[type="file"]{display: none;}
    }
}

.tarjetaProducto2{
    height: 134px;
    width: 340px;    
    margin: 3px;
    box-shadow: 0px 0px 5px transparentize($colorGrisMedio , .5);
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 5px;
    padding: 5px;
    transition: all .5s;
    position: relative;
    cursor: pointer;
    .options{
        transition: all .5s;
        position: absolute;
        bottom:3%;left:40%;
        z-index: 90;
        background-color: transparentize($colorGrisMuyBajo,0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        padding:5px;
        border-top-right-radius: 5px;
    }
    &:hover{ box-shadow: 0px 0px 5px 1px transparentize($colorGrisMedio , .5);
        .options{
			i{
				svg{
					fill:$naranjaStart;
				}
			}
		}
    } 
    .metaBodyEx{ height: 100%; }

    .metaBody{
        height: 100%;
        width:200px;
        display: block;
        padding: 5px;
        padding-top: 0;
        .metaCont{}
        .FootCont{}
    }
    
    .metaBanner{
        height: 95px;
        width: 126px;
        position: relative;
        img{
            height: 100%;
            width: 100%;
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
            &.no{
                height: 90px;
                width: 90px;
                position: absolute;
                top: 50%;left: 50%;
                transform: translate(-50%,-50%);
            }
        }
    }

    .metaContStock{
        width: 100%;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        opacity: .9;
        &:hover{
            transition: all .5s;
            opacity: 1;
        }
        label{
            color: white;
            font-weight: bolder;
            font-size: 13px;
            margin-left: 20px;
        }
        img{ height: 22px; }
        &.existe{ background-color:#22b473; }
        &.noexiste{ background-color:$rojoNibiru; }
        &.nostock{background-color:$colorGrisMedio}
        &.variantes{ background-color:$naranjaStart; }
        &.servicio{ background-color:$azulBuBook; }
    }
    .metaPrec{
        font-size: 14px;
        height: 46px;
        font-weight: bold;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        color: $colorGrisFuerte;
    }
    .metaName{
        font-size: 14px;
        color: $colorGrisFuerte;
        font-weight: bold;
        line-height: 16px;
        height: 35px;
        overflow: hidden;
        width: 100%;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .metaDesc{
        font-size: 13px;
        line-height: 15px;
        height: 45px;
        overflow: hidden;
        text-align: justify;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        white-space: pre-wrap;
    }
}

.cont-instancias{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    //justify-content: center;
    flex-wrap: wrap;
    margin: 10px auto;
    //
    width: 100%;
    max-width: 1400px;
}

.cont-instancias5{
    margin-top: 30px;
    height: 1px;
}

.cont-instancias2{
    margin: auto;
    width: 80%!important;
    max-width: 1400px!important;
    //background: none!important;
    &.infoEmpresaModal{
        width: 83%!important;
        border: 2px solid $grisNivel2;
        padding: 10px;
        border-radius: 10px;
    }
}

.nombreCate{
    display: block;
    position: relative;
    margin-top: 15px;
    margin-bottom: -5px;
    
    .nombreCateint{
        color: $naranjaStart;
        font-size: 18px;
        text-transform: capitalize;
        background: white;
        padding: 0 5px;
        z-index: 99;
        margin:5px 0;
    }
}

/* newProductBodyForm */
.newProductHead{
    width: 80%;
    max-width: 1400px;
    margin: auto;
    margin-top: 20px;
    padding: 10px 20px;
    transition: all .5s;
    border-radius: 5px;
    border: 1px solid $colorGrisMuyBajo;
    box-shadow: 0px 0px 10px 0px $colorGrisMuyBajo;
    label{
        color: $naranjaEnd;
        font-size: 17px;
        display: block;
        font-weight: bolder;
    }
    span{
        color: $colorGrisBajo;
        font-size: 16px;

    }
    .contSrcs{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        height: min-content;
        position: relative;
        div{
            flex-grow: 1;
            position: relative;
            max-height: 115px;
            margin: 5px;
            label{
                border: 1px solid $colorGrisMuyBajo;
                box-shadow: 0px 0px 10px 0px $colorGrisMuyBajo;
                position: relative;
                height: 92px;
                width: 120px;
                margin: auto;
                transition: all .5s;
                border-radius: 5px;
                cursor: pointer;
                .mask{
                    position: absolute;
                    top: 0px; left: 0px;
                    width: 100%; height: 100%;
                    background-color: transparent;
                    z-index: 200;
                }
                .favImg{
                    position: absolute;
                    top: 100%;left: 50%;
                    transform: translate(-50%,-50%);
                    background-color: white;
                    border: 1px solid $colorGrisMuyBajo;
                    box-shadow: 0px 0px 10px 0px $colorGrisBajo;
                    height: 30px;
                }
            }
            img{
                top: 50%;left: 50%;
                transform: translate(-50%,-50%);
                position: absolute;
                border-radius: 5px;
                max-width: 115px;
                max-height: 87px;
            }
        }
        input[type="file"]{display: none;}
    }
}

.newProductBody{
    transition: all .5s;
    width: 80%;
    max-width: 1400px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    
    @media (max-width:750px){
        .newProductDiv{ width: 100%; }
    }

    .inputs-group{
        display: block;
        position: relative;
        transition: all .3s;
        padding: 5px 0;
        transition: all.5s;
        .inputs-label{
           font-size: 12px;
           font-weight: bold;
           color: $colorGrisMedio;
           span{
                font-weight: normal;
           }
        }
        
        textarea.inputs-input{
            @include clearBorder;
            border-bottom: 1px solid $colorGrisMedio!important;
            box-sizing: border-box;
            resize: none;
            font-size: 14px;
            line-height: 18px;
            height: auto;
            border-radius: 0;
            &:focus{
                border-bottom: 2px solid $naranjaEnd!important;
            }
        }

        .inputs-input{
            display: block;
            font-size: 14px;
            height: 30px;
            width: 100%;
            border:none;
            outline: none;
            position: relative;
            padding-left: 10px;
            border-bottom: 1px solid $colorGrisMedio;
            transition: all .3s;
            color: $colorGrisMedio;
            &:focus{ border-bottom-color: $naranjaEnd; border-bottom-width: 2px; }
            &::placeholder{ color:$grisNivel2; }
        }
        .mytimer{
            transition: all .5s;
            .ant-time-picker{
                box-shadow: none;
                border: none;
                width: 100%;
                input{
                    border: none;
                    border-bottom: 1px solid $colorGrisBajo;
                    border-radius: 0;
                    width: 100%;
                }
                i{display: none;}
            }
        }
    }
    .fechaSexo{
        display: flex;
        justify-content: space-between;
        .inputs-group.inputs-group-inline{ 
            flex-grow: 1;
            &:first-child{
                margin-right: 15px;
            }
        }
    }
}

.newProductDiv{
    width: 49%;
    min-width: 300px;
    padding: 20px;
    margin-top: 10px;
    border: 1px solid $colorGrisMuyBajo;
    box-shadow: 0px 0px 10px 0px $colorGrisMuyBajo;
    border-radius: 5px;
    .newProductDivTitle{
        display: flex;
        justify-content: space-between;
        position: relative;
        label{
            color: $naranjaEnd;
            font-weight: bolder;
            font-size: 15px;
        }
        .overChecked{
            position: absolute;
            left: 96%;top: 50%;
            transform: translate(-50%,-50%);
            background-color: transparent;
            height: 100%;
            width: 70px;
            border-radius: 20px;
            cursor: pointer;
        }
    }
}

.newProductDivSucursales{
    transition: all .5s;
    width: 80%;
    max-width: 1400px;
    margin: auto;
    margin-top: 10px;
    .listSucursales{
        margin-top: 5px;
    }
}

.newProductBodyStock{
    height: min-content;
    .fechaSexo{
        display: flex;
        justify-content: space-between;
        .inputs-group.inputs-group-inline{ 
            flex-grow: 1;
            &:first-child{
                margin-right: 15px;
            }
        }
    }
    .lisSucsProds{
        margin-top: 10px;
        overflow: hidden;
        .nomSuc{
            font-size: 17px;
            color: $naranjaStart;
            position: relative;
            &::after{
                content: '';
                position: absolute;
                top: 50%;left: 110%;
                height: 1px;
                width: 1000px;
                display: block;
                background-color: $naranjaStart;
                box-shadow: 0px 0px 10px 0px $naranjaStart;
                z-index: -1;
            }
        }
        .nomProd{
            font-size: 15px;
            display: block;
            color: $colorGrisMedio;
        }
    }
    .nota{
        color:$naranjaEnd;
        display: block;
        margin:10px auto;
        text-align: center;
        font-size: 12px;
        font-weight: bold;
        cursor: pointer;
        &.error{
            cursor:initial;
            display: none;
            color:$rojoNibiru;
        }
    }
}
.newProductBodyVar{
    transition: all .5s;
    .newProductDivTitle{
        border-bottom: 1px solid $colorGrisMuyBajo;
        padding: 5px 0;
        width: 95%;
        margin: auto;
    }
    .lisvariantItem{
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $colorGrisMuyBajo;
        height: 40px;
        width: 95%;
        margin: auto;
        font-size: 17px;
        color: $colorGrisMedio;
        transition: all .5s;
        .miniVar{
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                height: 20px;
            }
        }
        &:hover{
            background-color: transparentize($colorGrisMuyBajo, .5);
        }
        label{
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 300px;
            display: block;
            overflow: hidden;
            cursor: pointer;
            margin-left: 10px;
        }
        span{
            display: flex;
            align-items: center;
            margin-right: 10px;
            img{
                height: 25px;
                cursor: pointer;
                margin-top: -5px;
                margin-left: 10px;
            }
        }
    }
    .lisSucsProds{
    }
    .newVarButton{
        width: 95%;
        margin: auto;
        margin-top: 20px;
        font-size: 15px;
        color: white;
        font-weight: bolder;
        border: 1px solid $colorGrisMuyBajo;
        box-shadow: 0px 0px 10px 0px $colorGrisMuyBajo;
        background-image: linear-gradient(to right, $naranjaStart,$naranjaEnd);
        border-radius: 5px;
        padding: 6px;
        i{
            margin-right: 20px;
            color: white;
        }
    }
}

.newProductBodyForm{
    transition: all .3s;
    display:flex;
    flex-direction: column;
    .ant-select.ant-select-enabled,
    .ant-select.ant-select-open.ant-select-focused.ant-select-enabled,
    .ant-select.ant-select-focused.ant-select-enabled{
        .ant-select-selection.ant-select-selection--single,
        .ant-select-selection--multiple{
            box-shadow: none;
            border: none;
            outline: none;
            border-bottom: 1px solid $colorGrisMedio;
            border-radius: 0;
            &:focus{
                border-bottom: 2px solid $naranjaEnd;
            }
        }
        svg{
            fill: $naranjaEnd;
            height: 20px;
            width: 15px;
        }
    }
}
.newProductFoot{
    .dosBtones{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 20px;
        button{
            margin: 5px 10px;
            height: 35px;
            width: 150px;
            border-radius: 5px;
            border: 1px solid $colorGrisMuyBajo;
            box-shadow: 0px 0px 10px 0px $colorGrisMuyBajo;
            border-radius: 5px;
            text-align: center;
            transition: all .5s;
            background-image: linear-gradient(to right, $naranjaStart , $naranjaEnd );
            color: white;
            &:hover{ box-shadow: 0px 0px 10px 5px transparentize($colorGrisBajo,0.5) }
        }
        .btnEliminar{
            background: none;
            color: $colorGrisBajo;
            font-weight: bolder;
            opacity: .7;
            &:hover{ color: $naranjaEnd}
        }
        .btnAceptar{
            font-weight: bolder;
            &:disabled{
                background-color: $grisNivel2;
                cursor: not-allowed;
                background-image: none;
            }
        }
    }

    transition: all .5s;
    width: 80%;
    max-width: 1400px;
    margin:auto;
    margin-bottom: 50px;
    @media (max-width:750px){
        padding-bottom: 30px;
        width: 100%;
    }
    .newProductFootButtons{
        margin-top: 20px;
        display: flex;
        justify-content: center;
        button{
            margin: 5px 10px;
            height: 35px;
            width: 150px;
            border-radius: 5px;
            border: 1px solid $colorGrisMuyBajo;
            box-shadow: 0px 0px 10px 0px $colorGrisMuyBajo;
            border-radius: 5px;
            text-align: center;
            transition: all .5s;
            &:hover{ transform: scale(1.1); }
        }
        .btnEliminar{
            color: $colorGrisBajo;
            font-weight: bolder;
            opacity: .7;
        }
        .btnAceptar{
            color: $naranjaEnd;
            font-weight: bolder;
        }
    }
}
.contCheckSuc{
    width: 340px;
    padding-left: 5px;
    margin: auto;
    .tititle{
        font-size: 12px;
        font-weight: bold;
        color: $colorGrisMedio;
        margin-bottom: 10px;
        width: 100%;
    }
    .tititle2{
        display: block;
        line-height: 14px;
    }
}
.milistcheck{
    &.milistchecknolist{
        display: flex;
        flex-wrap: wrap;
        .myCheckProd{
            width: 200px;
        }
    }
    max-height: 120px;
    overflow-y:auto;
    &::-webkit-scrollbar { width: 8px;}
    &::-webkit-scrollbar-button{ background:white; }
    &::-webkit-scrollbar-track {background:white;} 
    &::-webkit-scrollbar-thumb { background-color:$naranjaEnd; border-radius: 5px; }
    .myCheckProd{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        cursor: pointer;
        label{
            cursor: inherit;
            white-space: nowrap;
            text-overflow: ellipsis;
            display: block;
            width: 100%;
            overflow: hidden;
        }
        input[type="checkbox"]{
            cursor: inherit;
            height: 16px;
            width: 16px;
            position: relative;
            margin: 0 10px;
            &:checked::after{
                content: '';
                position: absolute;
                top: 0; left: 0;
                width: 14px;
                height: 14px;
                background-image:url(https://bubook.nibirusystem.com/img/iconos/Palomita.png);
                background-size: 90%;
                background-color: white;
                background-position: center;
                background-repeat: no-repeat;
                display: block;
                z-index: 2;
            }
        }
    }
}

.bloqueFiltro{
    .inputs-group{
        position: relative;
        .ant-select.ant-select-enabled,
        .ant-select.ant-select-open.ant-select-focused.ant-select-enabled,
        .ant-select.ant-select-focused.ant-select-enabled{
            .ant-select-selection.ant-select-selection--single,.ant-select-selection--multiple{
                width: 278px;
                box-shadow: none;
                border: none;
                outline: none;
                border-bottom: 1px solid $colorGrisBajo;
                border-radius: 0;
                &:focus{
                    border-bottom: 2px solid $naranjaEnd;
                }
            }
            svg{
                fill: $naranjaEnd;
                height: 20px;
                width: 15px;
            }
        }
    }
}



input[type="checkbox"]{
    cursor: inherit;
    height: 16px;
    width: 16px;
    position: relative;
    margin: 0 10px;
    &:checked::after{
        content: '';
        position: absolute;
        top: 0; left: 0;
        width: 14px;
        height: 14px;
        background-image:url(https://bubook.nibirusystem.com/img/iconos/Palomita.png);
        background-size: 90%;
        background-color: white;
        background-position: center;
        background-repeat: no-repeat;
        display: block;
        z-index: 2;
    }
}