@import './../color.scss';

$anchoPantalla : 100vw;
$altoPantalla : 100vh;

.cont-Aviso{
    height: $altoPantalla;
    width: $anchoPantalla;
    position: relative;
    .avisoContainer{
        padding: 12px;
        border-top: 40px solid $naranjaEnd;
        border-radius: 10px;
        min-height: 450px;
        width: 350px;
        box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.3);
        font-size: 25px;
        color: black;
        img{
            display: block;
            margin: auto;
            margin-top: 10px;
            height: 100px;
        }
        .avisoTitulo{            
            display: block;
            margin: auto;
            margin-top: 10px;
            text-align: center;
            color:$colorGrisFuerte;
        }
        .btn-Aviso{
            font-size: 15px;
            display: block;
            margin: auto;
            margin-top: 25px;
            background-color: $naranjaEnd;
            border: none;
            color: white;
            padding: 12px 15px;
            border-radius: 5px;
            cursor: pointer;
        }
        .btn-Aviso25{
            width: 130px;
            font-size: 15px;
            margin: auto;
            margin-top: 8px;
            color: $naranjaEnd;
            background-color: red;
            border: none;
            padding: 2px;
            color: white;
            border-radius: 5px;
            cursor: pointer;
        }
        .powered{
            font-weight: bold;
            font-size: 14px;
            text-align: center;
            position: absolute;
            left: 50%; bottom: 5%;
            width: 100%;
            transform: translate(-50%,-5%);
            color: $colorGrisMedio;
        }
    }
}