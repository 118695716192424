@import './../../../color.scss';

.divCalificaciones{
	margin: auto;
    width: 80%;
    max-width: 1400px;
    margin-top: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
	.divCalificacionesChild{
		width: 400px;
		.puntuacion{
			font-size: 23px;
			font-weight: bold;
			text-align: center;
			width: 100%;
			color:$naranjaNivel2;
		}

		.nResenas{
			font-size: 17px;
			font-weight: bold;
			text-align: center;
			width: 100%;
			margin: 10px auto;
		}

		.contEstrellas{
			.contEstrellasChild{
				margin: auto;
				width: 306px;
				height: 60px;
				padding: 0;
				position: relative;
				.strella{
					height:60px;
					width: 60px;
					margin-left: 1px;
				}
				.contEstrellasChildFloat{
					position: absolute;
					top: 0;left: 0;
					height: 100%;
					background: $naranjaStart;
					z-index: -1;
					transition: all 0.5s;
				}
			}
		}
		.contMetaResenas{
			.metaResena{
				line-height: 25px;
				display: flex;
				.calResena{
					width: 120px;
					color:$grisNivel5;
					font-family: "Poppins-Medium";
					font-size: 15px;
				}
				.canResena{
					width: 20px;
					margin-left: 5px;
					text-align: center;
					color:$grisNivel5;
					font-family: "Poppins-Medium";
					font-size: 15px;
				}
			}
		}
	}
}

.targetaResena{
	width: 100%;	
	//max-width: 600px;
	margin:10px 0;
	border-radius: 5px;
	box-shadow: 0px 0px 5px transparentize($colorGrisMedio , .5);
	padding: 5px;
	cursor: pointer;
	&:hover{ box-shadow: 0px 0px 5px 1px transparentize($colorGrisMedio , .5);}
	.head{
		display: flex;
		align-items:flex-start;
		justify-content: space-between;
		padding: 0;
	}

	.body2{

		/**/
		background-color: $grisNivel2;
	    position: relative;
	    border-radius: 5px;
	    margin-top: 10px;
	    &:after{
	        content: '';
	        display: block;
	        height: 15px;
	        width: 15px;
	        background: blue;
	        position: absolute;
	        top: 0;left: 0;
	        transform: translateY(-70%);
	        z-index: 10;
	        clip-path: polygon(50% 0, 0 100%, 100% 100%);
	        background: $grisNivel2;
	    }
		/**/


		margin-left: 30px;
		padding: 5px;
		.imgCliente{
			img{
				height: 30px;
				width: 30px;
			}
		}
		.Headrespuesta{
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		.respuestatxt{
			font-weight: bold;
			margin: 2px;
		}
		.fecha2{
			color:$grisNivel5;
			font-family: "Poppins-SemiBold";
		}

		.NomCliente{
			display: flex;
			justify-content: flex-start;
			align-items: center;
		}
	}

	.respuestaResena{padding: 5px;}

	.divImgNom{
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	
	.imgCliente{
		display: flex;
		justify-content: center;
		align-items: center;
		width: fit-content;
		padding: 5px;
		img{
			height: 60px;
			width: 60px;
			border-radius: 100%;
		}
	}
	.NomCliente{
		max-width: 100%;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		font-size: 14px;
		font-weight: bold;
		text-transform: capitalize;
		text-align: left;
		height: 30px;
		font-size:15px;
	}
	.califCliente{
		display: flex;
		justify-content: flex-start;
		align-items: center;
		.calif{
			font-weight: bold;
			margin: 2px 5px;
		}
	}
	.fecha1{
		display: block;
		text-align: right;
	}


	.contEstrellasMini{
		.contEstrellasChild{
			width: 150px;
			height: 25px;
			padding: 0;
			position: relative;
			align-items: center;
			.strella{
				height:25px;
				width: 25px;
			}
		}
	}
}

.resenaModal{
	display: flex;
	justify-content: flex-start;
	margin-top: -20px;
	margin-bottom: 30px;
	height: 80px;
	.perfilModal{
		width: 80px;
		display: flex;
		justify-content: center;
		align-items: flex-start;
		img{
			height: 50px;
			width: 50px;
			border-radius: 100%;
		}
	}
	.bodyModal{
		width: 100%;
		.dv1{
			display: flex;
			justify-content: space-between;
			width: 100%;
			.nom{
				font-weight: bold;
				max-width: 60%;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				font-size: 15px;
				text-transform: capitalize;
			}
		}
		.respuestaResena{}
	}
}


.inputs-input.textareaModal{
	height: auto!important;
}

.filtradosExtras{
	.MyDate{
    	width: 100%;
    	border: none;
    	outline: none;
	}
}

.tiposFiltrados.tiposFiltrados3{
	.filTle{
		font-size: 15px;
        color: $naranjaEnd;
        display: block;
        font-weight: bold;
        text-align: center;
	}
	.contEstrellas{
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 5px;
		img{
			height: 40px;
			cursor: pointer;
		}
	}
	.cleanDiv{ padding: 0px 10px; }
}

.contControles.contControles2{
	.tiposFiltrados.tiposFiltrados3{
		display: block!important;
	}
}