$naranjaBuBook:#DC730E;
$naranjaStart:#FF9700;
$naranjaEnd:#F77400;

$grisTitulos:#4d4d4d;
$grisContenido:#666666;
$grisSubContenido:#848484;
$grisAnotaciones:#d5d5d5;

button, a{
  text-decoration-line: none; 
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}

.centrado{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.bannerBuForms{
    background-image: linear-gradient(to right,$naranjaStart,$naranjaEnd);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    .logoBu{
        height:120px;
        display: block;
        margin: auto;
    }
}

.page {
  position: absolute;
  width: 100%;
}

.page-enter {
  opacity: 0;   
}

.page-enter-active {
  opacity: 1; 
  transition: opacity 0.5s;
}

.page-exit {
  opacity: 1;  
}

.page-exit-active {
  opacity: 0; 
  transition: opacity 0.5s;
} 