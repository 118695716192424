@import './../../color.scss';

#publi{
  //background: red;
  //min-height: 100px;
}

#forMessageDiv{
  position: relative;
  .ant-message{
    position: absolute;
    top:50%;left:50%;
    transform: translate(-50%,-50%);
  }
}



#textToCopy2,#textToCopy{
  transition:  all .5s;
  &.copied{  }
}

.addDiv{
  color: $naranjaNivel2;
  font-family: "Poppins-Medium";
  margin:8px 0;
  font-size: 15px;
  cursor: pointer;
}

.buttonMakeReport{
  background:$naranjaNivel2;
  color:$blanco;
  font-size: 15px;
  font-family: "Poppins-Medium";
  padding: 10px;
  border-radius: 5px;
}


.markada{
  
  img{
    height: 40px;
    border-radius: 100%;
    background: white;
  }

  .tooltipMark{
    
    .ant-tooltip-arrow{
      &:before{
        background: $naranjaNivel2;
      }
    }

    .ant-tooltip-inner{
      width: 150px;
      background: $naranjaNivel2;
      text-align: center;
      padding: 5px;
    }
  }

}

.tooltipMark{}

.itemSelecttor{
  background: white;
  height: 110px;
  margin-bottom: 10px;
  border-radius: 5px;
  text-align: center;
  .label{padding: 5px;display: block;}
  .ant-select-selection.ant-select-selection--single{
    margin: 0 auto;
    background: none;
    color:$naranjaNivel2;
    width: 180px;
    border-radius:0!important;
    @include clearBorder;
    border-bottom: 1px solid $grisNivel5!important;
    i{svg{fill:$naranjaNivel2;} font-size:20px; transform: translateY(0); }
    .ant-select-selection__rendered{
      height: 25px;
      position: relative;
      top: 5px;
    }
  }
  .horario{
    font-family: "Poppins-Medium";
    width: 180px;
    font-size: 15px;
    border: 2px solid;
    border-radius: 5px;
    text-align: center;
    margin: 10px auto;
    //margin-top: 5px;
    text-transform: capitalize;
    &.abierto{border-color: green; color:green;}
    &.cerrado{border-color: red; color:red;}
  }
}

.botonVerMasResenas{
  border: 2px solid $grisNivel3;
  border-radius: 5px;
  text-align: center;
  padding: 5px 10px;
  color:$grisNivel4;
  font-family: "Poppins-Medium";
  margin-top: 7px;
}

.infoEmpresaModal{
  background: $grisNivel1;
  width: 800px;
  border-radius: 15px;

  .content{
    width: 90%;
    margin: auto;
    .section{
      margin-top: 10px;
      .title{
        color:$grisNivel5;
        font-family: "Poppins-Medium";
      }
      &.pdd{ padding-bottom: 10px; }
      &.bdrnd{
        width: 103%;
        margin-left: -1.5%;
        border: 2px solid $grisNivel2;
        padding: 10px;
        border-radius: 10px;
        .title{color:$naranjaNivel2;}
      }
      &.mgg{
        width: 100%;
        margin: 0;
      }
    }
    .spaceMaker{
      height: 20px;
    }
  }
  
  .titleShare{
    margin-top: 20px;
    span{
      font-size: 25px;
      font-family: "Poppins-SemiBold";
      color:$grisNivel3;
      display: block;
      color: $grisNivel5;
    }
  }
  
  .strellas{
    svg{
      height: 20px;
      width: 20px;
      fill:$amarillo;
      transform: translateY(-3px);
      margin: 0 2px;
    }
    color: $grisNivel4;
    font-size: 15px;
  }

  .positiveRese{
    color: $naranjaNivel2;
    font-size: 12px;
    margin: 7px 0;
  }

  .metaDesc{
    white-space: pre-wrap;
    padding: 0;
    color: $grisNivel3;
    font-size: 14px;
    font-family: "Poppins-Medium";
    margin: 5px 0;
    margin-bottom: 10px;
  }
  
  .listHorario{
    display: flex;
    justify-content: space-between;
    span{ margin-left: 5px; text-transform: capitalize; }
    label{margin-right: 5px;}
  }

  .divmap{
    position: relative;
    height: 350px;
    width: 100%;
    border-radius: 15px;
    div{ &:nth-child(1){ &:nth-child(1){ div{border-radius: 10px 10px 0 0;}} } }
    
    .iconCloseModal{
      position: absolute;
      top: 10px;
      right: 30px;
      font-size: 12px;
      cursor: pointer;
      background:transparentize($grisNivel5, 0);
      padding: 5px;
      border-radius: 100%;
      svg{fill:white;}
    }
  }

  .divUbicacion{
    background: $grisNivel2;
    min-height: 40px;
    text-align: center;
    padding: 5px 50px;
    font-family: "Poppins-Medium";
    font-size: 14px;
  }

  .metaInfo{
    .title{
      color:$grisNivel5;
      font-family: "Poppins-Medium";
      img{height: 15px;transform: translateY(-1px);}
    }
    span{
      white-space: pre-wrap;
      display: block;
      color:$grisNivel3;
      font-family: "Poppins-Medium";
      &.greyTag{
        background-color: $grisNivel4;
        color:$blanco;
        width: fit-content;
        margin:4px 0;
        border-radius: 10px;
        padding: 3px 2px;
      }
    }
  }

  .contentHorarios{
    //span{ display: block; }
    label{
      color: $naranjaNivel2;
      display: block;
    }
  }
  
  .itemSelecttor{
    position: absolute;
    top: 10px;left: 30px;
    background: $grisNivel5;
    height: 70px;
    width: 180px;
    .label{color:$blanco;}
    .ant-select-selection.ant-select-selection--single{
      transform: translateY(-10px);
      border-bottom: 1px solid $blanco!important;
      width:150px;
      padding: 0;
      .ant-select-selection__rendered{ width: 100%; transform: translateX(-10px); }
      i{svg{transform: translateX(15px);}}
    }
  }

  .infoDire{
    text-align:center;
    padding: 5px;
  }
  .markerg{
    height: 35px;
    width: 35px;
    background: white;
    border-radius: 100%;
  }
  .contBlockPunt{
    display: flex;
    justify-content: center;
    align-items: center;
    color:$grisNivel3;
    font-family: "Poppins-Medium";

    label{
      font-family: "Poppins-Medium";
      &.fwBold{
        color:$grisNivel5;
        font-family: "Poppins-Bold";
      }
    }

    .contImagenes{ img{ height: 40px; } }
  }
  .contProgres{
    width: 400px;
    .itemProgres{
      display: flex;
      font-family: "Poppins-Medium";
      align-items: center;
      color:$grisNivel3;
      .ant-progress-inner{background-color:$grisNivel2;}
      .calResena{width: 120px;}
      .canResena{width: 20px;margin-left: 5px;}
    }
  }
  .cardCliente{
    margin-top: 10px;
    //box-shadow: 0px 0px 5px transparentize($colorGrisMedio , .5);
    padding: 5px;
    .head{
      display: flex;
      .perfil{
        height: 60px;
        width: 60px;
        padding: 5px;
        border-radius: 100%;
        margin: 0 3px;
        margin-left: -5px;
        img{width: 100%;height: 100%;border-radius: 100%;}
      }
      .metCliente{
        flex-grow: 1;
        .nombre{
          .dataNombre{
            font-size: 14px;
            color: $grisNivel5;
            font-weight: bold;
            line-height: 16px;
            width: 100%;
          }
          .dataImgs{
            span{color: $grisNivel5;font-weight: bold;margin-right: 5px;}
            div{
              transform: translateY(-2px);
              img{height: 20px;}
            }
          }
        }
      }
    }
    .comen{
      white-space:pre-wrap;
      margin-top: -15px;
    }
  }
  .cardRespuesta{
    padding: 5px;
    background-color: $grisNivel2;
    margin-left: 50px;
    position: relative;
    border-radius: 5px;
    &:after{
        content: '';
        display: block;
        height: 15px;
        width: 15px;
        background: blue;
        position: absolute;
        top: 0;left: 0;
        transform: translateY(-70%);
        z-index: 10;
        clip-path: polygon(50% 0, 0 100%, 100% 100%);
        background: $grisNivel2;
    }

    .head{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 5px 0;
      div{ img{height: 20px; border-radius: 100%; margin: 2px 5px;} }
      .rtaResena{
        color:$grisNivel5;
        font-family: "Poppins-SemiBold";
      }
    }
    .comen{
      padding-left: 5px;
    }
  }
  .comen{
    font-size: 14px;
    padding: 5px;
    padding-left: 55px;
    font-family: "Poppins-Regular";
    color:$grisNivel4;
    text-align: justify;
    white-space: pre-wrap;
  }
}

.perfilEmpresa{
  .portada{
    margin: auto;
    margin-top:10px;
    width: 100vw;
    max-width: 85%;
    height: 379px;
    overflow: hidden;
    background: $grisNivel1;
    border-radius: 10px 10px;
    cursor: pointer;
    position: relative;
    img{
      border-radius: 10px 10px;
      transition: all .5s;
      position: absolute;
      top:50%;left: 50%;
      transform: translate(-50%,-50%);
      width: 100%;
      display: block;
    }
  }

  .blockInfoGral{
    .limiter{margin: auto;}
  }

  .controlsPerfil{    
    font-weight: bold;
    margin: 10px 0;
  }
  .meta{
    border-bottom: 2px solid $grisNivel5;
    padding-bottom: 10px;
  }
  .info{
    .resenasNumber{
      .estrella{
        fill: $amarillo;
        width: 18px;
        height: 18px;
        transform: translate(-5px,3px);
      }

      span{
        font-size: 15px;
        font-family: "Poppins-Medium";
        color:$grisNivel3;
        text-align:right;
        &.calif{
          color:$grisNivel5;
          font-family: "Poppins-Bold";
        }
      }
    }
  }
  
  .groupIcons{
    
    $color:$grisNivel3;
    .compartir{
      width: 30px;
      height:30px;
      fill:$grisNivel3;
      path{
        stroke:$grisNivel3;
        stroke-width:5;
      }
      &:hover{
        cursor: pointer;
        fill:$naranjaNivel2;
        path{ stroke:$naranjaNivel2;}
      }
    }

    .calendario{
      margin-right: 8px;
      height: 30px;
      transform:translateY(-3px);
      cursor: pointer;
    }

    span{
      font-family: "Poppins-Bold";
      font-size: 15px;
      margin: 0 5px;
    }
    .corazon{
      width: 27px;
      height: 27px;
      stroke:$naranjaNivel2;
      stroke-width:10;
      cursor:pointer;
      fill:none;
      &.filled{fill: $naranjaNivel2;}
    }
    .loadingTemp{
      transform:translateY(-3px);
      font-size: 27px;
      svg{fill:$naranjaNivel2;}
      display: none;
    }
  }
  .perfil{
    .imgPerfil{
      height: 100px;
      width: 100px;
      border-radius: 5px;
      background: white;
      margin-right: 15px;
      img{width: 100%;border-radius: 5px;}
    }
    .name{

      label{
        font-family: "Poppins-Bold";
        font-size: 35px;
        line-height: 40px;
        display: block;
        color:$grisNivel5;
      }
      span{
        font-size: 16px;
        font-family: "Poppins-Medium";
        color:$grisNivel3;
        display: block;
      }
      svg{
        width: 25px;
        fill:$grisNivel3;
        transform: translate(-5px,-50%);
        &:hover{ fill:$naranjaNivel2; }
      }
      i{cursor: pointer;}
    }
  }

  .contentEmpresa{
    margin: 20px 0;
    height: 535px;
    transition: all .5s;
    @media screen and(min-height: 900px){
      height: 730px;
      .groupSeleCat{
        height: 590px!important;
      }
      .listCategorias{
        height: 530px!important;
      }
    }
    
    .groupSeleCat{
      height: 490px;
      width: 200px;
    }

    .inputBusquedaPadre{
      background: $blanco;
      height: 70px;
      margin-top: -3px;
      margin-bottom: 8px;
      width: 200px;
      padding: 2px;
      .labelfilter{
        text-align: center;
      }
      .inputBusqueda{ height: 40px; }
      i{
        background: $grisNivel1;
        height: 25px;
        width: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px 0 0 5px;
      }
      input{
        @include clearBorder;
        width: 160px;
        height: 25px;
        border-radius: 0 5px 5px 0;
        background: $grisNivel1;
      }
    }

    .listCategorias{
      width: 200px;
      height: 345px;
      background: white;
      border-radius: 5px;
      .itemCategoria{
        padding: 5px 10px;
        height: 40px;
        font-family: "Poppins-Medium";
        font-size: 14px;
        font-weight: medium;
        color:$grisNivel5;
        transition: all .5s;
        cursor: pointer;
        &.active{
          color:$naranjaNivel2;
          background-color:$grisNivel5;
        }
      }
    }

    .listProductos{
      height: 100%;
      //width: 50px;
      flex-grow: 1;
      padding:0;
      margin-left: 15px;
      .listProductosContent{
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        scroll-behavior: smooth;
        &::-webkit-scrollbar { width: 4px; }
        &::-webkit-scrollbar-button{ display: none; }
        &::-webkit-scrollbar-track {background:transparentize($grisNivel5,.6);} 
        &::-webkit-scrollbar-thumb { background-color:$grisNivel5; border-radius: 10px;}
      }

      .noResultss{
        height: 80%;
        text-align: center;
        img{height: 100%;}
        &:after{
          content: '¡No se encontraron resultados!';
          display: block;
          margin-top: 20px;          
        }
      }
      
      .bloqueDeProductos{ margin-left: -10px; }

      .titleCategoria{
        font-family: "Poppins-SemiBold";
        font-size: 16px;
        color: $naranjaNivel2;
        margin-top: 10px;
        &.nomg{margin-top: 0px;}
      }

      .tarjetaProductoConDesc{
        background: white;
        border-radius: 5px;
        margin: 5px;
        //padding: 10px;
        padding: 5px;
        width: 300px;
        cursor: pointer;
        position: relative;
        .productoNombre{
          font-size: 14px;
          height: 20px;
          font-family: "Poppins-SemiBold";
          margin:0 auto;
          margin-bottom: 5px;
          max-width: 240px;
          text-align: center;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          color: $grisNivel5;
        }

        .imgTarjeta{
          border-radius: 5px;
          width:100px;
          height: 100px;
          background: $blanco;
          img{ width: 100px; background: $blanco; border-radius: 5px; &.igmError{width: 90px;} }
        }
        
        .infoTarjeta{
          width: 160px;
          height: 100px;
          margin-left: 10px;
          .productoDesc{
            font-family: "Poppins-Regular";
            font-size: 13px;
            line-height: 15px;
            color:$grisNivel3;
            height: 74px;
            text-align: justify-all;
            white-space: pre-wrap;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;            
          }
          .iconPromo{
            height: 25px;
            svg{
              height: 100%;
              fill:$naranjaNivel2;
            }
          }
          .productoPrec{            
            font-family: "Poppins-Regular";
            font-size: 12px;
            color: $grisNivel5;
            text-align: right;
            margin-right: 5px;
          }
        }

      }
    }

  }

}

.poopShare{
  .ant-popover-inner-content{
    padding: 10px!important;
  }
}

.Share-Popover{
  display: flex;
  justify-content: center;
  align-items: center;
  
  div{
    width: 30px;
    height: 30px;
    margin: 0 5px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    svg{
      width: 30px;
      height: 30px;
      border-radius: 100%;
    }
    img{
      width: 30px;
      height: 30px;
      border-radius: 100%;
    }
  }
  .telefono{
    background: black;
    svg{
      fill: white;
      width: 20px;
      height: 20px;      
    }
  }

  .share{
    background: $naranjaNivel2;
    svg{transform: translate(2px,-1px);fill:$blanco;}
  }

}

.itemBack{
  cursor: pointer;
  i{ font-size: 17px; svg{fill:$naranjaNivel2;} }
  span{ margin-left: 5px; }
  margin: 15px 0;
  transition: all .2s;
  &:hover{
    color:$naranjaNivel2;
    i{ font-size: 17px; svg{fill:$naranjaNivel2;} }
  }
}

.modalPortadaPapa{
  background: rgba(0,0,0,.7);
}

.modalPortada{
  width: auto!important;
  .ant-modal-content{border-radius: 10px;background-color:transparent;}
  .ant-modal-body{
    border-radius: 10px;
    padding: 0;
    height: 538px;
    overflow: hidden;
    position: relative;
    width:960px;
    background-color:transparent;
    img{
      border-radius: 10px;
      width: 100%;
      position: absolute;
      top:50%;left: 50%;
      transform: translate(-50%,-50%);
    }
  }
}

.nodalRegistroStripe .detalleProducto{
  *{transition: all .5s;}
  width: 500px!important;
  max-height: 600px;
  overflow-y: auto;
  padding-bottom: 20px;
  /**/
  &::-webkit-scrollbar{
    width: 10px;
    background-color:$blanco;
    border-radius: 100%;
  }
  &::-webkit-scrollbar-button{ display: none; }
  &::-webkit-scrollbar-track, &::-webkit-scrollbar-thumb{
    border-style: solid;
    border-width: 2px 6px 2px 0px;
    //border-width: 10px;
    border-color: rgba(255,255,255,0);
    background-clip: padding-box;
    &:hover{
      cursor: pointer;
      border-width: 2px 4px 2px 0px;
    }
  }  
  &::-webkit-scrollbar-track{ background-color: transparentize($grisNivel5, .5);}
  &::-webkit-scrollbar-thumb{
    background-color:$grisNivel5;
    border-radius: 10px;
  }
  &.noscroll{
    &::-webkit-scrollbar{display: none!important;}
    &::-webkit-scrollbar-track, &::-webkit-scrollbar-thumb{display: none!important;}
    width: auto!important;
    padding: 20px 25px;
  }
  /**/
  .contentclose{
    padding: 15px 5px 0 0;
    .title{
      font-family: "Poppins-SemiBold";
      padding-left:10px;
      color:$naranjaNivel2;
      font-size: 16px;
    }
    &.withPPE{
      padding: 0;
      .title{padding-left:0px;}
      .iconCloseModal{ margin-right:0px; }
    }
    .nombre{
      font-family: "Poppins-Medium";
      font-size: 20px;
    }
  }

  .iconCloseModal{
    font-size: 12px;
    cursor: pointer;
    background: $grisNivel5;
    padding: 5px;
    border-radius: 100%;
    svg{fill:white;}
    margin-right:10px;
  }

  .portadaProducto{
    img{
      //width: 380px;
      width: 420px!important;
      border-radius: 10px!important;
      transition: all .0s!important;
      &.igmError{ width: 120px;}
    }
  }
  .info{
    margin: 10px auto;
    font-size: 15px;
    width: 420px;
    .inputs-group{
      margin-top: 10px;
      padding: 0;
    }
    &.mgtopNeg{
      width: auto;
      margin: 0 auto;
    }
    &.ancher{width: 450px;}
  }

  .btnAdd.adpromo{
    margin:10px  0 0 0;
    cursor: pointer;
    padding: 8px;
  }

  .info .itemPromo{
    box-shadow: 0 0 10px 1px $grisNivel2;
    border-radius: 5px;padding: 5px;
    margin-top:5px;
    padding: 10px 5px;
    padding-left: 8px;
    cursor: pointer;
    &:hover{
      box-shadow: 0 0 10px 2px $grisNivel2;
    }
    .selector{
      margin-right: 5px;
      .radioFake{
        height: 18px;
        width: 18px;
        background: $blanco;
        border-radius: 100%;
        border: 1px solid $naranjaNivel2;
        position: relative;
        &.selected{
          &:after{
            content: '';
            position: absolute;
            top: 50%;left: 50%;
            transform: translate(-50%,-50%);
            background: $naranjaNivel2;
            height:12px;
            width: 12px;
            border-radius: 100%;
          }
        }
      }
    }
    .titulo{
      font-family: "Poppins-SemiBold";
      color:$grisNivel5;
    }
    .desc{
      font-family: "Poppins-Medium";
      color:$grisNivel3;
      font-size: 14px;
    }
  }
  .nombre{
    font-family: "Poppins-SemiBold";
    font-size: 15px;
    color: $grisNivel5;
  }
  .descripcion{
    font-size: 13px;
    color: $grisNivel3;
    text-align: justify;
    white-space: pre-wrap;
    line-height: 17px;
    margin: 3px auto;
  }
  
  .ntaPromo{
    font-size: 13px;
    color: $naranjaNivel2;
    font-family: "Poppins-Medium";
  }

  .preio{
    flex-flow: column;
    span{
      font-family: "Poppins-SemiBold";
      font-size: 13px;
      text-align: right;
      color: $grisNivel5;
      font-size: 14px;
      &.notvalid{
        font-family: "Poppins-Regular";
        color: $grisNivel3;
        text-decoration: line-through;
      }
    }
  }

  .controlCantidad{
    margin: 10px 0;
    .ctrl{
      margin: 0 5px;
      font-size: 18px;
      line-height: 18px;
      font-family: "Poppins-Medium";
      box-shadow: 0 0 5px 1px $grisNivel2;
      width: 30px;
      height: 25px;
      border-radius: 5px;
      cursor: pointer;
    }
    span{
      margin: 0 5px;
      font-family: "Poppins-SemiBold";
      font-size: 17px;
    }
  }
  .ant-input.inputs-input{
    box-shadow: none;
    outline: none;
    border-radius: 0;
    background: $grisNivel1;
    border-radius: 10px;
    border: none;
  }
  .btnViewPromo{
    background: $naranjaNivel2;
    color:$blanco;
    padding: 5px 10px;
    margin: 0;
    border-radius: 5px;
    margin-right: -2px;
    font-family: "Poppins-Medium";
  }
  .btnAdd{
    width: auto!important;
    font-family: "Poppins-SemiBold";
    padding: 5px 10px;
    margin:0 10px;
    border-radius: 5px;
    background: $naranjaEnd;
    color: white;
    span{ margin-right: 20px; font-family: inherit; cursor: inherit;}
    label{font-family: inherit; cursor: inherit;}
    &.nostock{
      background: $grisNivel2;
      cursor: not-allowed;
      span,label{cursor: inherit;}
    }
    &.nosee{
      background: white;
      color: $grisNivel5;
      border: 2px solid $grisNivel3;
      span,label{cursor: inherit;}
    }
  }
  .btnCancel{
    background: white;
    color: $blanco;
    font-family: "Poppins-SemiBold";
    padding: 5px 10px;
    background: $grisNivel5;
    border-radius: 5px;
  }

  .listVar{
    width: 450px;
    margin: auto;
  }

  /*.itemVar{
    margin-top: 10px;
    padding: 5px;
    border-bottom:1px solid $grisNivel3;
    .inputs-group{
      padding:2px 10px;
      opacity: 0;
      height: 0;
      overflow: hidden;
      transition: height .5s;
    }
    .img{
      width: 70px;
      border-radius: 5px;
      background: $blanco;
      border: 1px solid $grisNivel1;
      margin-right: 5px;
      img{
        width: 70px;
        border-radius: 5px;
        &.igmError{height: 55px; width: 55px; display: block; margin: auto;}
      }
    }
  }*/

  .itemVar{
    margin-top: 5px;
    padding: 5px;
    box-shadow: 0 0 5px 1px $grisNivel2;
    position: relative;
    .iconPromo{
      position: absolute;
      svg{
        height: 25px;
        fill:$naranjaNivel2;
      }
    }
    .img{
      width: 70px;
      border-radius: 5px;
      background: $blanco;
      border: 1px solid $grisNivel1;
      margin-right: 10px;
      img{
        width: 70px;
        border-radius: 5px;
        &.igmError{height: 55px; width: 55px; display: block; margin: auto;}
      }
      .lime{
        position: absolute;
        left: 0px;top: 0;
      }
    }
  }

  .controlsVar{
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    label{
      text-align: right;
      font-family: "Poppins-Medium";
      color:$grisNivel5;
    }
  }

  .addResenaLink{
    text-decoration: underline;
    color: #40b7ff;
    font-size: 12px;
    font-family: "Poppins-SemiBold";
    margin-left: 10px;
    cursor: pointer;
  }

  .controlesMini{
    .ctrl{
      margin: 0 5px;
      font-size: 12px;
      font-family: "Poppins-Medium";
      box-shadow: 0 0 5px 1px $grisNivel2;
      width: 22px;
      height: 15px;
      border-radius: 5px;
      text-align: center;
      cursor: pointer;
    }
    span{
      margin: 0 5px;
      font-family: "Poppins-SemiBold";
      font-size: 12px;
    }
  }

  .metaVar{
    flex-grow: 1;
    height: 45px;
    .inforVar span{
      font-size: 14px;
      font-family: "Poppins-SemiBold";
      display: block;
      &.withColor{
        color: $naranjaNivel2;
        font-family: "Poppins-Medium";
        text-decoration: underline;
        cursor: pointer;
      }
    }
    .detail{
      font-size: 14px;
      font-family: "Poppins-ExtraLight";
      color: white;
      padding: 5px 20px;
      border-radius: 5px;
      cursor: pointer;
      background: $naranjaNivel2;
      &:hover{ box-shadow: 0px 0px 5px 1px $colorGrisBajo; }
    }
  }
}

.citasPerfil{
  *{transition: all .5s;}
  .head{
    border-radius: 8px 8px 0px 0;
    background: $naranjaNivel2;
    height: 60px;
    img{height: 45px;}
  }
  
  .bodyModal{
    padding: 10px 30px;
    .title{
      font-size: 17px;
      line-height: 17px;
      font-family: 'Poppins-Bold';
      text-align: center;
      color:$naranjaNivel2;
      margin-top: 5px;
    }
    .inputs-group{
      margin: 5px 0;
      padding: 0;
    }
    .MyDate{
      @include clearBorder;
    }
    .inputs-label{
       font-size: 12px;
       font-weight: bold;
       color: $colorGrisFuerte;
    }
  }
  
  .listCitas{
    max-height: 430px;
    transition: all .5s;
    overflow: auto;
    &::-webkit-scrollbar{width:5px;}
    &::-webkit-scrollbar-button{ display: none; }
    &::-webkit-scrollbar-track {background:$grisNivel1;} 
    &::-webkit-scrollbar-thumb { background-color:$grisNivel4; border-radius: 5px; &:hover{ background-color:$grisNivel5;} }
  }

  .cargando{
    height: 90px;
    flex-direction: column;
    &:after{
      content: 'Actualizando citas';
      display: block;
      color:$naranjaNivel2;
    }
  }
  
  .nonResult{
    height: 280px;
    img{height: 250px;}
    flex-direction: column;
    &:after{
      content:'¡No hay citas disponibles!';
      font-family: 'Poppins-Medium';
      display: block;
    }
  }

  .instanciaCita{
    border-bottom: 1px solid $grisNivel2;
    cursor: pointer;
    .horas{
      flex-direction: column;
      min-width: 45px;
      min-height: 60px;
      max-width: 45px;
      max-height: 60px;
      text-align: center;
      background-color: $grisNivel1;
      border: 1px solid $grisNivel2;
      border-bottom: none;
      span{display: block;}
    }
    .texto{
      flex-grow: 1;
      height: 60px;
      &.descanso{background: $grisNivel1;}
      .barras{
        height: 100%;
        width: 10px!important;
        margin-right: 15px;
        &.pendiente{background: #848484;}
        &.confirmada{background: #22B573;}
        &.noasistio{background: #ED3B48;}
        &.asistio{background: #37C8EF;}
        //&.cancelada{background: #ED3B48;}
        &.nodisponible{}
      }
      .inTexto{
        flex-direction: column;
        width: 100%;
        span{
          color: $grisNivel5;
        }
        label{
          display: block;
          white-space: pre-wrap;
          overflow: hidden;
          max-height: 30px;
          line-height: 15px;

          color:deepskyblue;

          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;

        }
      }
    }
    &:hover{
      background-color:transparentize($grisNivel1,.6);
    }
  }

}

.controlesNuevaCita{
  border-radius: 5px;
  background-color:$blanco;
  padding: 20px 15px;


  .ant-calendar-picker-input.ant-input{
    @include clearBorder;
    border-bottom: 1px solid $grisNivel5!important;
    border-radius: 0;
    margin-top: -10px;
  }


  *{transition:all .5s;}
  .title{
    color: $naranjaNivel2;
    font-size: 16px;
    font-family: "Poppins-Medium";
  }

  .nta{
    color: $grisNivel4;
    font-size: 16px;
    margin: 5px 0;
    font-family: "Poppins-Medium";
    &.hoverable{
      cursor: pointer;
      &:hover{color:$naranjaNivel2;}
    }
  }

  .detalle{ label{font-weight: bold;} }

  .data{
    margin: 10px 0;
    &.middle{
      label{ width: 50%; }
      .inputs-input{
        &:nth-child(1){width: 40%;}
        &:nth-child(2){ width: 50%; }
        &.hoverable{
          cursor: pointer;
          &:hover{
            border-bottom: 2px solid $naranjaNivel2!important;
          }
        }
      }
    }
    .ant-select-selection.ant-select-selection--single{
      @include clearBorder;
      border-bottom: 1px solid $grisNivel4!important;
      border-radius: 0px;
    }
  }

  .ant-input.inputs-input{
    @include clearBorder;
    display: block;
    border-radius: 0;
    border-bottom: 1px solid $colorGrisMedio!important;
    transition: all .5s;
    &:focus{
      border-bottom: 2px solid $naranjaEnd!important;
    }
  }

  .btnAdd{
    margin-top: 10px;
    width: 100%;
    padding: 8px 0;
    background-image:linear-gradient(to right,$naranjaStart 50%,$naranjaNivel2);
    font-family: "Poppins-Medium";
    color: $blanco;
    border-radius: 5px;
  }

  .contButtons{
    margin-top: 15px;
    .btn{
      font-family: "Poppins-Medium";
      font-weight: normal!important;
      box-shadow: 0px 0px 3px 0px $colorGrisMuyBajo;
    }
  }

}

.reagenda{
  border-radius: 5px;
  background-color:$blanco;
  padding: 20px 15px;
  *{transition:all .5s;}

  .title{
    color: $naranjaNivel2;
    font-size: 16px;
    font-family: "Poppins-Medium";
  }

  .nta{
    color: $grisNivel4;
    font-size: 13px;
    font-family: "Poppins-Medium";
  }

  .mycalendar{}
  .btn{
    width: 120px!important;
    &.btn1{margin-left: 10px!important;}
  }

  .citasCinepolis{
    padding: 0 10px;
    margin: 10px auto;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    .cuadrito{
      flex-direction: column;
      width: 50px;
      height: 50px;
      background: $blanco;
      margin: 5px;
      border-radius: 5px;
      box-shadow: 1px 1px 5px 0px $colorGrisMuyBajo;
      &:hover{
        cursor: pointer;
        box-shadow: 1px 1px 10px 2px $colorGrisMuyBajo;        
      }
    }
    .cirulo{
      background: white;
      width: 10px;height: 10px;
      border-radius: 100%;
      &.pendiente{background: #848484;}
      &.confirmada{background: #22B573;}
      &.noasistio{background: #ED3B48;}
      &.asistio{background: #37C8EF;}
      &.cancelada{background: #ED3B48;}
      &.nodisponible{}
      &.descanso{background: #ED3B48;}
    }
  }

}





