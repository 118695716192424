@import './../../../color.scss';

#toshow{
	color: $rojoNibiru;
	font-size: 15px;
	margin-top: 15px;
}

#referenciaPago{
	display:none;
	visibility: hidden;
	opacity: 0;
}


.modalCarritoParent{
	.ant-modal.modalCarrito{
		
		width: auto!important;

		.ant-modal-content{
			background: $grisNivel1;
		}

	.iconCloseModal{
	  font-size: 16px;
	  cursor: pointer;
	  background:transparentize($grisNivel5, 0);
	  padding: 5px;
	  border-radius: 100%;
	  svg{fill:white;}
	  &.fter{
	  	position:absolute;
	  	right:0;
	  	top:0;
	  	transform:translate(50%,-50%);
	  }
	}
	.itemBack span{
		font-size: 16px;
	}

		.contentCarrito{
			width: 65vw;
			max-width: 1250px;
	  }

	  .headCarrito{
			
			position:relative;		

			.controls{
				.itemBack{
					margin-top: -10px;
				}
			}

			
			.content{
				margin-top: -10px;
				font-size: 20px;
				color: $grisNivel5;
				font-family: "Poppins-Semibold";
				border-bottom: 3px solid $grisNivel3;
				padding-bottom: 10px;	
			}

			.sucStatus,#placeHolderHorario{
				font-size:18px;
				line-height: 20px;
				font-family: "Poppins-Regular";
				width: fit-content;
				&.Cerrado{color: $rojoNibiru;}
				&.Abierto{color: green;}
			}

			.infoPedido{
				flex-direction: column;
				margin-top: 7px;
				img{
					height:95px;
					border-radius:100%;
				}

				.presentation{
					width: 100%;
					max-width: 1150px;
					margin: auto;
    			padding: 0 30px;
				}
				.dataEmpresa{ margin: 0 3rem;}
			}

		}

		.info{
			color:$grisNivel5;

			.nombreEmpresa{
				font-family: "Poppins-SemiBold";
				font-size: 25px;
				line-height: 27px;
				padding:8px 5px;
			}

			.nombreSuc{
				font-family: "Poppins-Medium";
				font-size: 18px;
				line-height: 20px;
				&.clor{
					color:$naranjaNivel2;
				}
			}

		}

	  .bodyCarrito{
			
			//background-color:deepskyblue ;
			padding: 10px;
			margin: auto;
			position: relative;
			left: 50%;
			transform: translate(-50%);
			width: 80%;
			min-width: 700px;

			transition: all .5s;
			*{transition: all .5s;}
			&.bodyCarrito1{
				max-height: 430px;
				margin-top: 5px;
				overflow-y:auto;

				width: 100%;

				scroll-behavior: smooth;
			
				&::-webkit-scrollbar{ width: 4px; }
				&::-webkit-scrollbar-button{ display: none; }
				&::-webkit-scrollbar-track { background:transparentize($grisNivel5,.6); } 
				&::-webkit-scrollbar-thumb {background-color:$grisNivel5; border-radius: 10px; }

			}
	  }

	  .footerCarrito{
			font-size: 20px;
			color: $grisNivel5;
			font-family: "Poppins-Semibold";
			text-align: right;

			&.nivel2{
				margin: auto;
				//background-color: deepskyblue;
				padding: 0 30px;
				max-width: 1150px;
			}

			.columner{}

		.conlumInner{
			width: 45%;
			//background-color: deeppink;
			//max-width: 380px;

			&:nth-child(2){
				.totalSucursal{
					padding: 8px 0;
					&:last-child{
						border-top: 2px solid $grisNivel5;
						margin-top: 5px;
					}
				}
			}
		}

		.subinfo{
			margin: 5px auto;
			div{
				font-family: "Poppins-Medium";
				color:$grisNivel3;
				text-align: left;
				font-size: 15px;
			}
		}


		.totalSucursal{
			font-size: 16px;
			font-family: "Poppins-Regular";
			svg{
				cursor: pointer;
				fill: $naranjaNivel2;
				margin: 0px 2px;
			}
			.labelForList{
				flex-grow: 1;
				max-width: 180px;
				text-align: left;
			}
		}
			.btnOrdenar {
				button{
					font-size: 16px;
					font-family: "Poppins-Regular";
					background: $naranjaNivel2;
					color: $blanco;
					padding: 5px 10px;
					margin-top: 10px;
					border-radius:5px;
				}
				span{
					font-size: 14px;
					color: $naranjaNivel2;
					margin-top: 10px;
					cursor: pointer;
				}
				&.nosee{
			  background: white;
			  color: $grisNivel5;
			  border: 2px solid $grisNivel3;
			  font-size: 16px;
				font-family: "Poppins-Regular";
				margin-top: 10px;
					border-radius:5px;
					padding: 4px 5px;
					cursor: pointer;
			}
			}
			.contentData{
				width: 150px;
				//flex-grow: 1;
				text-align: right;
				font-size: 16px;
				font-family: "Poppins-Bold";
				overflow: hidden;
				white-space: nowrap;
				text-overflow:ellipsis;

				&.clor{
					width:auto!important;
					min-width: 190px;
					color:$naranjaNivel2;
					font-family: "Poppins-Regular";
					cursor: pointer;
					text-align: left!important;
				}
			}
	  }

	  .itemEmpresaCarrito{
		margin: 5px;
		padding: 5px;
		border-bottom: 1px solid $grisNivel3;
		img{
			height: 90px;
			border-radius:5px;
		}
		.detalleEmpresa{
			padding-left:10px;
			flex-direction: column;

			.nombreE{
				font-family: "Poppins-Medium";
				font-size: 16px;
				color: $grisNivel4;
			}
				.precio{
					font-family: "Poppins-Bold";
				font-size: 20px;
				color: $grisNivel5;
			}
			.descP{
				font-family: "Poppins-Medium";
				font-size: 14px;
				color: $grisNivel5;
				width: 100%;
				text-align: justify;
				overflow: hidden;
				height: 60px;

				white-space: pre-wrap;
		  display: -webkit-box;
		  -webkit-line-clamp: 2;
		  -webkit-box-orient: vertical;
		  text-overflow: ellipsis;
			}
				.distri{ font-family: "Poppins-Medium"; }
				.delete{cursor: pointer;color: $naranjaNivel2;}
		}

		span{
			font-size: 14px;
			font-family: "Poppins-ExtraLight";
			color: white;
			padding: 5px 20px;
			border-radius: 5px;
			cursor: pointer;
			&.detail{
				background: $naranjaNivel2;
				&:hover{ box-shadow: 0px 0px 5px 1px $colorGrisBajo; }
			}

			&.precio{
				font-family: "Poppins-Semibold";
				font-size: 16px;
				color: $grisNivel5;
				height: 90px;
				width: 150px;
				text-align: right;
				cursor: initial;
				flex-direction: column;
				.controls{
					width: 150px;
					display: block;
					label{
						cursor: pointer;
						font-family: "Poppins-Bold";
						font-size: 16px;
						color: $grisNivel5;
						border-radius:20px;
						width:35px;
						display: inline-block;
						text-align: center;
						background: white;
						&.cantidad{
							cursor: initial;
							background: none;
							border-radius:0;
							color:$grisNivel5;
						}
					}
				}
				.content{
					font-family: "Poppins-Semibold";
					color: $grisNivel5;
					font-size: 16px;
					width: 150px;
					padding: 0;
					text-align: right;
				}
			}
		  }
	  }

	  .configPedido{ }

	}
}

.itemPaquetitoCarrito{

	&.mini{
		.infoPack{
			width: 60%!important;
			.cnt{width: auto;}
			.nbr,.cmtr{font-size: 14px;line-height: 15px; max-height: 16px;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;}
			.cmtr{font-size: 13px;line-height: 14px;}
			.midler{ width: auto!important;}
		}
		.precio{
			width: 40%!important;
			width: auto;
			font-size: 14px;
		}
		&:nth-child(odd){ background: $grisNivel1; }
	}

	&.endRow{ border-bottom: 2px solid $grisNivel3; }
	&.hijo{
		.precio{ padding-right:33px; }
		&.mini .precio{padding-right: 0!important;}
	}

	cursor: pointer;
	width: 100%;
	
	.infoPack{

		width: 70%;
		
		.cnt{width: 20%; padding: 0 5px; color: $naranjaNivel2;}
		.midler{ width: 80%; }

		.nbr,.cmtr{
			position:relative;
			width: 100%;
			padding: 0 5px;
			svg{fill: $naranjaNivel2;height: 20px;margin-left: 3px;transform: translateY(5px);}
		}
		.cmtr{font-size: 13px;}
		.deleted{
			font-size: 17px;
			svg{
				fill: red;
				position: absolute;
				top: 50%;left: 0%;
				transform:translate(-100%,-50%);
			}
		}
	}

	.precio{
		//width: 30%;
		flex-grow:1;
		font-size: 15px;
		color: $grisNivel5;
		text-align: right;
		font-family: "Poppins-Semibold";
		padding: 3px;
		.oldnormal,.normal{font-family: inherit;}
		&.tgh{
			flex-direction:column;
			.oldnormal{text-decoration: line-through;font-family: "Poppins-Medium"; color:$grisNivel3; }
		}
	}

	.opelim{
		width:30px;;
		img{
			width:20px;
			cursor:pointer;
			//&.moved{transform: translateY(100%);}
		}
	}

	/*&:nth-child(even){background: $grisNivel1; }
	&:nth-child(odd){ background: $blanco; }
	&:last-child{ border-bottom: 2px solid $grisNivel3; }*/
	
	&.header{
		color:$grisNivel5;
		background: $grisNivel2;
		.cnt,.nbr{padding: 3px;color:$grisNivel5;}
		//.precio{text-align:left;}
	}

}