@import './../../../color.scss';

$bR:10px;
.modalProductosPromo{
	.ant-modal-content{
		border-radius: $bR;
		.ant-modal-header,.ant-modal-body,.ant-modal-footer{ padding: 0; border-radius: 0; border: none; }
		.ant-modal-header{border-top-left-radius: $bR; border-top-right-radius: $bR;}
		.ant-modal-footer{border-bottom-right-radius:$bR; border-bottom-left-radius: $bR;}

		.ant-modal-title{
			color: $naranjaEnd;
			font-size: 17px;
			text-align: center;
			padding: 10px;
			font-weight: bold;
		}
		.inputs-group{
			display: block;
			position: relative;
			transition: all .3s;
			transition: all.5s;
			width: 400px;
			.inputs-label{
			   font-size: 12px;
			   font-weight: bold;
			   color: $colorGrisMedio;
			   display: block;
			   span{
					font-weight: normal;
			   }
			}
			.inputs-input{
				display: block;
				font-size: 14px;
				height: 30px;
				width: 100%;
				border:none;
				outline: none;
				position: relative;
				padding-left: 10px;
				border-bottom: 1px solid $colorGrisMedio;
				transition: all .3s;
				color: $colorGrisMedio;
				&:focus{ border-bottom-color: $naranjaEnd; border-bottom-width: 2px; }
				&:read-only{
					background-color:$colorGrisMuyBajo;
				}
			}
		}

		.cont-ProductsPromo{
			.ant-select.ant-select-enabled,
			.ant-select.ant-select-open.ant-select-focused.ant-select-enabled,
			.ant-select.ant-select-focused.ant-select-enabled{
				.ant-select-selection.ant-select-selection--single,
				.ant-select-selection--multiple{
					box-shadow: none;
					border: none;
					outline: none;
					border-bottom: 1px solid $colorGrisMedio;
					border-radius: 0;
					width: 200px;
					&:focus{
						border-bottom: 2px solid $naranjaEnd;
					}
				}
				svg{
					fill: $naranjaEnd;
					height: 20px;
					width: 15px;
				}
			}

			&.ProductsPromo2{
				.productListPromo{
					height: 320px;
				}
				.contcont{
					margin-top: 15px;
					margin-bottom: -10px;
					display: flex;
					padding-left: 20px;
				}
			}
	
			height: 500px;
			//height: 50vh;
			width:520px;
			.filtrosPromo{
				margin-top: 20px;
				margin-bottom: -15px;
				height: 70px;
				position: relative;
				.floatFiltros{
					position: absolute;
					width: 435px;height: 70px;
					top: -10%; left: 1%;
					background-color: white;
					z-index: 2;
					display: flex;
					padding: 5px;
					box-shadow: 0px 0px 5px $colorGrisBajo;
					display: none;
					&.floatFiltros2{display: flex;}
					.inputs-group{flex-grow: 1;}
				}
				.headFiltros{
					display: flex;
					align-items: center;
					justify-content: space-evenly;
				}
				.btn-Filtrar{
					display: block;
					font-size: 14px;
					padding:5px 10px;
					box-shadow: 0px 0px 5px $colorGrisBajo;
					cursor: pointer;
				}
			}
			.productListPromo{
				height: 370px;
				overflow-y: auto;
				position: relative;
				&::-webkit-scrollbar{ width:  5px; }
				&::-webkit-scrollbar-button{ background:white; }
				&::-webkit-scrollbar-track{ background:white; width: 10px;} 
				&::-webkit-scrollbar-thumb{ background-color:$naranjaEnd; border-radius: 3px;width: 8px; }
				&::-webkit-scrollbar-corner{ background: gold;}
				.nomCate{}
				.imgnonResult.imgnonResult2{
					height: 100%;
					width: 100%;
					z-index: 0;
					margin: 0;
					position: relative;
					.nonResultChild{
						width: 80%;
						height: 80%;
						img{
							width: 80%;
							height: 100%;
						}
						&:after{ top: 105%!important;}
					}
				}
			}
		}
		.contTipoDespuesto{
			padding: 10px;
			.opDescuento{
				display: flex;
				justify-content: space-evenly;
				align-items: center;
				margin:5px ;
			}
		}

		.footPromoModal.newProductFoot{
			margin: 0;
			width: 97%;
		}
	}
}

.bodyFormPromo{
	width:100%;
}

.newProductDiv2{
	.fechaSexo{
		.inputs-group-inline{
			width: 50%;
		}
	}
}

.contControles{
	.tiposFiltrados2{
		.bloqueFiltro{
			height: 130px;
			.cleanDiv{
				height: 100%;
				display: flex;
				justify-content: flex-end;
				align-items: flex-end;
			}
		}
	}
}

.cleanDiv2{	height: 100px!important; }

.cont-Promociones{
	margin: 20px auto;
    width: 80%;
    max-width: 1400px;
    display: flex;
    //justify-content: space-around;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.productListPromo{
	padding: 0px 15px;
	.nomCate{
		color: $naranjaStart;
		font-size: 15px;
		font-weight: bold;
		margin: 15px 0px 5px 0px;
	}
	.myCheckProd{
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 5px 0px;
		font-size:14px;
		//border: 1px solid red;
		div{
			display: flex;
			justify-content: flex-start;
			align-items: center;
			width:350px;
			padding-left: 10px;
			input[type="checkbox"]{margin: 0px;margin-right: 5px;}
		}
		.nomb{
			width: 300px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			//color: $colorGrisFuerte;
			&.nocheck{
				//color: $colorGrisFuerte;
				font-weight: bold;
				//margin-left: 20px;
			}
			&.nomHijo{}
		}
		.prec{
			width: 150px;
			text-align: right;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			&.nocheck{
				//color: $colorGrisFuerte;
				font-weight: bold;
				margin-left: 20px;
			}
		}
		.radioBubook{ margin: 0 5px 0 -2px; }
	}
}

.contTarjetasProductos{
	margin: 20px auto;
	margin-bottom: 0;
    width: 80%;
	max-width: 1400px;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	overflow-x: auto;
	&::-webkit-scrollbar{height: 5px;}
    &::-webkit-scrollbar-button{ background:white; }
    &::-webkit-scrollbar-track {background:white;} 
    &::-webkit-scrollbar-thumb { background-color:$naranjaEnd; border-radius: 5px; }
	.contTarjetasProductosChild{
		min-width: 800px;
		width: 100%;
	}
}

.tarjetaAddPromo{
	/*width: 500px;
	height: 250px;*/
	box-shadow: 0px 0px 10px 0px $colorGrisMuyBajo;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	&.tarjetaAddPromo1{
		width: 100%;
		height: auto;
		margin:5px 0px;
	}
	&.tarjetaAddPromo2{
		width: 100%;
		.HeadNaranjaTargetaPromo{
			border-top-right-radius: 0px;
		}
		.HeadGrisTargetaPromo{
			border-top-right-radius: 5px;
			//background: $naranjaEnd;
			//color: white;
		}
	}
}

.HeadNaranjaTargetaPromo{
	background: $naranjaEnd;
	padding: 5px;
	color: white;
	font-weight: bold;
	font-size: 17px;
	border-top-right-radius: 5px;
	border-top-left-radius: 5px;
	padding-left: 25px;
	.head2{
		text-align: center;
	}
}
.HeadGrisTargetaPromo{
	background: $colorGrisMuyBajo;
	padding: 5px;
	color: $colorGrisMedio;
	font-weight: bold;
	font-size: 17px;
	.head2{
		text-align: center;
	}
}
.BodyNaranjaTargetaPromo{
	font-size: 15px;
	font-weight: bold;
	color: $colorGrisBajo;
	padding: 5px;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	cursor: pointer;
	&:nth-child(even){
		background: rgba(0,0,0,.05);
	}
	.nomBre{
		display: flex;
		align-items: center;
		height: 40px;
		padding-left: 25px;
		.brebre{
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
	}
	.descBre{
		display: flex;
		justify-content: center;
		align-items: center;
		height: 40px;
	}
	.preCio{
		text-align: right;
		padding-right: 10px;
		height: 40px;
		.preGris{
			color: $colorGrisBajo;
			text-decoration: line-through;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
		.preNan{
			color: $naranjaStart;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}
	.preCio2{
		text-align: right;
		padding-right: 10px;
		height: 40px;
		display: flex;
		align-items: center;
		.prepre{
			align-items: center;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}
	.imgTrash{
		display: flex;
		justify-content: center;
		align-items: center;
		height: 40px;
		img{height: 25px; cursor: pointer; }
	}
}
.addProd{
	text-align: right;
	color: $naranjaStart;
	text-decoration: underline;
	font-size: 14px;
	font-weight: bold;
	cursor: pointer;
	margin-top: 10px;
	margin-left: 5px;
	margin-bottom: -15px;
	display: block;
}
.newProductBodyImg{
	position: relative;
	height: 410px;
	@media (max-width:750px){
		height: 280px;
	}
	.labelForImg{
		position: absolute;
		top: 50%;left: 50%;
		transform: translate(-50%,-50%);
		cursor: pointer;
		z-index: 2;
	}
	input[type="file"]{display: none;}
	.fotoPromo{
		position: absolute;
		top: 50%;left: 50%;
		transform: translate(-50%,-50%);
		max-width: 98%;
	}
}

.targetaPromocion{
	width: 320px;
	box-shadow: 0px 0px 10px 0px $colorGrisMuyBajo;
	transition: all .5s;
	border-radius: 5px;
	margin: 3px;
	.tituloPromo{
		padding: 10px;
		.nombre{
			display: flex;
			justify-content: space-between;
			.title{
				font-size: 14px;
		        color: $colorGrisFuerte;
		        font-weight: bold;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				max-width: 80%;
			}
			.notitle{
				color: red;
				cursor: pointer;
			}
		}
		.descri{
			display: flex;
			justify-content: space-between;
			align-items: center;
			.vigencia{
				font-size: 13px;
				display: block;
			}
			.status{
				font-size: 13px;
				font-weight: bold;
				&.Activa{color:green;}
				&.Inactiva{color:red;}
			}
		}
	}
	.bodyPromo{
		width: 320px;
		height: 180px;
		position: relative;
		cursor: pointer;
		img{
			width: 100%;
			height: 100%;
			border-bottom-right-radius: 5px;
			border-bottom-left-radius: 5px;
		}
	}
	&:hover{
		box-shadow: 0px 0px 10px 5px $colorGrisMuyBajo;
		.options{
			i{
				svg{
					fill:$naranjaStart;
				}
			}
		}
	}
}
.newPromoFoot{
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	flex-wrap: wrap;
	padding: 5px;
	.addProduct{
		margin-bottom: 2px;
		text-align: right;
		color: $naranjaStart;
		text-decoration: underline;
		font-size: 14px;
		font-weight: bold;
		label{&:hover{cursor: pointer;}}
	}
}

.contentModalMapa.contentModalMapaNewPromo{
	*{transition: all .5s;}
	background: white;
	border-radius: 10px;
	padding: 15px 15px 10px 15px;
	.title{
		text-align: center;
		color:$naranjaNivel2;
		font-size: 15px;
		font-family: "Poppins-Medium";
	}
	.producto{
		margin: 15px auto;
		display: flex;
		justify-content: flex-start;
		align-items: center;

		.clickable{
			text-decoration: underline;
			color:$grisNivel3;
		}
		
		span{
			font-size: 14px;
			color:$naranjaNivel2;
			margin-right: 5px;
			font-family: "Poppins-SemiBold";
			width: 30px;
			text-align: center;
		}
		label{
			cursor: pointer;
			font-size: 14px;
			color:$grisNivel5;
			font-family: "Poppins-Regular";
			&.nombre{
				width: 300px;
				margin-right: 5px;
			}
			&.precio{
				flex-grow: 1;
				text-align: right;
			}
			&.preciosGratis{
				text-decoration: none;
				display: flex;
				flex-direction: column;
				flex-grow: 1;
				del{
					font-family: "Poppins-SemiBold";
					color:$grisNivel3;
					text-align: right;
					display: block;

				}
				label{
					font-family: "Poppins-SemiBold";
					text-align: center;
				}
			}
		}
	}
	
	.radiosbubok{
		padding: 10px;
		div{ margin: 0 20px; }
	}

	.contcont{
		margin: 0 auto 15px auto;
	}

	.btnAceptarPromo{
		background-image: linear-gradient(to right, $naranjaStart , $naranjaEnd );
		border-radius: 5px;
		padding: 8px 0;
		font-family: "Poppins-SemiBold";
		color:$blanco;
		font-size: 15px;
		cursor: pointer;
	}
}