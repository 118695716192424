@import './../color.scss';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

#motoNone{
    //display: none;
    width: 50%;
    float: left;
    @media screen and (max-width: 500px){
        display: block;
        width: 100%;
        margin:15px auto;
    }
}

#celplacesNone{
    width: 50%;
    position: absolute;
    top: 50%;left: 50%;
    transform: translate(0%,-50%);
    @media screen and (max-width: 500px){
        position: relative;
        transform: none;
        top:0;left: 0;
        display: block;
        width: 70%;
        margin:10px auto;
    }
}

#indicator1leftNone{
    @media screen and (max-width: 500px){ left: -30%; }
    position: absolute;
    display: block;
    width: 40%;
    position: absolute;
    left: -25%;
}

.moto{
    @media screen and (max-width: 830px){img{margin-left: 5%!important;}}
    @media screen and (max-width: 750px){img{margin-left: 2%!important;}}
    @media screen and (max-width: 500px){display: none;}
}

.celplaces{
    //45%
    //display: none;
    background:transparentize(red, .2);
    img{transition: all .5s;}
    @media screen and (max-width: 830px){img{margin-left: 50%!important;}}
    @media screen and (max-width: 750px){img{margin-left: 55%!important;margin-top: 7%;}}
    @media screen and (max-width: 600px){img{width:30%!important;margin-left: 70%!important;margin-top: 15%;}}
    @media screen and (max-width: 500px){display: none;}
}

.ticket{
    //width: '80%', marginLeft: '5%'
    position: absolute;
    @media screen and (max-width: 850px){margin-left: 4%!important;margin-top: 8%;}
    @media screen and (max-width: 730px){margin-left: 3%!important;margin-top: 12%;}
    @media screen and (max-width: 620px){margin-left: 2%!important;margin-top: 20%;width: 100%!important;}
    @media screen and (max-width: 620px){margin-left: 2%!important;margin-top: 20%;width: 100%!important;}
    @media screen and (max-width: 500px){
        margin-left: -20%!important;
        width: auto!important;
        height: 60%;
    }
    //@media screen and (max-width: 500px){display: none;}
}


.registerIcon{
    font-size: 60%;
    font-weight: bold;
    transform: translateY(-25%)!important;
}


.circleRight{ }

.landingWrapper{
    width: 100%;
    height: 100vh;
    width: 100vw;
    background:#F3F0F0;
    overflow: scroll;
    overflow-x: hidden;
    &::-webkit-scrollbar { width: 0; display: none; }
    scroll-behavior: smooth;
}

.crPo{ cursor: pointer; }

.landing{ position: relative;}

.container{
    width: 85%;
    margin: auto;
}

.contactBar{
    background-color:$grisNivel5;
    position: relative;
    .itemsGroup{
        color:#ffffffAA;
        display: flex;
        justify-content:flex-end;
        align-items: baseline;
        font-size: 12px;
        cursor: pointer;
    }
    .grouper{
        display: flex;
        justify-content:flex-end;
        align-items: baseline;
        width: auto;
        margin-left: 20px;
    }
    .item{
        padding: 10px 0;
        margin: 0 10px;
        font-family: 'Poppins', sans-serif;
        font-weight: normal;
        img{ height: 15px; margin-right: 7px; }
    }
    transition: all .5s;
    @media screen and (max-width: 1200px){
        .item{font-size: 9px;}
        .grouper{margin-left: 50px;}
    }
    @media screen and (max-width: 500px){
        .itemsGroup{ width: 100%;}
        .item{ display: none;}
        .grouper{
            width: 100%;
            margin: 0;
            .item{display: flex;flex-grow: 1;}
        }
    }
}
.content1{
    margin: 0;
    height: 100vh;
    background-image: url(https://bubook.nibirusystem.com/img/elementos/Grupo19235.png);
    background-size:100% 100%;
    padding-top: 20px;
    background-repeat: no-repeat;
    @media screen and (max-width: 750px){
        background-size:auto 80%;
        .navbar{font-size: 10px;}
        .container{width:99%;}
        .btn{margin: 0 5px!important;}
        .item{margin:0px!important;text-align: center;}
    }
    .navbarWrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
    }
    .logo{
        width: 40%;
        max-width: 200px;
        min-width: 80px;
        img{width: 100%;cursor: pointer;}
    }
    #icon{display: none;}
    .navbar{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 80%;
        color: $blanco;
        padding: 5px 10px;
        .item{
            margin:0 10px;
            font-family: 'Poppins', sans-serif;
            cursor: pointer;
            transition: all .5s;
        }
        transition: all .5s;
        @media screen and (max-width: 1200px){font-size: 12px; }
    }
    .btn{
        padding: 5px;
        width: 100px;
        text-align: center;
        border-radius: 30px;
        font-family: 'Poppins', sans-serif;
        font-weight: medium;
        cursor: pointer;
        margin:0 10px;
        transition: all .5s;
    }
    .btnWhite{
        color:$grisNivel5;
        background-color: $blanco
    }
    .btnBlack{
        background-color:$grisNivel5;
        color: $blanco;
    }
    .bigTitle{
        padding:5px;
        text-align: right;
        transform: translateY(20%);
        transition: all .5s;
    }
    .titleWhite{
        color: $blanco;
        font-size: 72px;
        line-height: 75px;
        font-style: italic;
        font-family: 'Montserrat', sans-serif;
        &.black{
            color:$grisNivel5;
            font-weight: bold;
        }
        transition: all .5s;
        @media screen and (max-width: 1200px){
            font-size: 52px;
            line-height: 55px;
        }
    }
    .txtMinihite{
        color: $blanco;
        font-size: 16px;
        .txtWhite{
            font-family: 'Poppins-SemiBold', sans-serif;
            font-weight: 300;
            transition: all .5s;
        }
        @media screen and (max-width: 1200px){
            .txtWhite{font-size: 15px;}
        }
    }
    @media screen and (max-width: 500px){
        height: auto!important;
        margin-bottom:40px;
        .bigTitle{
            text-align: center;
            transform: translateY(5%);
            .titleWhite{ font-size: 30px; line-height: 35px; }
        }
        .txtMinihite{
            color:$grisNivel5;font-weight: bold;
            margin: 20px 0;
        }
        .separadorRects{div{background:$grisNivel5!important;}width: 100%;}
        #icon{
            display: block;margin: 0 15px;
            svg{
                fill:$blanco;
                height: 30px;
                width: 30px;
            }
        }
        .logo{margin: 0 15px;}
        .navbar{
            display: block;
            position: absolute;
            top: 60px;right:-80%;
            z-index: 10;
            padding: 0;
            background: $grisNivel5;
            width: 50%;
            min-width: 220px;
            border-radius: 15px;
            display: none;
            right:-1px;
            &:after{
                content: '';
                display: block;
                height: 30px;
                width: 30px;
                background: blue;
                position: absolute;
                top: 0;left: 100%;
                transform: translate(-150%,-80%);
                clip-path: polygon(54% 0, 0 100%, 100% 100%);
                background: $grisNivel5;
            }
            &.visible{display: block;}
        }
        .item{
            display: block; text-align: center;
            margin:2px 0;
            position: relative;
            font-size: 13px;
            padding: 15px 0;
            &:hover{ background: $blanco;color:$naranjaNivel2; }
        }
        .btn{display: block;width: 100%!important;border-radius: 0;margin:0!important;padding: 10px 0!important; font-weight: bold;
            &.btnBlack{background: $naranjaNivel2;border-radius: 0  0  15px 15px;} }
    }
    .separadorRects{
        height: 20px;
        margin: 15px 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        div{background: $blanco; margin: 0 5px;}
        .rect1{ width: 10px; height: 6px; border-radius: 2px;}
        .rect2{ width: 30px; height: 6px; border-radius: 2px;}
        .rect3{ width: 60px; height: 6px; border-radius: 2px;}
    }
}
.content2{
    height: 100vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    
    .title{
        font-size: 40px;
        line-height: 45px;
        font-family: 'Poppins', sans-serif;
        font-weight: bold;
        &.black{color:$grisNivel5;}
        &.orange{color:$naranjaNivel2;}
        transition: all .5s;
         @media screen and (max-width: 1200px){
            font-size: 30px;
            line-height: 35px;
        }
    }
    .separadorRects{
        height: 20px;
        margin: 15px 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        div{background: black; margin: 0 5px;}
        .rect1{ width: 10px; height: 6px; border-radius: 2px;}
        .rect2{ width: 30px; height: 6px; border-radius: 2px;}
        .rect3{ width: 60px; height: 6px; border-radius: 2px;}
    }
    .texto{
        color: $grisNivel5;
        font-size: 16px;
        div{
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
        }
        transition: all .5s;
    }
    .divButton{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        div{
            width: 30%;
            background: $naranjaNivel2;
            color:$blanco;
            border-radius: 30px;
            padding: 10px;
            text-align: center;
            margin: 10px 0;
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            transition: all .5s;
            cursor: pointer;
        }
    }
    @media screen and (max-width: 1200px){
        .texto{font-size: 12px;}
        .divButton{font-size: 13px;div{padding:5px 7px;}}
    }
    @media screen and (max-width: 800px){
        .divButton{div{width: 45%;}}
    }
    @media screen and (max-width: 490px){
        height:fit-content!important;
        align-items: flex-start;
        .title{
            text-align: center;
            font-size: 25px!important;
            line-height: 30px!important;
        }
        .separadorRects{justify-content: center;}
        .texto{text-align: center!important; text-align: justify;margin: auto;}
        .divButton{div{width: 100%;}}
    }
    @media screen and (max-width: 450px){
        .title{font-size: 18px!important; }
        .texto{font-size: 10px!important; }
    }
}
.content3{
    position: relative;
    height: 100vh;
    @media screen and (max-width: 900px){
        .row{ padding: 0!important; }
        .cardItem{
            height: 16vw!important;
            width: 16vw!important;
            min-width: 112px;
            min-height: 112px;
            margin: 10px 5px!important;
        }
        .abs{top:80%!important; }
        .cardsTools2{ flex-wrap: wrap; }
    }
    @media screen and (max-width: 790px){
        &.content3Top1{ height: auto; }
        &.content3Top2{ height: auto; }
        .leftCircle{top: 110%!important;}
    }
    @media screen and (max-width: 790px){
        .leftCircle{
            left: 105%!important;
        }
    }
    @media screen and (max-width: 500px){
        &.content3Top2{
            height: fit-content;
        }
        .cardsTools2{
            height: fit-content!important;
        }
        .title{
            font-size: 25px!important;
            line-height: 30px!important;
        }
    }
    .leftCircle{
        position: absolute;
        top: 94%;
        left: 100%;
        transform: translate(-50%,-50%);
        width: 30%;
        z-index: 1;
    }
    .titleBlock{
        margin-top: 20px; &.mg{margin-top: 30px;}
    }
    .title{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 40px;
        line-height: 45px;
        font-family: 'Poppins', sans-serif;
        font-weight: bold;
        &.black{color:$grisNivel5;}
        &.orange{color:$naranjaNivel2;}
        transition: all .5s;
         @media screen and (max-width: 1200px){
            font-size: 30px;
            line-height: 35px;
        }
        @media screen and (max-width: 450px){
            font-size: 18px!important;
            line-height: 18px;
        }
    }
    .separadorRects{
        height: 20px;
        margin: 10px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        div{background: black; margin: 0 5px;}
        .rect1{ width: 10px; height: 6px; border-radius: 2px;}
        .rect2{ width: 30px; height: 6px; border-radius: 2px;}
        .rect3{ width: 60px; height: 6px; border-radius: 2px;}
    }
    .texto{
        color: $grisNivel5;
        font-size: 16px;
        text-align: center;
        div{font-family: 'Poppins', sans-serif;font-weight: 500;}
        z-index: 3;
        &.abs{transform: translateY(50%);}
        transition: all .5s;
         @media screen and (max-width: 1200px){
            font-size: 14px;
        }
        @media screen and (max-width: 530px){
            font-size: 12px;
            &.abs{transform: none;}
        }
        @media screen and (max-width: 330px){ font-size: 10px; }
    }
     .button{
        margin: 20px 0;
        display: flex;
        justify-content: center;
        transition: all .5s;
        div{
            background-color: $grisNivel5;
            color:$blanco;
            font-weight: bold;
            padding: 10px 25px;
            font-family: 'Poppins', sans-serif;
            border-radius: 10px;
            letter-spacing: 2px;
            cursor: pointer;
            transition: all .5s;
            text-align: center;
            &:hover{ background-color: #293540CC; }
        }
        @media screen and (max-width: 1200px){
            div{font-size: 13px;padding: 5px 7px;}
        }
        @media screen and (max-width: 1200px){
            div{padding:5px 7px;}
        }
        @media screen and (max-width: 800px){
            div{width: 45%;}
        }
        @media screen and (max-width: 490px){
            div{width: 100%;}
        }
    }
    .cardsTools{
        transition: all .5s;
        .row{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            padding: 10px;
        }
        .cardItem{
            //$var:14vw;
            $var: unquote("min(14vw,26vh)");
            overflow: hidden;
            background: $blanco;
            //height: 14vw;
            //width: 14vw;

            height: $var;
            width: $var;
            padding:5px;
            border-radius: 5px;
            transition: all .5s;
            margin: 0 10px;
            div{
                width: 100%;
                height: 60%;
                background-color: $blanco;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                transition: all .5s;
                .interno{
                    height: 100%;
                    width: 100%;
                    position: absolute;
                    background-image:url(https://bubook.nibirusystem.com/img/elementos/Recurso94x2x.png);
                    background-position: 50%;
                    background-size: 40%;
                    background-repeat: no-repeat;
                    opacity: .3;
                }
                img{height: 40%;opacity: 1;}
            }
            span{
                transition: all .5s;
                text-align:center;
                display: block;
                font-weight: bold;
                color: $grisNivel5;
                height: 40%;
                overflow: hidden;
                line-height: 15px;
                font-size: 14px;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                font-family: 'Poppins', sans-serif;font-weight: 500;
            }
        }
        transition: all .5s;
         @media screen and (max-width: 1200px){
            .cardItem{ span{font-size: 12px;}}
        }
        @media screen and (max-width: 760px){
            .cardItem{ span{font-size: 10px;}}
        }
    }
    .cardsTools2{
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-top: 20px;
        .cardItem2{
            width:17vw;
            min-width: 220px;
            background:$blanco;
            border-radius: 25px;
            transition: all .5s;
            margin: 10px auto;
            .aRatio{
                overflow: hidden;
                width: 16vw;
                min-width: 218px;
                min-height: 230px;
                margin: auto;
                img{ width: 90%; height: 100%; display: block; margin: auto;border-radius: 25px;padding: 10px 0;}
            }
            div{
                text-align: center;
                width: 100%;
            }
            span{
                color: $grisNivel5;
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 17px;
                line-height: 20px;
                padding: 5px;
                text-align: center;
                height: 70px;
                margin: 0 auto;
                display: table-cell;
                vertical-align: middle;
                .registerIcon{
                    display: inline-block;
                    transform: translateY(-25%)!important;
                }
            }
            .cicles{
                transition: all .5s;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 50px;
                div{
                    transition: all .5s;
                    height: 8px;
                    width: 8px;
                    border-radius: 100%;
                    background: #D3D5D8;
                    margin: 0 10px;
                }
            }
        }
        //@media screen and (max-width: 1200px){ .cardItem2{border-radius: 20px!important;span{font-size: 12px;}}}
        //@media screen and (max-width: 800px){ .cardItem2{border-radius: 15px!important; div{img{padding: 5px 0;}} span{font-size: 10px;line-height: 12px; height: 25px;}}}
        /*@media screen and (max-width: 500px){
            .cardItem2{
                border-radius: 5px!important;
                div{ img{ padding:0; } }
                span{font-size: 8px;line-height: 10px; height: 30px;}
                .cicles{ justify-content:space-around; height: 30px; div{ margin:0px; } }
            }
        }*/
    }
}
.content4{
    height: 100vh;
    background-image: url(https://bubook.nibirusystem.com/img/elementos/Recurso542.png);
    background-size:100% 90%;
    background-position:100%;
    background-repeat: no-repeat;
    position: relative;
    .container{height: 100%;}
    .content{
        position: relative;top: 20%;
    }
    .titleBlock{
        margin-top: 20px;
        width:100%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: column;
        .titleBlockhijo{
            width: 50%;
        }
    }
    .contImgs{
        width: 50%;
        position: relative;
        left: 50%;
        display: flex;
        justify-content: center;
        padding: 20px;
        margin-top: 10px;
        img{
            margin: 0 20px;
            width: 35%;
            min-width: 100px;
            cursor: pointer;
        }
        @media screen and (max-width: 1200px){
            margin-top: 0;
        }
    }
    .title{
        color:$blanco;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 45px;
        font-size: 50px;
        font-family: 'Poppins',sans-serif;
        font-weight: bold;
        &.black{color:$grisNivel5;font-size: 35px;}
        transition: all .5s;
        @media screen and (max-width: 1200px){
            font-size: 30px;
            line-height: 25px;
            &.black{color:$grisNivel5;font-size: 25px;}
        }
        @media screen and (max-width: 550px){
            font-size: 25px;
            line-height: 30px;
            &.black{color:$grisNivel5;font-size: 20px;}
        }
        @media screen and (max-width: 450px){
            //font-size: 18px!important;
            //&.black{font-size: 18px!important;}
        }
    }

    @media screen and (max-width: 860px){
        .container{width: 100%;}
        //.titleBlock{margin-left: 20px; width:70%;}
    }

    @media screen and (max-width: 500px){
        background-size:auto 90%;
        height: 80vh;
        .titleBlock{margin-left: 20px; width:100%;}
    }

    @media screen and (max-width: 400px){
        .title{
            font-size: 25px;
            line-height: 30px;
            &.black{color:$grisNivel5;font-size:25px;}
        }
        .content{height: 100%;top: 0!important;}
        .titleBlock{position:relative;top: 20%!important;}
        .contImgs{
            position: absolute;
            width: 100%;
            top: 100%;left: 50%;
            transform: translate(-50%,-180%);
            img{width: 50%!important;}
        }
    }
}
.content5{
    height: 100vh;
    .titleBlock{margin-top: 20px; &.mg{margin-top: 30px;}}
    .title{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 40px;
        line-height: 45px;
        font-family: 'Poppins', sans-serif;
        font-weight: bold;
        &.black{color:$grisNivel5;}
        &.orange{color:$naranjaNivel2;}
         @media screen and (max-width: 1200px){
            font-size: 30px;
            line-height: 35px;
        }
        @media screen and (max-width: 550px){
            font-size: 20px;
            line-height: 25px;
        }
        @media screen and (max-width: 330px){
            font-size: 18px;
            line-height: 20px;
        }
    }
    .separadorRects{
        height: 20px;
        margin: 10px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        div{background: black; margin: 0 5px;}
        .rect1{ width: 10px; height: 6px; border-radius: 2px;}
        .rect2{ width: 30px; height: 6px; border-radius: 2px;}
        .rect3{ width: 60px; height: 6px; border-radius: 2px;}
    }
    .texto{
        color: $grisNivel5;
        font-size: 16px;
        text-align: center;
        div{
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
        }
         @media screen and (max-width: 1200px){
            font-size: 14px;
        }
        @media screen and (max-width: 530px){
            font-size: 12px;
        }
    }
    .button{
        margin: 20px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        div{
            background-color: $grisNivel5;
            color:$blanco;
            font-weight: bold;
            padding: 10px 25px;
            font-family: 'Poppins', sans-serif;
            border-radius: 10px;
            letter-spacing: 2px;
            cursor: pointer;
            text-align: center;
            transition: all .5s;
            &:hover{ background-color: #293540CC; }
        }
         @media screen and (max-width: 1200px){
            div{font-size: 13px;padding: 5px 7px;}
        }
        @media screen and (max-width: 1200px){
            div{padding:5px 7px;}
        }
        @media screen and (max-width: 800px){
            div{width: 45%;}
        }
        @media screen and (max-width: 490px){
            div{width: 100%;}
        }
    }
    .contPlanes{
        display: flex;
        justify-content: center;
        align-items: baseline;
        padding: 10px;
        padding-top: 30px;
        transition: all .5s;
        .plan{
            transition: all .5s;
            background: $blanco;
            margin: 0 20px;
            position: relative;
            border-radius: 15px;
            box-shadow: 0px 0px 10px 1px rgba(0,0,0,.3);
            @media screen and (max-width: 550px){
                margin: 0 10px;
            }
        }
        .Precio{
            display: flex;
            justify-content: center;
            align-items: flex-end;
            margin-top: 30px;
            padding: 20px 25px;
            span{
                font-size: 30px;
                color:$naranjaNivel2;
                font-family: 'Poppins', sans-serif;
                font-weight: bold;
            }
            label{
                font-size: 50px;
                line-height: 55px;
                color: $grisNivel5;
                font-family: 'Poppins', sans-serif;
                font-weight: bold;
            }
            h4{
                display: block;
                text-align: center;
                font-family: 'Poppins', sans-serif;
                span{font-size: 20px; display: block;color: $grisNivel5;line-height: 20px;}
                label{display: block;color:$grisNivel5;font-size: 15px;line-height: 15px;font-weight: normal;}
            }
            @media screen and (max-width: 1200px){
                span{font-size: 16px;}
                label{
                    font-size: 30px;line-height: 32px;
                }
                h4{
                    span{font-size: 14px;line-height: 14px;}
                    label{font-size: 12px;line-height: 12px;}
                }
            }
            @media screen and (max-width: 550px){
                padding: 10px 15px;
                span{font-size: 16px;}
                label{
                    font-size: 28px;line-height: 30px;
                }
                h4{
                    span{font-size: 14px;line-height: 14px;}
                    label{font-size: 12px;line-height: 12px;}
                }
            }
        }
        .nota{
            background-color: $naranjaNivel2;
            text-align:center;
            font-weight: bold;
            font-family: 'Poppins', sans-serif;
            color:$blanco;
            border-radius: 0 0 15px 15px;
            padding: 5px;
            div{
                &:nth-child(1){font-size: 20px;line-height: 20px;}
                &:nth-child(2){font-size: 25px;line-height: 30px;}
            }
            @media screen and (max-width: 550px){
                padding: 2px;
                div{
                    &:nth-child(1){font-size: 15px;line-height: 15px;}
                    &:nth-child(2){font-size: 20px;line-height: 25px;}
                }
            }
        }
        .etiqueta{
            transition: all .5s;
            position: absolute;
            top:10%;left: -10px;
            width: 100%;
            color: $blanco;
             @media screen and (max-width: 550px){
                top:5%;
            }
            .triangulo{
                background: red;
                width: 10px; height: 10px;
                clip-path: polygon(100% 0, 0 0, 100% 100%);
                background-color:#904B0D;
            }
            .trapecio{
                background: red;
                width: 70%; height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                clip-path: polygon(0 0, 90% 0, 100% 100%, 0% 100%);
                border-radius: 5px 5px 5px 0;
                background-color:$naranjaNivel2;
                font-family: 'Poppins', sans-serif;
                font-weight:300;
            }
        }
    }
    @media screen and (max-width: 500px){
        height:auto;
        margin-bottom: 40px;
        .content{width: 100%!important;}
    }
}
.footer{
    background-color:#293540;
    height: 45vh;
    .texto{
        color: $blanco;;
        font-size: 16px;
        height: 100%;
         div{
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            span{font-family: inherit;font-size: 0.9pc;}
        }
    }
    .content{
        height: 30vh;
        padding: 40px 5px;
        justify-content: space-around;
        display: flex;
        .logo{
            img{
                height: 130px;
                //background: $naranjaNivel2;
                border-radius: 10px;
                padding: 10px 5px;
            }
        }
        .list{
            color:$blanco;
            width: 25%;
        }
        .title{
            color:$naranjaNivel2;
            font-family: 'Montserrat', sans-serif;
            font-weight: bold;
            font-size: 17px;
        }
        .item{
            font-family: 'Montserrat', sans-serif;
            color:$blanco;
            font-weight: medium;
            font-size: 15px;
            &.crs{cursor:pointer;}
            a{
                font-family: 'Montserrat', sans-serif;
                color:$blanco;
                font-weight: medium;
                font-size: 15px;
                display: inline;
                &:hover{color:$naranjaNivel2;}
            }
            &:hover{color:$naranjaNivel2;}

            &.rs{
                img{
                    height: 20px;
                }
            }
        }
        .itemImg{
            img{
                width: 40%;
                cursor: pointer;
                margin:2% 0;
            }
        }
    }
    .container{ }
    .footer2{
        height: 100%;
        border-top: 1px solid $blanco;
        height: 15vh;
        display: flex;
        align-items:center;
        .texto{font-size: 13px; span{font-size: 12px;} .registerIcon{ display: inline-block;} }
    }
    .semiblock{font-family: 'Poppins-SemiBold';font-size: 13px;font-weight: bold;}
    @media screen and (max-width: 1200px){
        .content{
            .logo{img{height: 80px;}}
            .title{font-size: 14px;}
            .item{font-size: 12px; a{font-size: 12px;} }
            .itemImg{img{width: 100px;}}
        }
        .texto{div{font-size: 13px;}}
    }

    @media screen and (max-width: 700px){
        .container{width:100%;}
        .texto{div{text-align: center;}}
    }
    @media screen and (max-width: 500px){
        .content{.logo{display: none;}.item{font-size: 10px;}}
        .container{width:100%;}
        .texto{div{text-align: center;}}
        .texto{div{font-size: 11px;span{font-family: inherit;font-size: 10px;}}}
    }
    @media screen and (max-width: 400px){
        .content{height: 30vh;}
        .footer2{height: 10vh;}
        .item{font-size: 10px!important; a{font-size: 10px!important;} }
        .semiblock{ display:block; }
    }
}

.hvNa{
    &:hover{color: $naranjaNivel2;}
}
