@import './../color.scss';

#logincvino{
    animation-name: cvino;
    animation-duration: .5s;
}

.nofondo{ background-image: none; background-color: white; }
.noborde{border: none!important;}

.divCounter{
  border: 1px solid red;
  position: absolute;
  display: none;
  .cont-avc{
      width: 33.33%;
      display: flex;
      justify-content: center;
      align-items: center;
      .avc{
          width: 35px;
          height: 35px;
          background-color: white;
          border: 3px solid $colorGrisMedio;
          border-radius: 100%;
          margin: 0px 10%;
          position: relative;
          &::after{
              content: '';
              display:block;
              height: 1px;
              width: 350%;
              position: absolute;
              top: 50%; left: 100%;
              transform: translate(0%,-50%);
              background-color: $colorGrisMedio;
          }
          &:last-child::after{
              display:none;
          }
          &.avcfill{
              border-color: $naranjaStart;
              background-color: $naranjaStart;
              &.filled::after{
                  background-color: $naranjaStart;
              }
          }
      }
  }
}

.checkboxBubook{
    height: 16px;
    width: 16px;
    position: relative;
    margin: 0 10px;
    &:checked::after{
        content: '';
        position: absolute;
        top: 0; left: 0;
        width: 14px;
        height: 14px;
        background-image:url(https://bubook.nibirusystem.com/img/iconos/Palomita.png);
        background-size: 90%;
        background-color: white;
        background-position: center;
        background-repeat: no-repeat;
        display: block;
        z-index: 2;
    }
    &:disabled{
        cursor:not-allowed;
    }
}

.radioBubook{
    height: 16px;
    width: 16px;
    position: relative;
    &::after{
        content: '';
        position: absolute;
        top: 0;left: 0;
        height: 100%;
        width: 100%;
        display: block;
        border-radius: 100%;
        border: 2px solid $naranjaEnd;
        background-color: transparent;
    }
    &::before{
        content: '';
        position: absolute;
        top: 50%;left: 50%;
        height: 8px;
        width: 8px;
        border-radius: 100%;
        background-color: $naranjaEnd;
        transform: translate(-50%,-50%);
        display: none;
    }
    &:checked::before{
        display: block;
    }
}

.MyDate{
    width: 100%;
    border: none;
    outline: none;
    .ant-calendar-picker{
        border: none;
        outline: none;
        box-shadow: none;
        border-bottom: 1px solid $colorGrisFuerte;
        border-radius: 0;
    }
    input{
        border: none;
        outline: none;
        border-bottom: 1px solid $colorGrisFuerte;
        font-size: 15px;
        border-radius: 0;
    }
}

.ant-time-picker.myTimer{
    border: none;
    outline: none;
    display: block;
    width: 100%;
    input{
      border: none;
      outline: none;
      border-bottom: 1px solid $colorGrisFuerte;
      font-size: 15px;
      border-radius: 0;
      display: block;
    }    
}

.group-tags{
  border-bottom: 1px solid $grisNivel5;
  flex-wrap: wrap;
  .mytag{
    display: flex;
    align-items: center;
    background-color: $naranjaStart;
    color: white;
    font-size: 13px;
    border-radius: 4px;
    padding: 2px 5px;
    margin: 2px;
    span{
      cursor: pointer;
      font-size: 15px;
      margin-left: 2px;
    }
  }
}

.inputBaseTree-container{
    padding-right: 5px;
    flex-grow: 1;
    .inputBaseTree{
        flex-grow: 1;
        border-radius: 0;
        .ant-select-selection.ant-select-selection--single{ border-radius: 0!important; }
        
        //&:first-child{ .ant-select-selection.ant-select-selection--single{ border-radius: 15px 0 0 15px!important; } }
        
        &:nth-child(3){ margin: 0 1px; }
        
        &:last-child{ .ant-select-selection.ant-select-selection--single{ border-radius: 0 15px 15px 0!important; } }
    }
}

ul.ant-select-tree-child-tree.ant-select-tree-child-tree-open{
    span.ant-select-tree-switcher.ant-select-tree-switcher-noop{
        width: 24px!important;
    }
}

span.ant-select-tree-switcher.ant-select-tree-switcher-noop{
    width: 5px!important;
}

.cilindir{
    background-color: $grisNivel1;
    border-radius: 15px 0 0 15px;
    margin-top: 0px;
    display: inline-block;
    height: 31px;
    padding-left: 7px;
    padding-right: 0px;
    padding-top: 7px;
    svg{height: 17px; width: 17px;}
}

.holibolillos{
    width: 150px!important;
}


@keyframes cfue{
    to{transform: translate(-250%,-50%);}
}
@keyframes cvino{
    from{transform: translate(150%,-50%);}
    to{transform: translate(-50%,-50%);}
}