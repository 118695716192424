@import './../../../color.scss';

.panelAux{z-index: 2000;}

.presentacionStripe{
    *{transition: all .5s;}
    .falseIcon{
        color:$grisNivel5;
        font-size: 35px;
        line-height: 40px;
        font-family: "Poppins-Bold";
        margin: 5px auto;
    }
    .titleTarifa{
        font-size: 17px;
        font-family: "Poppins-Medium";
        background: $grisNivel1;
        padding: 5px;
        border-radius: 5px 5px 0 0;
    }
    .contPros{
        border: 1px solid $grisNivel1;
        border-top: 1px solid #000;
        margin-top: -4px;
        border-radius:0 0 5px 5px;
        padding: 5px;
        padding-left: 20px;
        .comision{
            font-family: "Poppins-Regular";
            letter-spacing: 1px;
            font-size: 20px;
            color:#22B573;
            margin-bottom: 10px;
        }
    }
    .item{
        img{height: 20px;margin-right: 15px;}
        span{
            font-family: "Poppins-Medium";
            color:$grisNivel5;            
        }
    }
    .nta{
        font-family: "Poppins-Regular";
        text-align: justify;
        color:$grisNivel4;
        font-size: 13px;
    }
}

.emdedStripeForm{
    height: 700px;
    embed{
        width: 100%;
        height: 700px;
    }
}

.MyOps-info{
    width: 80%;
    min-width: 400px;
    max-width: 900px;
    margin: 10px auto 60px auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.MyOps-button{
    height: 45px;
    width: 200px;
    border-radius: 5px;
    border: 1px solid $colorGrisMuyBajo;
    box-shadow: 0px 0px 10px 0px $colorGrisMuyBajo;
    border-radius: 5px;
    transition: all .5s;
    background-image: linear-gradient(to right, $naranjaStart , $naranjaEnd );
    color: white;
    font-size: 15px;
    font-weight: bold;
    &:hover{ box-shadow: 0px 0px 5px 3px transparentize($colorGrisBajo,0.5) }
    &:disabled{
        cursor:not-allowed;
        transform: scale(.95);
    }
}

.miconfigModal{

    &.miconfigModalCitas{
        &.miconfigModalCitasMg{
            .ant-modal-content{margin-top: 30px;}
            .inputs-groupSwitch{
                margin: 15px 0;
                padding:0 5px;
                .timerhijos{
                    margin-top: 10px;
                    .title{
                        display: flex;
                        justify-content: space-between;
                        margin: 5px 0;
                    }
                    .spanAdd{
                        text-decoration: underline;
                        cursor: pointer;
                        color: $naranjaEnd;
                        font-weight: bold;
                    }
                    .itemHorarioHijo{
                        display: inline-flex;
                        box-shadow: 0px 0px 10px 0px $colorGrisMuyBajo;
                        border-radius: 5px;
                        margin: 3px;
                        transition: all .5s;
                        padding: 3px;
                        justify-content: space-around;
                        align-items: center;
                        width: 200px;
                        &:hover{ box-shadow: 0px 0px 5px 3px $colorGrisMuyBajo;}
                        span{ cursor: pointer; font-size:14px; }
                        img{height: 25px; cursor: pointer;}
                    }
                }
            }
            .mytimers{
                margin: 0;
                width: 100%;
                display: flex;
                justify-content: space-between;
                .myTimer{width: 45%; }
            }
            .myCheckProd{
                //.radioBubook{height: 20px;width: 20px; margin-right: 10px; }
                .radioBubook{ margin: 0 5px 0 -2px; }
            }
        }
        .ant-modal-header{
            padding: 10px;
            .ant-modal-title{color:$naranjaStart;text-align: center;}
        }
        .ant-modal-body{
            padding: 10px 15px;
        }
        .ant-modal-footer{padding: 10px 15px;}
        
        .tititle,.divradios2{font-size: 12px;font-weight: bold;color: #666666;margin-top: 20px;display: block;margin-left: 6px;}

        .divradios{
            margin-bottom: 10px;
            padding-left: 10px;
            max-height: 70px;
            overflow-y:auto;
            overflow-x: hidden;
            &::-webkit-scrollbar { width: 8px;}
            &::-webkit-scrollbar-button{ background:white; }
            &::-webkit-scrollbar-track {background:white;} 
            &::-webkit-scrollbar-thumb { background-color:$naranjaEnd; border-radius: 5px; }
        }
        .nameEditando{
            font-size: 15px;
            color: $naranjaStart;
            font-weight: bold;
        }

    }
    &.newVar{
        .ant-modal-header{
            padding: 10px;
            margin-bottom: 15px;
            .ant-modal-title{color:$naranjaStart;text-align: center;}
        }
    }

    &.nrd{
        .ant-modal-content{border-radius: 5px!important;padding: 0px;}
        .ant-modal-title{color:$naranjaNivel2;}
        //.ant-modal-header{padding-bottom: 0px;}
        //.ant-modal-footer{padding-top: 0px;}
    }

    &.alined{
        .ant-modal-footer{
            display: block;
            padding: 10px;
            div{
                display:flex;
                justify-content:flex-end;
                align-items:center;
                button{
                    display:flex;
                    justify-content:center;
                    align-items:center;
                }
            }
        }
        .inputs-input::placeholder{
            color:$grisNivel2;
        }
    }

    .iconSee{
        position: absolute;
        top: 55%;
        left: 92%;
        cursor: pointer;
        z-index: 2;
        transform:rotateY(180deg);
    }
    .inputs-group{
        display: block;
        position: relative;
        transition: all .3s;
        margin-top: -10px;
        padding: 10px;
        .inputs-label{
            font-size: 12px;
            font-weight: bold;
            color: $colorGrisMedio;
            span{
                font-weight: normal;
            }
        }
        .inputs-input{
            display: block;
            font-size: 14px;
            height: 30px;
            width: 100%;
            border:none;
            outline: none;
            position: relative;
            padding-left: 10px;
            border-bottom: 1px solid $colorGrisMedio;
            transition: all .3s;
            color: $colorGrisMedio;
            &:focus{ border-bottom-color: $naranjaEnd; border-bottom-width: 2px; }
        }
        textarea.inputs-input{
            box-sizing: border-box;
            border-radius: 3px;
            resize: none;
            font-size: 14px;
            line-height: 18px;
            overflow: auto;
            height: auto;
            border-radius: 0;
        }
    }
    .nota{
        color:$naranjaEnd;
        display: block;
        margin:10px auto;
        text-align: center;
        font-size: 12px;
        font-weight: bold;
        cursor: pointer;
        &.error{
            cursor:initial;
            display: none;
            color:$rojoNibiru;
            margin-top: 20px;
            margin-bottom: -30px;
        }
    }
}

.newItem{
    font-size: 15px;
    font-weight: bold;
    color: $naranjaStart;
    text-decoration: underline;
    text-decoration-color:$naranjaEnd;
    cursor: pointer;
}

.listaConfiguraciones{
    height: 400px;
    overflow-y: auto;
    padding: 5px;
    &::-webkit-scrollbar{ width:  5px; }
    &::-webkit-scrollbar-button{ background:white; }
    &::-webkit-scrollbar-track{ background:white; width: 10px;} 
    &::-webkit-scrollbar-thumb{ background-color:$naranjaEnd; border-radius: 3px;width: 8px; }
    &.columnxd{flex-direction: column;}
    .itemSucursal{
        cursor: pointer;
        transition: all .5s;
        border-radius: 5px;
        font-size: 15px;
        padding:5px 10px;
        box-shadow: 0px 0px 10px 0px $colorGrisMuyBajo;
        margin-bottom: 5px;
        &:hover{ box-shadow: 0px 0px 5px 3px $colorGrisMuyBajo;}
        .title{
            font-weight: bold;
            cursor: inherit;
            display: flex;
            justify-content: space-between;
            img{height: 25px;}
            margin-bottom: 5px;
        }
        div{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
    .nta{
        font-size: 14px;
        font-family: "Poppins-Medium";
        text-align:justify;
        margin-bottom: 10px;
    }
    .contItems{
        border-radius: 5px;
        border: 1px solid $grisNivel2;
    }
    .itemTipo{
        font-size: 16px;
        font-family: "Poppins-Medium";
        background: transparentize($grisNivel1, .5); 
        //height: 40px;
        border-bottom: 1px solid $grisNivel2;
        padding: 0 10px;
        cursor: pointer;
        height: 50px;
        &:hover{ background: $grisNivel1; }
        .icon{
            margin-right: 10px;
            text-align: center;
            width:100px;
            img{height: 40px;}
        }
        .noticon{flex-grow: 1;}
    }
}
.miconfigModalMetodoPago{
  margin-top: -15px;
  overflow: hidden;
  margin-left: -5px;
  margin-right: -5px;
  min-height: 400px;
  max-height: 400px;
  *{transition: all .5s;}
  &.miconfigModalMetodoPago2{
    overflow: auto;
    max-height: 600px;
    flex-flow: column;
  }
  
  .listItemTransfer{
    width: 95%;
    margin: 0 auto;
    margin-top: 10px;
    box-shadow: 0px 0px 3px $colorGrisBajo;
    border-radius: 5px;
    cursor: pointer;
    table{width: 100%;}
    .meta{
      background: $grisNivel1;
      font-family: "Poppins-SemiBold";
      width: 30%;
      padding: 2px 5px;
    }
    .data{
      padding: 2px 5px;
      font-family: "Poppins-Regular";
    }
    &:hover{
      box-shadow: 0px 0px 5px 1px $colorGrisBajo;
    }
    .dataImg{
        width: 80px;
        text-align: center;
        img{ width: 90%; }
    }
  }

  .contItems2{
    width: 100%;
    max-height: 550px;
    padding: 5px;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar{ width:  5px; }
    &::-webkit-scrollbar-button{ background:white; }
    &::-webkit-scrollbar-track{ background:white; width: 10px;} 
    &::-webkit-scrollbar-thumb{ background-color:$naranjaEnd; border-radius: 3px;width: 8px; }

    .nonResults{
        width: 100%;
        height: 400px;
        position: relative;
        img{ height: 300px; }
        &::after{
            content: '¡No se encontraron resultados!';
            position: absolute;
            top: 350px;
            left: 0%;
            text-align: center;
            display: block;
            width: 100%;
            font-size: 17px;
            font-family: "Poppins-Medium";
        }
    }

  }

  .btnAdd{
    margin-top: 10px;
    width: 100%;
    padding: 8px 0;
    background-image:linear-gradient(to right,$naranjaStart 50%,$naranjaNivel2);
    font-family: "Poppins-Medium";
    color: $blanco;
    border-radius: 5px;
    &.btn2{
        background-image: none;
        background-color: #FFFFFF;
        color: #848484;
        font-weight: bolder;
        opacity: .7;
        border: 1px solid #d5d5d5;
        box-shadow: 0px 0px 5px 0px #d5d5d5;
        &:hover{
            color: #F77400;
        }
    }
    &:hover{
        box-shadow: 0px 0px 5px 0px #d5d5d5;
    }
  }
}

.miconfigDiv{

    .selectorMinutos{
        display: flex;
        padding: 0 10px;
        justify-content: space-between;
        .selector{
            label{font-size: 12px;font-weight: bold;color: #666666;}
            .inputs-input{
                @include clearBorder;
                border-radius: 0;
                width: 90%;
                border-bottom: 1px solid $grisNivel4!important;
                &:focus{ border-bottom: 2px solid $naranjaEnd!important; }
            }
        }
    }


    &.nomgtp{margin-top: -35px;margin-bottom: -20px;}
    .ant-input.inputs-input{
        @include clearBorder;
        border-radius: 0;
        border-bottom: 1px solid $grisNivel4!important;
        &:focus{ border-bottom: 2px solid $naranjaEnd!important; }
    }
    
    .ant-select.ant-select-disabled .ant-select-selection.ant-select-selection--single{
        @include clearBorder;
        background: white;
        color:$grisNivel4;
        cursor: initial;
        border-radius: 0;
        border-bottom: 1px solid $grisNivel4!important;
        &:focus{ border-bottom: 2px solid $naranjaEnd!important; }
    }

    .ant-select.ant-select-enabled,
    .ant-select.ant-select-open.ant-select-focused.ant-select-enabled,
    .ant-select.ant-select-focused.ant-select-enabled{
        .ant-select-selection.ant-select-selection--single,
        .ant-select-selection--multiple{
            box-shadow: none;
            border: none;
            outline: none;
            border-bottom: 1px solid $colorGrisMedio;
            border-radius: 0;
            &:focus{
                border-bottom: 2px solid $naranjaEnd;
            }
        }
        svg{
            fill: $naranjaEnd;
            height: 20px;
            width: 15px;
        }
    }
    .cont-tags{
        height: auto;
        .group-tags{
            border: none;
            display: flex;
            flex-wrap: wrap;
            .mytag{
                display: flex;
                align-items: center;
                background-color: $naranjaStart;
                color: white;
                font-size: 13px;
                border-radius: 4px;
                padding: 2px 5px;
                margin: 2px;
                span{
                    cursor: pointer;
                    font-size: 15px;
                    margin-left: 2px;
                }
            }
        }
    }
    .inputIncrustado{
        display: block;
        font-size: 14px;
        height: 30px;
        width: 80%;
        border:none;
        outline: none;
        position: relative;
        border-bottom: 1px solid $colorGrisMedio;
        transition: all .3s;
        margin-bottom: 5px;
        text-align: center;
        &:focus{
            border-bottom-color: $naranjaEnd;
            border-bottom-width: 2px;
        }
    }
}
.checkboxBubook{
    height: 16px;
    width: 16px;
    position: relative;
    margin: 0 10px;
    &:checked::after{
        content: '';
        position: absolute;
        top: 0; left: 0;
        width: 14px;
        height: 14px;
        background-image:url(https://bubook.nibirusystem.com/img/iconos/Palomita.png);
        background-size: 90%;
        background-color: white;
        background-position: center;
        background-repeat: no-repeat;
        display: block;
        z-index: 2;
    }
    &:disabled{
        cursor:not-allowed;
    }
}
.radioBubook{
    height: 16px;
    width: 16px;
    position: relative;
    &::after{
        content: '';
        position: absolute;
        top: 0;left: 0;
        height: 100%;
        width: 100%;
        display: block;
        border-radius: 100%;
        border: 2px solid $naranjaEnd;
        background-color: transparent;
    }
    &::before{
        content: '';
        position: absolute;
        top: 50%;left: 50%;
        height: 8px;
        width: 8px;
        border-radius: 100%;
        background-color: $naranjaEnd;
        transform: translate(-50%,-50%);
        display: none;
    }
    &:checked::before{
        display: block;
    }
}
.configHeadWrap{
    width: 100%;
    min-width: 400px;
    max-width: 1024px;

    height: 70vh;
    min-height: 225px;
    max-height: 379px;

    margin:auto;
}

.configFootWrap{
    width: 80%;
    min-width: 400px;
    max-width: 1024px;
    margin: auto;
    position: relative;
    margin-bottom: 55px;
}

.infoFootEmpresa{
    
    transform:translateY(50px);

     .nombreEmpresa{
        padding: 10px;
        padding-bottom: 0px;
        color: $naranjaEnd;
        font-weight: bold;
        transition: all 500ms;
        font-size: 23px;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin:10px auto;
        overflow: hidden;
    }

    .descripcionEmpresa{
        position: relative;
        display: block;
        
        text-align: justify;
        color: $grisNivel4;
        white-space: pre-wrap;

        font-size: 14px;
        line-height: 18px;
        max-height: 72px;

        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;

    }
}

.headPortada{
    position: relative;
    height: 100%;
    width: 100%;
    &::before{
        content: 'BuBook';
        display: block;
        position: absolute;
        top: 50%;left: 50%;
        transform: translate(-50%,-50%);
        font-size: 50px;
        color:$colorGrisMuyBajo;
        font-weight: bold;
    }

    .contPortada,.cont-Cropper{
        background: white;
        position: absolute;
        top: 50%;left: 50%;
        transform: translate(-50%,-50%);
        height: 100%;
        max-height: 379px;
        width: 100%;
        max-width: 1024px;
        border-radius:10px;
        .srcPortada{
            width: 100%;
            height: 100%;
            border-radius:10px;
        }
    }
    .cont-Cropper{
        z-index: 999;
        position: relative;
        .opsImg{
            position: absolute;
            right: 0; bottom: 0;
            display: flex;
            align-items: center;
            justify-content: space-around;
            cursor: pointer;
            img{
                height: 35px;
                width: 35px;
                margin: 5px;
                background: white;
                padding: 5px;
                transition: all .5s;
                &:hover{
                    transform: scale(1.1);
                }
            }
        }
    }
    .inputNewPortada{
        position: absolute;
        top: 88%;
        left: 94%;
        transform: translate(-50%,-50%);
        height: 70px;
        cursor: pointer;
        transition: all .3s;
        opacity: .8;
        &:hover{transform: translate(-50%,-50%) scale(1.05);opacity: 1;}
    }
    input[type='file']{display: none;}
}
.footPortada{
    position: relative;
    background-color: white;
    padding-top: 10px;
    .opsImg{
      position: absolute;
      top: 100%; left: 55%;
      transform: translate(0%,150%);
      z-index: 99999;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      cursor: pointer;
      img{
        height: 25px;
        width: 25px;
        background: white;
        padding: 5px;
        transition: all .5s;
        &:hover{ transform: scale(1.1); }
      }
    }

    .cont-Cropper{
        z-index: 999;
        position: relative;
    }
    .contPerfil,.cont-Cropper{
        background: white;
        border: 3px solid rgba(0,0,0,.05);
        position: absolute;
        top:50%;left: 50%;
        height: 130px;
        width:  130px;
        transform: translate(-50%,-50%);
        padding: 0;
        border-radius: 10px;
        .srcPerfil{
            border-radius: 10px;
            height: 100%;
        }
    }

    .cont-Cropper{
        height: 140px;
        width: auto;
        top:0%;left:50%;
        transform: translate(-50%,-50%);
        border-radius: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .nombreEmpresaCorreo{
        color: $naranjaEnd;
        transition: all 500ms;
        font-size: 17px;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
        overflow: hidden;
    }

    .inputNewPerfil{
        position: absolute;
        top:90%;left: 90%;
        transform: translate(-50%,-50%);
        height: 40px;
        width: 40px;
        z-index: 2;
        opacity: .9;
        cursor: pointer;
        transition: all .3s;
        &:hover{
            transform: translate(-50%,-50%) scale(1.05);
            opacity: 1;
        }
    }
    input[type='file']{display: none;}
}

.cont-optionCard{
    width: 80%;
    min-width: 400px;
    max-width: 1024px;
    margin:20px auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
}

.optionCard{
    padding: 10px;
    flex-grow: 1;
    padding: 5px;
    min-width: 300px;
    flex-wrap: wrap;
    .cardHead{
        background-color: transparentize($colorGrisMuyBajo,.5) ;
        height: 60px;
        display: flex;
        align-items: flex-end ;
        padding: 10px;
        font-size: 17px;
        color: $colorGrisBajo;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        border: 2px solid $colorGrisMuyBajo;
        border-bottom: none;
    }
    .cardBody{
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        //padding:0 10px 0 20px;
        background-color: white;
        border: 2px solid $colorGrisMuyBajo;
        height: auto;
        .cardBodyOption{
            padding:0 10px 0 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid $colorGrisMuyBajo;
            height: 40px;
            &:hover{
                background-color: rgba(0,0,0,.05);
            }
            label{
                color: $colorGrisBajo;
                font-size: 14px;
                font-weight: bolder;
                white-space: nowrap;
                cursor: pointer;
            }
            i{
                font-size: 17px;
                cursor: pointer;
            }
        }
    }
}
.myswitch{
    &.ant-switch-checked{
        background-color:#22b473;
    }
    &.ant-switch-disabled{
        cursor: not-allowed;
        opacity:.5;
        &::after{cursor: inherit;}
    }
}

.modalBaseStripe{}
.modalBaseStripeContent{
    .ant-modal-content,.ant-modal-body{
        padding: 0!important;
        width: 500px;
        border-radius: 15px;
    }
    .contStripeChild{
        background: white;
        width: 500px;
        height: 600px;
        flex-direction: column;
        border-radius: 15px;
        .phantom{visibility: hidden;opacity: 0;}
        h1,h3{text-align: center;}
        h1{
            font-family: "Poppins-SemiBold";
            font-size: 35px;
        }
        h3{
            font-family: "Poppins-Medium";
            font-size: 17px;
        }
        div{
            text-align: center;
            margin: 10px auto;
            img{ height: 50px; }
        }
        .fakebutton{
            margin-bottom: 10px;
            padding: 5px;
            background-image: linear-gradient(to right, #FF9700 50%, #F77400 );
            color:white;
            font-family: "Poppins-SemiBold";
            width: 90%;
            font-size: 16px;
            border-radius:5px;
            cursor: pointer;
        }
        &.little{
            h1{font-size: 25px;}
            h3{font-size: 14px; padding: 10px;}
        }
    }
}