@import './../../../color.scss';

.modalUnidad,.modalPermisos{
	.ant-modal-title{
		color: $naranjaEnd;
		font-size: 17px;
		text-align: center;
		padding: 10px;
		font-weight: bold;
	}
}

.modalUnidad{

	&.nopdd{
		.ant-modal-body{ padding-top: 5px; }
	}

	.tablainline{
    max-height: 400px;
    position:relative;
    overflow-y: auto;
    margin: 0;
    margin-bottom: -40px;
    &::-webkit-scrollbar { width: 8px; }
    &::-webkit-scrollbar-button{ background:white; }
    &::-webkit-scrollbar-track {background:white;} 
    &::-webkit-scrollbar-thumb { background-color:$colorGrisBajo; border-radius: 10px;}
	}


	.tablaHead{
    	background: $colorGrisMuyBajo;
    	padding: 5px;
    	text-align: center;
    	font-weight: bold;
    	font-size: 16px;
    	margin-top: -30px;
    }

    .datosPublico.empty{ margin: 0; }

    .tablaBody{
		padding: 5px;
    	text-align: center;
    	font-size: 16px;
        transition: all .5s;
        .nombre{
            text-align: left;
            padding-left: 20px;
        }
        &:nth-child(even){ background: rgba(0,0,0,.05); }
	}

	.titleShare{
		font-family: "Poppins-SemiBold";
		font-size: 16px;
		text-align: center;
		color: $grisNivel5;
	}

	.deescShare{
		text-align: justify;
		margin: 10px auto;
		font-family: "Poppins-Regular";
		color: $grisNivel5;
		a{color: $azulBuBook;cursor: pointer; display: inline;}
	}

	.listShare{ color: $grisNivel5; }

	.itemShare{
		span{color: $naranjaNivel2;}
		label{margin-left: 5px;}
	}

	.ant-modal-content{border-radius: 5px!important;}
	.ant-modal-header{
		border-radius: 5px!important;
		padding: 10px 20px;
		border: none;
	}

	.ant-modal-footer{ border: none; padding-bottom: 15px;}
	.miUniDiv{

		.ant-input.inputs-input,.ant-input{
			@include clearBorder();
			border-radius: 0px!important;
			border-bottom: 1px solid $grisNivel5!important;
			&:focus{
				border-bottom: 2px solid $naranjaNivel2!important;
			}
		}

		.ant-input-affix-wrapper{
      padding-left: 0!important;
      &:hover .ant-input{border-bottom-color: $naranjaEnd!important;}
      .ant-input-suffix{font-size: 18px;}
      .ant-input:hover{border-bottom-color: $naranjaEnd;}
    }

		.inputs-input.ant-select.ant-select-enabled,.ant-select-disabled{
			@include clearBorder();
			padding-left: 0!important;
			.ant-select-selection.ant-select-selection--single{
				@include clearBorder();
				border-radius: 0px!important;
				border-bottom: 1px solid $grisNivel5!important;
				&:focus{ border-bottom: 2px solid $naranjaNivel2!important; }
				
			}
		}
		.inputs-input.ant-select.ant-select-disabled .ant-select-selection.ant-select-selection--single{
			background: $blanco;span{display: none;}cursor: initial;
		}


		.contEstrellas.contEstrellasAdd{
			img{height: 70px;}
		}

		.nivelNom{
			display: flex;
			justify-content: space-between;
			.inputs-group{
				&.ja0{width: 20%;}
				&.ja1{width: 75%;}
			}
		}
		.inputs-group{
	        height: 35px;
	        position: relative;
	        &.mgtop{
	        	height: 60px;
	        	margin-top: 10px;
	        	.inputs-label{
	        		position: relative;
	        		top: initial;
	        		left: initial;
	        		transform: translate(0,0)!important;
	        	}
	        }
	        .inputs-label{
	            text-align: left;
	            font-size: 12px;
	            color: $colorGrisMedio;
	            font-weight: bold;
	            position: absolute;
	            top: 0px;
	            left: 0px;
	            transform: translate(0,-10px);
	        }
	        .inputs-input{
	            margin-top: 10px;
	            display: block;
	            font-size: 14px;
	            height: 30px;
	            width: 100%;
	            border:none;
	            outline: none;
	            position: relative;
	            padding-left: 10px;
	            border-bottom: 1px solid $colorGrisMedio;
	            transition: all .3s;
	            color: $colorGrisMedio;
	            &:focus{ border-bottom-color: $naranjaEnd; border-bottom-width: 2px; }
	        }
	    }
	    &.listDetalle{
	    	.itemDetalle{
	    		label{
	    			width: 50%;
	    			background: $grisNivel1;
	    			padding: 5px;
	    		}
	    		span{
	    			width: 50%;
	    			text-align: right;
	    			padding: 5px;
	    			&.middle{
	    				widows: 25%;
	    			}
	    		}
	    	}
	    }
	}

	.inputNewPagoImg{
		margin-top: -30px;
		margin-bottom: 45px;
		img{
			cursor: pointer;
			border-radius: 5px;
			.camarita{}
			&.imgPago{max-width: 350px;min-height: 300px;}
		}
	}
	#inputPagoImg{ display: none; }

}

.cont-Unidades{
    width: 100%;
    max-width: 1400px;
    display: flex;
    justify-content: flex-start;
	flex-wrap: wrap;
	margin: 10px auto;
}

.cont-Unidades2{
	margin: 20px auto;
    width: 80%;
    max-width: 1400px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.miunidad{
	box-shadow: 0px 0px 10px 0px $colorGrisMuyBajo;
	transition: all .5s;
	border-radius: 5px;
	margin: 5px;
	padding: 5px;
	height: 35px;
	width: 250px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	&:hover{
		box-shadow: 0px 0px 10px 5px $colorGrisMuyBajo;
		color: $naranjaEnd;
		cursor: pointer;
	}
	.nombre{
	}
	.delete{
		color: red;
		cursor: pointer;
		img{
			height: 25px;
		}
	}
}