@import './../../../color.scss';
.nodalRegistroStripePapa{}

.nodalRegistroStripe{
	
	&.wiClose{
		.ant-modal-close-x{
			svg{fill: white;transform: scale(1.2);}
		}
	}

	&.mxw800{
	  width: auto!important;
	  border-radius: 15px;
	}
	
	.ant-modal-content{
		padding: 0px;
		margin-top:20px;
		.ant-modal-body{
			padding: 0;
		}
		width: 100%;
		border-radius: 10px;
	}

	.contentstripe{
		width: 100%;
		.head{
			border-radius: 10px 10px 0 0;
			transition: all .5s;
			background-color:$naranjaStart;
			background-image: linear-gradient(90deg, $naranjaStart 0%, $naranjaEnd 70%);
			height: 80px;
			display: flex;
			justify-content: center;
			align-items: center;
			.logoBu{ height: 100%; }
			&.pdd{
				padding: 10px;
			}
		}

		.notaData a,.notaData i{
			font-family: 'Poppins-SemiBold';
			color: $grisNivel5;
			display: inline;
			cursor: pointer;
		}

		.notaData i{font-style: normal;}
		
		.titleBlock{
			text-align: center;
			.title{
				display: flex;
		        justify-content: center;
		        align-items: center;
		        font-size: 25px;
		        line-height: 25px;
		        font-family: 'Poppins-Bold';
		        &.black{color:$grisNivel5;}
		        &.orange{color:$naranjaNivel2;}
			}
		}
		.separadorRects{
	        height: 20px;
	        margin: 10px 0;
	        display: flex;
	        justify-content: center;
	        align-items: center;
	        div{background: black; margin: 0 5px;}
	        .rect1{ width: 10px; height: 6px; border-radius: 2px;}
	        .rect2{ width: 30px; height: 6px; border-radius: 2px;}
	        .rect3{ width: 60px; height: 6px; border-radius: 2px;}
	    }
	    .texto{
	        color: $grisNivel5;
	        font-size: 14px;
	        text-align: center;
	        div{font-family: 'Poppins-SemiBold';}
	    }

		.footStripe{
			display: flex;
			justify-content: flex-end;
			align-items: center;
			margin:10px;
			margin-top: -5px;
			padding: 10px;
			.btnFootStripeModal{
				height: 35px;
			    width: 120px;
			    border: 1px solid #d5d5d5;
			    box-shadow: 0px 0px 10px 0px #d5d5d5;
			    border-radius: 5px;
			    transition: all .5s;
			    background-image: linear-gradient(to right, #FF9700, #F77400);
			    color: white;
			    font-size: 15px;
			    font-weight: bold;
			}
		}
		.body{
			padding: 5px;
			.titleB{
				font-size: 17px;
				text-align: center;
				padding: 5px;
				color: $naranjaEnd;
				font-weight: bold;
				&.titleLeft{
					text-align: left;
					margin-left: 10px;
					margin-top: 10px;
					margin-bottom: 5px;
				}
				&.clrGery{
					margin-top: -5px;
					margin-bottom: -5px;
					color: $colorGrisFuerte;
				}
			}
			.itemInfo{
				margin:10px 20px;
				display: flex;
				justify-content: flex-start;
				font-size: 16px;
				padding: 5px;
				span{
					width: 40%;
					text-align: left;
					font-weight: bold;
				}
				label{
					width: 60%;
					text-align: left;
					.dots{width:auto!important;font-weight: bold;font-size: 18px; display: inline-flex;justify-content: center;align-items: center;}
				}
				&:nth-child(odd){
            		background: rgba(0,0,0,.05);
        		}
			}
			.tarjetaMembresiaRed{
				background: #eaafb3;
				margin:10px 20px;
				border-radius: 5px;
				transition: all .5s;
				padding: 10px;
			}
			.tarjetaMembresia{
				background: white;
				margin:10px 20px;
				border-radius: 5px;
				transition: all .5s;
				box-shadow: 0px 0px 10px 0px $colorGrisMuyBajo;
				padding: 10px;
				&:hover{box-shadow: 0px 0px 10px 3px $colorGrisMuyBajo;}
				.title{
					display: flex;
					justify-content: space-between;
					font-size: 16px;
					span{}
					label{
						font-weight: bold;
						&.Activa{color:#22B573;}
						&.Incompleta,&.Vencida,&.Periododeprueba{color:$naranjaEnd;}
						&.Expirada,&.Cancelada,&.Sinpagar{color:$rojoNibiru;}
					}
				}
				.miplanb{
					&.miplanb{
						font-weight: bold;
					}
				}
			}
			.pagotarjeta{
				background: white;
				margin:10px 20px;
				border-radius: 5px;
				transition: all .5s;
				box-shadow: 0px 0px 10px 0px $colorGrisMuyBajo;
				padding: 10px;
				&:hover{box-shadow: 0px 0px 10px 3px $colorGrisMuyBajo;}
				cursor: pointer;
				.headPago{
					display: flex;
					justify-content: space-between;
					font-size: 16px;
					span{
						font-weight: bold;
						color:$naranjaStart;
					}
					label{font-weight: bold;}
				}
				.bodyPago{
					font-size: 15px;
					.dots{font-weight: bold;font-size: 18px; display: inline-flex;justify-content: center;align-items: center;}
				}
			}
			.error-content{
				margin:5px 20px;
				font-size: 14px;
				color: $rojoNibiru;
				height: 15px;
			}

			.warning{
				margin:5px 20px;
				font-size: 14px;
				text-align: justify;
				color: $colorGrisMedio;
				a,i{
					font-family: 'Poppins-SemiBold';
					color: $grisNivel5;
					display: inline;
					cursor: pointer;
				}i{font-style: normal;}
				&.justifed{
					transition:height .5s;
					overflow: hidden;
					&.overred1{height: 50px;}
					&.overred2{height: 150px;}
					&.overred3{height: 280px;}
					span{
						font-size: 14px;
						&.showM{sub{color:$naranjaNivel2; font-size: 12px; font-family: 'Poppins-Light'; cursor: pointer;}}
					}

					&.overoverred1{ height:120px;}
					&.overoverred3{ height:360px;}
				}
			}
			.contButton{
				margin:10px 20px;
				display: flex;
				justify-content: center;
				align-items: center;
				button{
					color: white;
					font-weight: bold;
					font-size: 17px;
					background-color:$naranjaStart;
					background-image: linear-gradient(90deg, $naranjaStart 0%, $naranjaEnd 70%);
					width:100%;
					padding: 5px 10px;
					border-radius: 5px;
					transition: all .5s;
					box-shadow: 0 0 10px 0 #d5d5d5;
					&:disabled{
						opacity: 0.5;
						background-image: linear-gradient(90deg, $colorGrisBajo 0%, $colorGrisMedio 70%);
						cursor: not-allowed;
					}
					&.disabled{
						background-image: none;
						background-color: white;
						color:$colorGrisBajo;
						font-weight: bold;
						border: 1px solid #d5d5d5;
						&:hover{color: $naranjaEnd;}
					}
				}
				button.white{
					background-image: none;
					background-color: white;
					color:$grisNivel3;
					&:hover{
						color:$naranjaStart;
					}
				}
			}
			.opcionSucription{
				transition: all .5s;
				box-shadow: 0px 0px 10px 0px $colorGrisMuyBajo;
				margin: 10px 15px;
				&:hover{box-shadow: 0px 0px 10px 3px $colorGrisMuyBajo;}
				display: flex;
				justify-content: space-between;
				border-radius:5px;
				height: 90px;
				.left{
					padding: 10px;
					width: 50px;
					display: flex;
					justify-content: center;
					align-items: center;
					.radioBubook{
						height: 18px;
    					width: 18px;
					}
				}
				.right{
					padding: 10px;
					width: calc(100% - 50px);
					display: flex;
					justify-content: center;
					align-items: center;
					&.lines{display: block;}
					.title{
						color: $colorGrisMedio;
						font-weight: bold;
						font-size: 16px;
						text-align: center;
						display: block;
						width: 100%;
					}
					.desc{ text-align: center;  display: block;}
				}
			}
			.prueba{
				text-align: center;
				font-weight: bold;
				font-size: 17px;
			}
			.disfruta{
				margin:10px 20px;
				.itemFruta{
					display: flex;
					justify-content: flex-start;
					align-items: center;
					span{
						margin-left: 5px;
						color: $naranjaEnd;
						font-weight: bold;
						font-size: 20px;
					}
					label{
						margin-left: 5px;
					}
				}
			}
      .fechaSexo{
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          .inputs-group.inputs-group-inline{ 
              flex-grow: 1;
              width: 100%;
              &:first-child{
                  margin-right: 15px;
              }
          }
      }

      &.codPro{
      	padding: 10px;
      	.title{
      		padding: 10px;
      		font-size: 17px;
      		color: $naranjaNivel2;
      		font-family: 'Poppins-SemiBold';
      		margin-bottom: 10px;
      	}
      	.notaData{
      		padding: 5px 10px;
      		font-family: 'Poppins-Medium';
      		font-size: 14px;
      		text-align:justify;
      		color: $grisNivel4;
      	}
      	.inputs-group{
      		padding:10px;
      		margin: 5px 0;
      		.inputs-input{
      			@include clearBorder();
      			border-bottom: 1px solid $grisNivel5!important;
      			border-radius: 0px !important;
      			&:focus{
      				border-bottom: 2px solid $naranjaNivel2!important;
      			}
      		}
      	}
      	button{
      		background-image: linear-gradient(90deg, $naranjaStart 0%, $naranjaEnd 70%);
      		color:$blanco;
      		display: block;
      		margin: auto;
      		padding: 13px 10px;
      		font-family: 'Poppins-Medium';
      		border-radius:10px;
      		width: 98%;
      		font-size: 16px;
      		cursor: pointer;
      		&.whithe{
      			margin-top: 10px;
      			margin-bottom: 20px;
      			background-image: none;
      			background: white;
      			color:$grisNivel5;
      			box-shadow: 0px 0px 15px 1px $grisNivel2;
      		}
      	}
      	.nirvana{
      		height: 120px;
      		display: block;
      		margin: auto;
      		margin-top: -15px;
      		margin-bottom: -25px;
      	}
      	&.table{
      		.title,.titlesub{
      			color: $grisNivel4;
      			font-family: 'Poppins-Medium';
      			margin-bottom: 0px;
      		}
      		.titlesub{font-size: 15px;}
      	}
      	.tbla{
      		margin:10px auto;
      		margin-bottom: 15px;
      		.heade{
      			color: $grisNivel5;
      			background: $grisNivel2;
      			font-size: 15px;
      			padding: 5px;
      			text-align:center;
      		}
      		.meta,.data{text-align: center;width: 50%;;}
      		.meta{background: $grisNivel1;font-family: 'Poppins-SemiBold';}
      	}
      }
		}

		.inputs-group{
    	display: block;
      position: relative;
      transition: all .3s;
      padding: 10px 20px;
    }
    .inputs-label{font-size: 12px;
   		font-weight: bold;
   		color: $colorGrisMedio;
   		position: relative;
   		top: -5px;
   		display: block;
   		transition: all .5s;
    }
    .inputs-input{
        display: block;
        font-size: 14px;
        width: 100%;
        border:none;
        outline: none;
        position: relative;
        padding-left: 10px;
        border-bottom: 1px solid $colorGrisMedio;
        transition: all .3s;
        color: $colorGrisMedio;
        &:focus{ border-bottom-color: $naranjaEnd; border-bottom-width: 2px; }
    }
    .contPlanes{
      display: flex;
      justify-content: center;
      align-items: baseline;
      padding: 10px;
      padding-top: 30px;
      transition: all .5s;
      .plan{
        transition: all .5s;
        background: $blanco;
        margin: 0 20px;
        position: relative;
        border-radius: 15px;
        box-shadow: 0px 0px 10px 1px rgba(0,0,0,.3);
        cursor: pointer;
      }
      .Precio{
        display: flex;
        justify-content: center;
        align-items: flex-end;
        margin-top: 30px;
        padding: 15px 20px;
        padding-bottom: 5px;
        span{
            font-size: 25px;
            color:$naranjaNivel2;
            font-family: 'Poppins-SemiBold', sans-serif;
            font-weight: bold;
        }
        label{
            font-size: 40px;
            line-height: 50px;
            color: $grisNivel5;
            font-family: 'Poppins-SemiBold', sans-serif;
            cursor: pointer;
        }
        h4{
            display: block;
            text-align: center;
            font-family: 'Poppins', sans-serif;
            span{font-size: 20px; display: block;color: $grisNivel5;line-height: 20px;}
            label{display: block;color:$grisNivel5;font-size: 15px;line-height: 15px;font-weight: normal;}
        }
      }
      .nota{
        background-color: $naranjaNivel2;
        text-align:center;
        font-weight: bold;
        font-family: 'Poppins', sans-serif;
        color:$blanco;
        border-radius: 0 0 15px 15px;
        padding: 5px;
        div{
          &:nth-child(1){font-size: 20px;line-height: 20px;}
          &:nth-child(2){font-size: 25px;line-height: 30px;}
        }
      }
      .radioBubook{
      	position: absolute;
      	right: 10px;
      	top: 10px;
      }
      .etiqueta{
        transition: all .5s;
        position: absolute;
        top:15%;left: -10px;
        width: 100%;
        color: $blanco;
        &.etiqueta2{top:10%;}
        .triangulo{
          background: red;
          width: 10px; height: 10px;
          clip-path: polygon(100% 0, 0 0, 100% 100%);
          background-color:#904B0D;
        }
        .trapecio{
          background: red;
          width: 70%; height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          clip-path: polygon(0 0, 90% 0, 100% 100%, 0% 100%);
          border-radius: 5px 5px 5px 0;
          background-color:$naranjaNivel2;
          font-family: 'Poppins', sans-serif;
          font-weight:300;
        }
      }
    }
    &.redAlert{
    	height: 500px;
    	background: white;
    	border-radius: 6px;
    	.body.codPro{
    		border-radius: 5px 5px 0 0;
    		padding: 20px;
    		background:#ec3a48;
    		.title{color: white;margin-bottom: 0;}
				.notaData{
					color: white;font-family: 'Poppins-Regular';margin:5px auto 20px auto;
					span{font-size: 13px;margin-top: 0px;margin-bottom: 0px;}
				}
				.whithe{color:$grisNivel5;background: white;box-shadow: none; padding: 7px; }
    	}
    }
	}
}