@import './../../../color.scss';



.contBuscador{
    margin: auto;
    width: 80%;
    max-width: 1400px;
    margin-top: 5px;
    .inputs-group{
        display: block;
        position: relative;
        transition: all .3s;
        .inputs-label{
           font-size: 12px;
           font-weight: bold;
           color: $colorGrisMedio;
           display: block;
        }
        .inputs-input{
            margin-top: 10px;
            display: block;
            font-size: 14px;
            height: 30px;
            width: 100%;
            border:none;
            outline: none;
            position: relative;
            padding-left: 10px;
            border-bottom: 1px solid $colorGrisMedio;
            transition: all .3s;
            color: $colorGrisMedio;
            &:focus{ border-bottom-color: $naranjaEnd; border-bottom-width: 2px; }
        }   
    }
}

.infoClientes{
    margin: auto;
    width: 80%;
    max-width: 1400px;
    margin-top: 5px;
    display: flex;
    .info{
        width: 25%;
        text-align: center;
        background: rgba(0,0,0,.1);
        padding: 5px;
        span{
            display: block;
            font-weight: bold;
            font-size: 15px;
        }
        label{ font-size: 14px; }
    }
}

.tarjetaCliente{
	border-radius: 5px;
	width: 300px;
	box-shadow: 0px 0px 5px transparentize($colorGrisMedio , .5);
	padding: 5px;
    transition: all .5s;
    cursor: pointer;
    margin: 3px;
    &:hover{ box-shadow: 0px 0px 5px 1px transparentize($colorGrisMedio , .5);}
	.nombre{
		font-size: 14px;
        color: $colorGrisFuerte;
        font-weight: bold;
        overflow: hidden;
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
	}
	.mail{
		font-size: 14px;
        color: $colorGrisFuerte;
        overflow: hidden;
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
	}
}


.detalleCliente{
    margin: -10px;
    margin-top: -15px;

    &.mxh .body{
      max-height: 250px;
      overflow-y: auto;
      overflow-x: hidden;
      scroll-behavior: smooth;
      transition: all .5s;
      &::-webkit-scrollbar{ width:10px; }
      &::-webkit-scrollbar-button{ display: none; }
      &::-webkit-scrollbar-track {background:#F3F0F0;} 
      &::-webkit-scrollbar-thumb { background-color:#dad8d8; &:hover{ background-color:#8F969B;} }
    }

    .head{  padding: 10px; }
    .titleSeparador{
        font-size: 15px;
        color: $naranjaEnd;
        display: block;
        font-weight: bold;
        text-align: left;
        padding: 5px 10px;
    }
    .body{}
    .info{
        display: flex;
        justify-content: flex-start;
        &.infoInline{
            display:inline-flex;
            width: 100%;
            .infoInlineChild{
                display: flex;
                width: 50%;
            }
        }
    }
    .meta{
        font-weight: bold;
    }
    .content{
        padding-left: 5px;
        &.entregado{color:#22B573;font-weight: bold; }
        &.enproceso{color:#12E0A5;font-weight: bold; }
        &.enruta{color:#F77400;font-weight: bold; }
        &.pendiente{ color:#FF9700; font-weight: bold; }
        &.cancelado{color:#ED3B48;font-weight: bold; }
    }
    .detallePedido{
        border-radius: 5px;
        box-shadow: 0px 0px 5px transparentize($colorGrisMedio , .5);
        padding: 5px;
        transition: all .5s;
        cursor: pointer;
        margin: 5px;
        &:hover{ box-shadow: 0px 0px 5px 1px transparentize($colorGrisMedio , .5);}
    }
    .headGris{
        font-size: 15px;
        background: rgba(0,0,0,.05);
        padding: 5px 10px;
        margin-top:5px;
        font-weight: bold;
        text-align: right;
    }
    .spaceMakerCli{
        margin: 3px auto;
    }
}