@import './../../../color.scss';

.itemDireccion{
  .metaInfo{
    img{
      height: 120px;
      border-radius: 10px;
      margin-right: 20px;
      border: 1px solid transparentize($grisNivel1,.2) ;
    }
  }
}

.contentFavsEmbed{
	width: 450px;
	.titleSectionBubooker{
		span{
			font-size: 14px;
			color: $naranjaNivel2;
			text-decoration: underline;
			cursor: pointer;
		}
	}
}