@import './../../../color.scss';

.containerBrowserBubookerChildThree{
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

.containerBrowserBubookerChildFour{
  width: 100%;
  height: 100%;
  //background-color:deeppink;
}

.titleSectionBubooker{
  color:$grisNivel5;
  font-family: "Poppins-Bold";
  font-size: 30px;
  transition: all .5s;
  border-bottom: 2px solid $grisNivel4;
  i{
    margin-left: 5px;
    cursor: pointer;
    &:hover{ svg{fill:$grisNivel5;} }
    svg{
      height: 20px;
      transform: translateY(-3px);
      fill:$grisNivel4;
    }
  }


  .btnClear{
    background: $naranjaNivel2;
    font-family: "Poppins-Medium";
    font-size: 15px;
    padding:5px 15px;
    color: $blanco;
    border-radius: 5px;
  }


}

.listCItasBuBooker{
  .header{
    background-color: $grisNivel2;
    font-family: "Poppins-Medium";
    font-size: 15px;
    padding:5px;
  }
  .conCitas{
    width: 100%;
    margin-top: 5px;
    max-width: initial;
  }
}

.itemBackBu{
  i{ font-size: 17px; svg{fill:$grisNivel3;} }
  span{ margin-left: 5px; font-size: 14px; color:$grisNivel5; transition: all .5s; }
  cursor: pointer;
  width: fit-content;
  &:hover{
    span{color:$naranjaNivel1;}
    svg {fill:$naranjaNivel1;transition: all .5s; }
  }
}

.containerBrowserBubookerChildTwo{
  .nonResult{
    margin: 20px auto;
    height: 400px;
    flex-flow: column;
    img{height: 350px;}
    &:after{
      font-family: "Poppins-Medium";
      font-size: 20px;
      content: '¡No se encontraron resultados!';
    }
  }
  .ant-input.inputsB{
    @include clearBorder();
    border-radius: 0px!important;
    border-bottom: 1px solid $grisNivel5!important;
    padding: 5px;
    font-size: 14px;
    font-family: "Poppins-Medium";
    margin-top: -2px;
    &:focus{
      border-bottom: 2px solid $naranjaEnd!important;
    }
  }
  .labelsB{
    font-family: "Poppins-Medium";
    font-size: 15px;
    color: $grisNivel5;
    span{
      font-family: "Poppins-Regular";
    }
  }
}

.flexspacebend{
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
}

.flexspacebcenter{
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
}

.flexcentercenter{
	display: flex;
	justify-content: center;
	align-items: center;
}

.perfilCliente{
	border-radius: 5px;
	box-shadow: 0px 0px 5px $grisNivel3;
  background: white;
	max-width: 530px;
	margin: auto;
  margin-top: 20px;
	padding: 10px 25px;
	.inputs-label{
	   font-size: 12px;
	   font-weight: bold;
	   color: $colorGrisFuerte;
	}
	.inputs-group{
    display: block;
    margin-top: 20px;
    position: relative;
    transition: all .3s;
    text-align: left;
    .ant-input,.inputs-input{
        display: block;
        font-size: 14px;
        height: 30px;
        width: 100%;
        border:none;
        outline: none;
        position: relative;
        padding-left: 10px;
        border-bottom: 1px solid $colorGrisMedio;
        transition: all .3s;
        color: $colorGrisMedio;
        border-radius:0;
        &:focus{border-bottom-color: $naranjaEnd; border-bottom-width: 2px; box-shadow: none; }
        &:hover{border-bottom-color: $naranjaEnd!important;}
        &.readOnly{ background: rgba(0,0,0,.05); }
    }
    
    .ant-input-affix-wrapper{
      //@include clearBorder;
      padding-left: 0;
      &:hover .ant-input{border-bottom-color: $naranjaEnd!important;}
      .ant-input-suffix{font-size: 18px;}
      .ant-input:hover{border-bottom-color: $naranjaEnd;}
    }

   }
  .inputs-group2{
  	text-align: left;
  	border-bottom: 1px solid $colorGrisMedio;
  	height: 50px;
  	padding-right: 10px;
  	.fakeSex{
  		width: 80px;
  		height: 30px;
  		margin: 2px 0;
  		font-weight: bold;
  		background: rgba(0,0,0,0.05);
  		cursor: pointer;
  		transition: all .5s;
  		&.active{
  			background: $naranjaEnd;
  			color: white;
  		}
  	}
  }

  .labelnewPW{
    text-align: center;
    color:$grisNivel5;
    margin: 15px auto 0px auto;
    font-family: "Poppins-Bold";
  }

  .inputsEnLinea{ .inputs-group,.inputs-group2{ width: 49%; } }

  .changer{
  	margin: 5px 0;
  	padding: 10px;
  	color: $naranjaStart;
  	span{font-weight: bold;cursor: pointer;}
  }

  .head{
  	.srcPerfil{
  		width: 150px;
  		border-radius: 5px;
  		margin: auto;
  		position: relative;
  		img{
  			height: 100%;
  			width: 100%;
  			border-radius: 5px;
  		}
  		input{display: none;}
  		label{
  			position: absolute;
  			height: 40px;width: 40px;
  			opacity: 0.7;
  			transition: all .5s;
  			cursor: pointer;
  			img{width: 100%;height: 100%;}
  			top: 100%;left: 0%;
  			transform: translate(0%,-100%);
  			&:hover{opacity: 1;}
  		}
  	}
  	.name{
  		margin-top:5px;
  		font-size: 17px;
  		font-weight: bold;
  	}
  	.mail{}
  }

  .divConBotton{
  	display: flex;
  	justify-content: flex-end;
  	align-items: flex-end;
  	height: 50px;
  	button{
			height: 35px;
			width: 150px;
			border: 1px solid $colorGrisMuyBajo;
			box-shadow: 0px 0px 10px 0px $colorGrisMuyBajo;
			border-radius: 5px;
			transition: all .5s;
			background-image: linear-gradient(to right, $naranjaStart , $naranjaEnd );
			color: white;
			font-size: 15px;
			font-weight: bold;
			&:hover{ box-shadow: 0px 0px 3px 3px transparentize($grisNivel1,0.5) }
      &.btn1{
        width: auto;
        padding: 15px;
        background: none;
        color: $grisNivel3;
        &:hover{ color:$naranjaNivel2; }
      }
  	}
  }
  .separadorGris{height: 50px;background: rgba(0,0,0,0.05);margin:0 -10px;}
  .cardBodyOption{
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 40px;
      label{
          color: $colorGrisBajo;
          font-size: 14px;
          font-weight: bolder;
          cursor: pointer;
      }
  }
}

.contContra{
	margin: 5px;
	padding: 10px;
}
.wrapperCropper{
	margin: 5px;
	height: 300px;
	widows: 100%;
}