@import './../../../color.scss';

.modalUnidad{
	.ant-modal-content{border-radius: 5px!important;}
	.ant-modal-header{
		border-radius: 5px!important;
		padding: 10px 20px;
		border: none;
	}

	.ant-modal-title{
		color: $naranjaEnd;
		font-size: 17px;
		text-align: center;
		padding: 10px;
		font-weight: bold;
	}

	.ant-modal-body{
	}

	.ant-modal-footer{ border: none; padding-bottom: 15px;}
	.miUniDiv{
		
		.nivelNom{
			display: flex;
			justify-content: space-between;
			.inputs-group{
				&.ja0{width: 20%;}
				&.ja1{width: 75%;}
			}
		}
		.inputs-group{
	        height: 35px;
	        position: relative;
	        .inputs-label{
	            text-align: left;
	            font-size: 12px;
	            color: $colorGrisMedio;
	            font-weight: bold;
	            position: absolute;
	            top: 0px;
	            left: 0px;
	            transform: translate(0,-10px);
	        }
	        .inputs-input{
	            margin-top: 10px;
	            display: block;
	            font-size: 14px;
	            height: 30px;
	            width: 100%;
	            border:none;
	            outline: none;
	            position: relative;
	            padding-left: 10px;
	            border-bottom: 1px solid $colorGrisMedio;
	            transition: all .3s;
	            color: $colorGrisMedio;
	            &:focus{ border-bottom-color: $naranjaEnd; border-bottom-width: 2px; }
	        }
	    }
	    /*.ant-select.ant-select-enabled,
		.ant-select.ant-select-open.ant-select-focused.ant-select-enabled,
		.ant-select.ant-select-focused.ant-select-enabled{
		    .ant-select-selection.ant-select-selection--single,
		    .ant-select-selection--multiple{
		        box-shadow: none;
		        border: none;
		        outline: none;
		        border-bottom: 1px solid $colorGrisMedio;
		        border-radius: 0;
		        &:focus{
		            border-bottom: 2px solid $naranjaEnd;
		        }
		    }
		    svg{
		        fill: $naranjaEnd;
		        height: 20px;
		        width: 15px;
		    }
		}*/
	}
}

.cont-Unidades{
    width: 100%;
    max-width: 1400px;
    display: flex;
    justify-content: flex-start;
	flex-wrap: wrap;
	margin: 10px auto;
}

.cont-Unidades2{
	margin: 20px auto;
    width: 80%;
    max-width: 1400px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.miunidad{
	box-shadow: 0px 0px 10px 0px $colorGrisMuyBajo;
	transition: all .5s;
	border-radius: 5px;
	margin: 5px;
	padding: 5px;
	height: 35px;
	width: 250px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	&:hover{
		box-shadow: 0px 0px 10px 5px $colorGrisMuyBajo;
	}
	.nombre{

	}
	.delete{
		color: red;
		cursor: pointer;
		img{
			height: 25px;
		}
	}
}