@import './../../../color.scss';

.ant-modal.modalPermisos{
    width: 100vw!important;
    max-width: 800px;

    .ant-modal-content{ }

    .ant-modal-header{
        border: none;
        .ant-modal-title{ padding: 0; }
    }
    .ant-modal-body{ padding:0 15px ; }
    .ant-modal-footer{ border: none; }
}

.mycehc{
    margin-left: 10px;
    input{display: none;}
    .ant-checkbox.ant-checkbox-checked{
        .ant-checkbox-inner{
            background-color:$naranjaNivel2;
            border-color:$naranjaNivel2;
            &:hover{border-color: red; }
        }
        &::after{ border-color: red; }
    }
    //.ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner
    //.ant-checkbox{ &::after{ border-color: red; } }
    &:hover .ant-checkbox-inner{ border-color:$naranjaNivel2; }
}

.divPermisosWrapper{}

.contentGroupPermisos{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items:stretch;

    .itemCategoria{
        max-height: 380px;
        min-height: 150px;
        height: max-content;
        min-width: 250px;
        flex-grow: 1;

        .titleCat{
            background: $grisNivel2;
            padding: 5px;
        }
    }
    .bloquePermiso{
        .titlePermi{
            width: 90%;
            margin-left: 5px;
            color: $grisNivel5;
            svg{fill:$naranjaNivel2;}
            cursor: pointer;
        }

        &.opened{
            svg{
                transform: rotate(180deg);
            }
        }

        .funcionesParent{
            flex-flow: column;
            max-height: 0;
            overflow: hidden;
            transition: max-height .5s!important;
            &.opened{
                max-height: 85px;
                transition: max-height .5s!important;
            }
            .mycehc:first-child{ margin-left: 8px; }            
        }
    }
}

.miUniDiv.userempresa{
    margin-top: -10px;
    margin-bottom: -20px;
    .inputs-group{
        height: 55px;
        .inputs-label{
            position: relative;
            top: initial;
            left: initial;
            transform: translate(0,0)!important;
        }
        .inputs-input{
            margin-top: 0px;
            &.ant-input{
                @include clearBorder();
                border-radius: 0px!important;
                border-bottom: 1px solid $grisNivel5!important;
                &:focus{
                    border-bottom: 2px solid $naranjaNivel2!important;
                }
            }
        }
    }
    &.case1{   }
    &.case2{}

    &.choseSuc{
        margin: -20px auto;
        .mycehc{
            margin-left: 0!important;
            font-size: 15px;
            color: $grisNivel5;
            padding: 5px;
        }
    }
}

.tarjetausuario{
    //font-family: "Poppins-BoldItalic";
	border-radius: 5px;
	width: 320px;
	padding: 5px;
    transition: all .5s;
    cursor: pointer;
    margin: 3px;
    border-radius:10px;
    border: 1px solid $grisNivel1;
         box-shadow: 5px 5px 10px 0px $grisNivel1;
&:hover{ box-shadow: 5px 5px 10px 5px $grisNivel1 ;}

    .meta{
        height: 100%;
        flex-grow: 1;
    }

    .ops{
        height: 100%;
        img{height: 30px;}
    }

    .data{
        //padding: 5px;
        //background: red;
        margin: 2px auto;
        img{
            height: 30px;
            border-radius:100%;
        }
        span{
            width: 100%;
            max-height: 30px;
            margin-left: 10px;
            line-height: 15px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }
}

.detalleCliente{

}