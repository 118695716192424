@import './../../../color.scss';



.makeReport{
    font-family: "Poppins-Medium";
    color: $naranjaNivel2;
    font-size: 15px;
    margin: 20px 0;
    span{cursor: pointer;}
}

.bigButton{
    background-image: linear-gradient(to right, $naranjaNivel1 20% , $naranjaNivel2 );
    color: white;
    display: block;
    padding: 15px;
    width: 100%;
    margin-top: -24px;
    border-radius: 10px;
    font-family: "Poppins-Medium";
    font-size: 15px;
}

.checkInFilter{
    span.ant-checkbox{
        display: inline-flex;
        background-color: deeppink;
        input[type="checkbox"]{
            position: absolute;
        }
        .ant-checkbox-inner{ border-color: $grisNivel5; } 

        &.ant-checkbox-checked{
            &::after{display: none;}
            .ant-checkbox-inner{
                background-color:$naranjaNivel2;
                border-color: $naranjaNivel2;
            }
        }
    }
}

.tarjetaEmbded{
    margin: 10px 0;
    img{
        margin-right: 20px;
        &.nomg{
            margin-right: 10px;
            margin-left: 25px;
            width: 80px!important;
        }
    }
    width: fit-content;
    
    &.mini{
        width: 100%;

        .info i{
            margin: 0 15px;
        }

        img{
            &.nomg{
                margin-right:initial;
                margin-left: initial;
                margin: auto;
                display: block;
                width: 100px!important;
            }
        }
        
    }

    .subinfo{
      flex-direction: column;
      margin: 5px auto;
      width: 100%;
    }
    .info{
        i{
            margin: 0 30px;
            font-size: 25px;
            svg{
                &:hover{fill: $naranjaNivel2;}
            }
        }
    }
    .meta{width: max-content;}
    .content{width: max-content;}
}

.tarjetaPedido2{

    .detallePedido{
        border-radius: 5px;
        box-shadow: 0px 0px 5px transparentize($colorGrisMedio , .5);
        transition: all .5s;
        cursor: pointer;
        margin: 5px;
        &:hover{ box-shadow: 0px 0px 5px 1px transparentize($colorGrisMedio , .5);}
        padding: 5px;
    }
    
    .imgPedido{
        padding: 10px;
        width: 90px;
        img{ width: 100%; border-radius:5px; }
    }

    .headhead{
        padding: 10px;
        background:#fafafa;
    }
    .bodybody{ padding: 10px; background: $blanco;}

    .headGris{
        font-size: 15px;
        background: rgba(0,0,0,.05);
        padding: 5px 10px;
        margin-top:10px;
        font-weight: bold;
        //text-align: right;
    }

    .content{
        padding-left: 5px;
        
        &.entregado{color:#22B573;font-weight: bold; }
        &.enproceso{color:#12E0A5;font-weight: bold; }
        &.enruta{color:#F77400;font-weight: bold; }
        &.pendiente{ color:#FF9700; font-weight: bold; }
        &.cancelado{color:#ED3B48;font-weight: bold; }
        
        &.pendientedepago{ color:red; text-decoration: underline;}
        &.pagado{color:green;}
        &.reembolso{color: $azulBuBook;}
        /*
        #22B573
        #12E0A5
        #F77400
        #FF9700
        #ED3B48
        */

        &.left{text-align: right;width: 100%; }
        &.preNan{ color: $naranjaStart; }
        &.refer{color:$grisNivel3;}
    }
    .info{
        display: flex;
        justify-content: flex-start;
        &.infoInline{
            display:inline-flex;
            width: 100%;
            .infoInlineChild{
                display: flex;
                width: 50%;
            }
        }
        &.flex{ display: flex; justify-content: space-between; }
    }
    .infoMap{
        height: 350px;
        width: 100%;
        margin: 10px 0;
    }
    
    .spaceMaker{
        height: 20px;
        margin: 5px 0;
        background: $grisNivel1;
    }

    .meta{ font-weight: bold; }
    .contTabla{
        background: $blanco;
        padding: 10px;
    }
    .tablaHead{
        text-align: left;
        font-weight: bold;
        .monry{ text-align: right; }
    }
    .filatablaPorProducto{
        border-bottom: 1px solid $grisNivel5;
        padding-left:5px;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .tablaBody{
        text-align: left;
        font-size:15px;
        color: $colorGrisFuerte;
        &.sub{
            font-size: 12px;
            color: $colorGrisBajo;
        }
        .cantidad{
            color: $naranjaStart;
            font-weight: bold;
        }
        .precio{
            text-align: right;
            .preNan{ color:$naranjaStart; }
            .preGris{ text-decoration: line-through; }
        }
        .campo{
            height: 100%;
            display: flex;
            align-items: center;
            &.imprt{

                &.imprtChild{
                    text-align: right;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: flex-end;
                }

                justify-content: flex-end;
                .preNan{ color:$naranjaStart; }
                .preGris{ text-decoration: line-through; }
            }
        }
    }

    .footBut{
        padding: 10px;
        display: flex;
        justify-content: flex-end;
    }
    .btn{
        height: 35px;
        width: 150px;
        padding:5px;
        border: 1px solid $colorGrisMuyBajo;
        box-shadow: 0px 0px 10px 0px $colorGrisMuyBajo;
        border-radius: 5px;
        transition: all .5s;
        background-image: linear-gradient(to right, $naranjaStart , $naranjaEnd );
        color: white;
        font-size: 15px;
        &:hover{ box-shadow: 0px 0px 5px 3px transparentize($colorGrisBajo,0.5) }
        &:disabled{
            cursor:not-allowed;
            transform: scale(.95);
        }
        &.btn2{
            background: none;
            color: $colorGrisBajo;
            font-weight: bolder;
            opacity: .7;
            &:hover{ color: $naranjaEnd}
        }
        &.btn1{
            font-weight: bold;
            margin-left: 10px;
        }
    }
    .comentario{
        .txtMeta{
            font-weight: bold;
            font-size: 15px;
        }
        .txtComen{}
    }
}



.bloqueFiltro{   
    
    .fechaSexo{
        display: flex;
        .inputs-group.inputs-group-inline{
            &:first-child{ margin-right: 10px; }
        }
    }



    .inputs-group-inline{
        flex-grow: 1;
        padding:10px 0;
        .inputs-label{
            font-size: 12px;
            font-weight: bold;
            color: $colorGrisMedio;
        }
        .inputs-input{
            display: block;
            font-size: 14px;
            height: 30px;
            width: 100%;
            border:none;
            outline: none;
            position: relative;
            border-bottom: 1px solid $colorGrisMedio;
            transition: all .3s;
            color: $colorGrisMedio;
            font-weight: normal;
            &:focus{
                border-bottom-color: $naranjaEnd;
                border-bottom-width: 2px;
            }
        }
        .ant-select.ant-select-enabled,
        .ant-select.ant-select-open.ant-select-focused.ant-select-enabled,
        .ant-select.ant-select-focused.ant-select-enabled{
            .ant-select-selection.ant-select-selection--single,
            .ant-select-selection--multiple{
                box-shadow: none;
                border: none;
                outline: none;
                border-bottom: 1px solid $colorGrisMedio;
                border-radius: 0;
                display: block;
                font-size: 14px;
                height: 30px;
                width: 100%;
                &:focus{
                    border-bottom: 2px solid $naranjaEnd;
                }
            }
            svg{
                fill: $naranjaEnd;
                height: 20px;
                width: 15px;
            }
        }
    }
}