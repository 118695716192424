@import './../../color.scss';

$transision-opacidad: opacity 0.5s;
$layout-breakpoint-xsmall: 576px;
$layout-breakpoint-small: 768px;
$layout-breakpoint-medium: 992px;

.page.pagenoscroll{
    overflow: hidden;
}


.pop-Suc.configPop{
  width: 320px;
  .ant-popover-title{display: none;}
  .ant-popover-inner-content{
      .config{
        .bloque1{
          padding: 10px;
          .divExtra{
            height: 70px;
            display: flex;
            justify-content: center;
            align-items: center;
            img{ height: 100%; border-radius: 100%;}
          }
          .titleCofigPop{
            margin-top: 5px;
            text-align: center;
            color: $colorGrisFuerte;
            .nameHome{font-weight:bold;}
            .mailHome{}
          }
        }

        .bloque2{
          .opMenu{
            padding: 5px 10px;
            height: 40px;
            cursor: pointer;
            display: flex;
            align-items: center;
            &:hover{ background: rgba(0,0,0,.05); }
            img{
              height: 25px;
              width: 25px;
              margin-right: 8px;
            }
            &.active{
              background-color: $naranjaStart;
              span{color: white;}
              &:hover{background-color: $naranjaStart;}
            }
          }
        }

        .bloque3{
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 10px;
           button{
              height: 35px;
              width: 120px;
              border-radius: 5px;
              border: 1px solid $colorGrisMuyBajo;
              border-radius: 5px;
              transition: all .5s;
              background-image: linear-gradient(to right, $naranjaStart , $naranjaEnd );
              color: white;
              &:hover{box-shadow: 0px 0px 10px 0px $colorGrisMuyBajo; }
          }
        }

        .bloque4{
          height: 50px;
          font-size: 12px;
          display:flex;
          justify-content: center;
          align-items: center;
          color: $colorGrisBajo;
          .separadorDot{
            height: 4px;width: 4px;
            background: black;
            border-radius: 100%;
            margin: 0 5px;
            font-size: 12px;
          }
          span{
            cursor: pointer;
            &:hover{color: black;}
          }
        }

        .separador{
          display: block; 
          width: 100%;
          height: 1px;
          margin: 0px auto 0px auto;
          background: rgba(0,0,0,0.1);
        }
    }
  }
}

.Layaout-HomeEmpresa{ 
    width: 100%;
    height: 100vh;
    .Nav-HomeEmpresa{
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        width: 100%; 
        height: 60px;
        padding: 10px;
        margin: 0px;
        position:sticky;
        top: 0px;
        border-bottom: 1px solid $colorGrisMuyBajo;
      .Input-Busqueda{
          position: absolute;
          top: 0;left: 0;
          width: 100%;
          text-align: center;
          top: 0;
          height: 100%;
          padding: 0;
          margin: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          .Nav-Option{
              height: 45px;width: 45px;
              margin: 0 25px;
              text-align: center;
              cursor: pointer;
              transition: all .5s;
              border-bottom: 2px solid transparent;
              display: flex;
              justify-content: center;
              align-items: center;
              position: relative;
              img{ height: 25px; width: 25px;}
              &:hover{
                background-color: rgba(0,0,0,.05);
                cursor: pointer;
                border-radius: 2px;
              }
              &.active{
                position: relative;
                border-bottom: 2px solid #2e3842;
              }
              .avisador{
                transition: all 1s;
                position: absolute;
                height: 20px;
                width: 20px;
                background: $naranjaEnd;
                display: flex;
                justify-content: center;
                align-items: baseline;
                border-radius:100%;
                top: 25%;left: 75%;
                transform: translate(-50%,-50%);
                span{color: white; font-weight: bold; }
              }
          }
      }
      .conyey{
        height: 100%;
        width: 50px;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
      }

      .headHome{
        text-align: center;
        color: $colorGrisMedio;
        transition: all .5s;
        cursor: pointer;
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        .divExtra{
          transition: all .5s;
          height: 40px;
          img{
            height: 100%;
            border-radius: 100%;
            display: block;
            margin: auto;
          }
        }
        .nameHome{
          display: none;
          transition: all .5s;
        }
        .mailHome{
          display: none;
          transition: all .5s;
        }

      }

      .Toggle-Button{
        padding: 5px;
        background: none;
        font-size: 20px;
        color: $colorGrisMedio;
        width: 3%;
        z-index: 10;
        margin-right: 10px;
      }
      .Nav-Logo{
        width: 20%;
        height: 50px;
        img{
          height: 100%;
          &:nth-child(1){
            display: block;
            height: 100%;
            cursor: pointer;
            position:relative;
            z-index: 10000;
          }
          &:nth-child(2){
            display: none;
            height: 100%;
          }
        }
      @media (max-width: $layout-breakpoint-small) {
        
        img:nth-child(1){
          display: none; 
        }
        img:nth-child(2){
          display: block; 
        }
      }
    }
    $Iconosbotones: 5px;
    .Nav-Btn-Options{
      width: 18%;
      text-align: right;
      justify-content: flex-end;
      display: grid; 
      grid-template-columns: repeat(3, 20%);
      button{  
        text-align: center; 
        cursor: context-menu;
        img{
          cursor: pointer;
          width: $Iconosbotones;
          height: $Iconosbotones;
        }
      }
    }

  }

  @media (max-width: $layout-breakpoint-small) {
    .Nav-HomeEmpresa {
      position:sticky;
      z-index: 10;
      top: 0px;
      background: rgb(255,255,255);
    }
  }

  .Layaout-Grid-Left{
    display: flex;
    width: 100%;
    height: calc(100% - 60px);// 100%;
        .Left-Side{
            transition: all 1s;
            width:  50px;
            border-right: 1px solid $colorGrisMuyBajo;

            .opMenu{
                height: 40px;
                &.active{
                  background-color: $grisNivel3;
                  span{color: white;}
                  //&:hover{background-color: $grisNivel3;}
                }
                transition: all 0.5s;
                img{ height: 30px; }
                padding: 5px;
                &:hover{ background: rgba(0,0,0,.05); cursor: pointer; }
                img{transform: translateX(7px);width: 25px;height: 25px;}
                span{
                    color: $colorGrisMedio;
                    display: none;
                    font-size: 0px;
                    overflow: hidden;
                    height: 25px;
                    transition: all .1s;
                }
            }
            .separador{
                display: block; 
                content: '';
                width: 90%;
                height: 1px;
                margin: 0px auto 0px auto;
                background: rgba(0,0,0,0.1);
            }
            &.Left-Side-Toggle{
                transition: all 1s;  
                width: 250px;
                .opMenu{
                    width: 240px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    img{margin-right: 15px;width: 25px;height: 25px;}
                    span{
                        color: $colorGrisMedio;
                        font-size: 15px!important;
                        display: initial;
                    }
                    &.active{span{color:white;}}
                }
            }
        }
        .Right-Side{
            width: 100%;
            height: 100%;
            position: relative;
        }
    }
}