@import './../color.scss';

.legacyWrapper{
    padding-bottom: 30px;
    .head{
        background: $naranjaEnd;
        height: 60px;
        padding: 5px;       
        justify-content: center;
        align-items: center;
        display: flex;   
        img{ height: 100%; cursor: pointer; }
    }


.content{
    .body{
        margin: auto;
        width: 100%;
        max-width: 1024px;
    }
    .title{
        margin: auto;
        display: block;
        text-align: center;
        font-size: 23px;
        padding: 5px;
        margin-top: 10px;
    }
}

.section{
    margin: 10px 0;
    .title{
        text-align: left;
        font-size: 17px;
        transition: all 1s;
        color:$naranjaStart;
        font-weight: bold;
    }
    .txt{
        text-align: justify;
        font-size: 15px;
        padding: 5px;
    }
    .titleList{
        padding: 5px;
        margin-left: 10px;
        span{color: $naranjaEnd;font-weight: bold;}
    }
    .itemList{
        padding: 5px;
        margin-left: 15px;
    }
    
    &.primary{ border: none; }
}
}