@import '../color.scss';
.miModalChargin{
    .ant-modal-content{
        background: none;
        box-shadow: none;
        display: flex;
        justify-content: center;
        align-items: center;
        .ant-modal-body{
            background-color: white;
            justify-content: center;
            border-radius: 10px;
            display: flex;
            align-items: center;
            height: 350px;
            width: 350px;
            padding: 0;
            margin: 0;
            img{height: 300px;}
        }
        .ant-modal-footer{ display: none; }
    }
}

.loadingBlanco{
    .ant-modal-content{background:none; box-shadow: none; }
    .ant-modal-body{background: none;}
}