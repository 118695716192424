@import './../../../color.scss';

.listAdressClient{
  padding: 5px 0;

  &.nomchg{
    position:relative;
    min-height: 300px;
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
    scroll-behavior: smooth;
    &::-webkit-scrollbar { width: 10px; }
    &::-webkit-scrollbar-button{ display: none; }
    &::-webkit-scrollbar-track {background:transparentize($grisNivel5,.6);} 
    &::-webkit-scrollbar-thumb { background-color:$grisNivel5; border-radius: 10px;}
  }
}

.itemDireccion{
  padding: 20px;
  margin: 20px 0;
  background: white;
  border-radius: 5px;

  &.fav{
    padding: 10px;
    margin: 0;
    &:first-child{ margin-top: 0; }
    &:last-child{ margin-bottom: -2px; }
    img{ height: 60px!important; }
    h4{margin-bottom: 0px; }
    span{line-height: 16px;}
    &:hover{
      background: $grisNivel1;
      cursor: pointer;
    }
  }

  .cardIcon{
    height: 30px!important;
    margin-right: 20px;
  }

  .trashIcon{ height: 25px; cursor: pointer;}

  .metaInfo{
    width: 70%;
    i{
      background: $grisNivel5;
      height: 40px;
      width: 40px;
      display: flex;justify-content: center;align-items: center;
      border-radius: 5px;
      margin-right: 10px;
      svg{fill:$blanco; height: 25px;}
    }
    .info{
      flex-direction: column;
      h4{
        font-size: 17px;
        font-family: "Poppins-SemiBold";
        i{
          background: $blanco;
          height: 30px;
          width: 30px;
          cursor: pointer;
          svg{height: 30px;}
          &.yesfavorito{ svg{stroke: $grisNivel5; stroke-width:5px; fill:$naranjaNivel2;} }
          &.nofavorito{ svg{stroke: $grisNivel5; stroke-width:5px; }}
        }
      }
      span{
        font-size: 14px;
        line-height: 20px;
        display: block;
        height: 20px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      label{
        font-size: 14px;
        line-height: 20px;
        display: block;
        height: 20px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        &.delete{color:$naranjaNivel2;cursor: pointer;}
      }
      &.card{
        height: 45px;
        width: 45px;
        h4 i{
          height: 45px;
          width: 45px;
          svg{height: 45px;}
        }
      }
    }
    &.card{
      span{height: 20px; font-size: 16px;margin-left: 15px;}
    }
  }

  button{
    font-size: 14px;
    font-family: "Poppins-ExtraLight";
    color: white;
    padding: 5px 20px;
    border-radius: 5px;
    &.new{background: $grisNivel3; }
    &.detail{background: $naranjaNivel2;}
  }

  .coraFavs{
    fill:red;
    stroke:$grisNivel5;
    stroke-width:5px;
    height: 25px;
    margin: 0 10px;
    transform: translateY(3px);
    position: relative;      
  }

  i{ padding: 5px; }


  &.new{
    i{background: $grisNivel3;}
  }
}


.newItemAdress{
  min-height: 500px;
  padding: 30px;
  background: $blanco;
  border-radius: 10px;
  margin-top: 10px;
  .divMap{
    height: 500px;
    width: 50%;
    position: relative;
  }
  .divInputs{
    height: 500px;
    margin-left: 20px;
    flex-direction: column;
    flex-grow: 1;
  }
  .inputGruoper{
    width: 100%;
    .ant-select-show-search{ display: block; }
  }
  .inputGruop{
    padding: 5px;
    width: 100%;
  }

  .addAddressButton{
    font-size: 14px;
    font-family: "Poppins-Regular";
    color: white;
    padding: 5px 20px;
    border-radius: 5px;
    background: $naranjaNivel2;
  }

}