$rojoNibiru:#ED3B48;

$colorGrisMuyBajo:#d5d5d5;
$colorGrisBajo:#848484;
$colorGrisMedio:#666666;
$colorGrisFuerte:#4d4d4d;

$naranjaStart:#FF9700;
$naranjaEnd:#F77400;

$azulBuBook:#37C8EF;

/*	COLORES */
$blanco:#FFFFFF;
$grisNivel1:#F3F0F0;
$grisNivel2:#dad8d8;
$grisNivel3:#8F969B;
$grisNivel4:#707070;
$grisNivel5:#293540;

$naranjaNivel1:#FBB03B;
$naranjaNivel2:#F07505;

$amarillo:#FFD400;

@mixin clearBorder{
  outline: none!important;
  border: none!important;
  box-shadow: none!important;
}