@import './../../../color.scss';


.controlesNuevaCita .ant-input.inputs-input.notfocusable:focus {
  border-bottom: 1px solid $grisNivel3!important;
}

.controlesNuevaCita .ant-input.inputs-input.focused{
  cursor: pointer;
  text-decoration: underline;
  color: $naranjaNivel2;
}

.linkeable{
  color:$naranjaNivel2!important;
  text-decoration: underline;
  cursor: pointer;
}

.newBtn{
  //background: red;
  border-radius: 5px;
  padding: 5px  10px;
  background: none;
  font-size: 15px;
  color:$grisNivel5;
  font-family: "Poppins-Medium";
  &.btnSecundario{
    color:$blanco;
    background:$grisNivel5;
  }
}

.moreDots{
  //box-shadow: 0px 0px 3px $colorGrisBajo;
  //border:1px solid transparentize($grisNivel1,0);
  margin-left: 5px;
    cursor: pointer;
    //&:hover{ svg{fill:$grisNivel5;} }
    svg{
      height: 25px;
      fill:$grisNivel5;
    }
}

.contFiltrosCitas{
  .ant-popover-inner-content{
    padding: 5px;
  }

  .newBtn{
    margin-top:10px;
    margin-bottom: 5px;
  }

  .padreFiltros{
  }

  .titleFilter{
    font-size: 17px;
    color:$grisNivel5;
    font-family: "Poppins-Medium";
    padding: 5px 10px;
  }

  .padreItemsFiltros{
    flex-direction: column;
  }

  .itemFiltro{
    padding: 5px;
    label{
      font-size: 15px;
      color:$grisNivel5;
      font-family: "Poppins-Regular";
      cursor: pointer;
    }
  }

}



.wrapperCalendar{
  overflow: hidden;
  position: absolute;
  z-index: 2;
  width: 80%;
  height: 0px;
  transition: max-height 0.5s;
  background: white;
  border-radius: 5px;
}

.myheadCalendar{
  padding: 10px 20px;
  background: $naranjaNivel2;
  color: $blanco;
  span,i{
    font-size: 15px;
    font-family: "Poppins-Medium";
    transition: all .5s;
  }
  i:hover{transform: scale(1.2); cursor: pointer;}
}

#fecha{
  &:focus ~ .wrapperCalendar, ~.wrapperCalendar:hover{
    max-height:auto;
    height: auto!important;
  }
}

.mycalendar{
  .ant-fullcalendar-header{
    display: none;
  }

  .ant-fullcalendar-column-header{
    text-align: center;
  }

  .ant-fullcalendar-cell{
    .ant-fullcalendar-date{
      margin: auto;
      height: 40px;width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      @include clearBorder;
      &:hover{ background: $grisNivel1; }
      &.ant-fullcalendar-date-select{
        background: $naranjaNivel1;
        color:$blanco!important;
      }
      .ant-fullcalendar-value{background: none;}
    }
    &.ant-fullcalendar-selected-day{
      .ant-fullcalendar-date{
        background: $naranjaNivel1;
        .ant-fullcalendar-value{color:$blanco;}
      }
    }
    &.ant-fullcalendar-today{
      @include clearBorder;
      .ant-fullcalendar-value{@include clearBorder;}
    }

  }
  
  .ant-fullcalendar-column-header-inner{
    font-family: "Poppins-SemiBold";
    font-size: 16px;
    text-transform: capitalize;
  }

}

.conCitas{
  width: 80%;
  margin: auto;
  max-width: 1400px;
  margin-top: 5px;
  transition: all .5s;
  *{transition: all .5s;}
  .instanciaCita{
    border-bottom: 1px solid $grisNivel2;
    border-right: 1px solid $grisNivel2;
    cursor: pointer;
    //background: cyan;margin: 5px auto;
    height: 60px;
    .horas{
      flex-direction: column;
      
      min-width: 45px;
      min-height: 60px;
      max-width: 45px;
      max-height: 60px;

      text-align: center;
      background-color: $grisNivel1;
      border: 1px solid $grisNivel2;
      border-bottom: none;
      span{display: block;}
    }
    .texto{
      height: 60px;
      width: unquote("calc( 100% - 45px )");
      &.descanso{background: $grisNivel1;}
      .barras{
        height: 100%;
        width: 10px!important;
        margin-right: 15px;
        &.pendiente{background: #848484;}
        &.confirmada{background: #22B573;}
        &.noasistio{background: #ED3B48;}
        &.asistio{background: #37C8EF;}
        &.cancelada{background: #ED3B48;}
        &.nodisponible{}
      }

      .inTexto{
        flex-direction: column;
        width: 97%;
        span{ color: $grisNivel5; }
        label{
          

          white-space: pre-wrap;
          word-break: break-word;
          overflow: hidden;
          max-height: 30px;
          line-height: 15px;
          width: 100%;          
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    &:hover{ background-color:transparentize($grisNivel1,.6); }
    &:nth-child(1){ border-top: 1px solid $grisNivel2; }
    /*&.cuadrito{
      border: 1px solid $grisNivel2;
      margin-bottom:5px;
      margin-right: 5px;
      width: 150px;
      height: 100px;
      border-radius:5px;
      position: relative;
      .horas{
        flex-direction: initial;
        width: 100%;
        height: 40px;
        border: none;
        border-radius:5px;
      }
      .barras{
        position: absolute;
        height: 20px;
        width: 20px;
        top: 20px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 100%;
      }
    }*/
  }

  .nonResult{
    height: 450px;
    img{height: 430px;}
    flex-direction: column;
    &:after{
      content:'¡ No se encontraron resultados !';
      color: $colorGrisBajo;
      display: block;
      font-size: 25px;
    }
  }
}