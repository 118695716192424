@import './../color.scss';
//@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
$layout-breakpoint-xsmall: 576px;
$layout-breakpoint-small: 768px;
$layout-breakpoint-medium: 992px;


.loadLogin{
    display: block;
    text-align: center;
    padding: 10px;
    label{
        display: block;
        text-align: center;
        margin: 5px auto;
        color: $naranjaEnd;
        font-weight: bold;
        font-size: 17px;
    }
}

.cont-Login{
    height: 100vh;
    width: 100vw;
    position: relative;
    overflow-y: scroll;
    overflow-x:hidden; 
    background-color:$grisNivel1;
    &::-webkit-scrollbar { width: 12px; }
    &::-webkit-scrollbar-button{ background:white; }
    &::-webkit-scrollbar-track {background:white;} 
    &::-webkit-scrollbar-thumb { background-color:$naranjaEnd; }
}

.miAntModal{
    .ant-modal-title{
        font-weight: bolder;
        color: $colorGrisFuerte;
        font-size: 17px;
    }
    .ant-modal-header,.ant-modal-footer{
        border: none;
        padding: 30px;
        border-radius: 20px!important;
    }
    .ant-modal-content{
        border-radius: 20px!important;
    }
}

.miAntModal,.modalPermisos{
   .ant-modal-footer{
        .btn{
            height: 35px;
            width: 150px;
            border: 1px solid $colorGrisMuyBajo;
            box-shadow: 0px 0px 10px 0px $colorGrisMuyBajo;
            border-radius: 5px;
            transition: all .5s;
            background-image: linear-gradient(to right, $naranjaStart , $naranjaEnd );
            color: white;
            display: inline-flex;
            font-size: 15px;
            &:hover{ box-shadow: 0px 0px 5px 3px transparentize($colorGrisBajo,0.5) }
            &:disabled{
                cursor:not-allowed;
                transform: scale(.95);
            }
            &.btn2{
                background: none;
                color: $colorGrisBajo;
                font-weight: bolder;
                opacity: .7;
                &:hover{ color: $naranjaEnd}
            }
            &.btn1{font-weight: bold;}
        }
    }
}

.mymodal{
    .inputs-group{
        display: block;
        position: relative;
        transition: all .3s;
        margin-top: -40px;
        padding: 10px;
        .inputs-label{
           font-size: 12px;
           font-weight: bold;
           color: $colorGrisMedio;
        }
        .inputs-input{
            margin-top: 10px;
            display: block;
            font-size: 14px;
            height: 30px;
            width: 100%;
            border:none;
            outline: none;
            position: relative;
            padding-left: 10px;
            border-bottom: 1px solid $colorGrisMedio;
            transition: all .3s;
            color: $colorGrisMedio;
            &:focus{ border-bottom-color: $naranjaEnd; border-bottom-width: 2px; }
        }   
    }
    .nota{
        color:$naranjaEnd;
        display: block;
        margin:10px auto;
        text-align: center;
        font-size: 12px;
        font-weight: bold;
        cursor: pointer;
        &.error{
            cursor:initial;
            display: none;
            color:$rojoNibiru;
        }
    }
    .body-alert{
        .icon-alert{
            height: 100px;
            display: block;
            margin: auto;
        }
        div{
            font-size: 14px;
            margin:30px auto;
            width: 80%;
            text-align: center;
            h4{
                font-size: 28px;
                color: $colorGrisFuerte;
                font-weight: bold;
            }
            .bloque1{
                color: $colorGrisMedio;
                display: block;
                .nota{
                    display: inline;
                    color: $colorGrisMedio;
                    font-weight: normal;
                    text-decoration: underline;
                }
            }
            .bloque2{ color: $colorGrisFuerte; font-weight: bold;}
            .notanotanota{
                color: $colorGrisMedio;
                font-weight: bold;
                display: block;
                margin-top: -20px;
                margin-bottom: -70px;
                span{
                    cursor:pointer;
                    font-weight: normal;
                    text-decoration: underline;
                }
            }
        }
        .nota{
            font-size: 15px;
            &.nopoint{
                cursor: context-menu;
                margin: 10px auto;
            }
        }
    }

    .txt{
        text-align: justify-all;
        margin-bottom: -40px;
    }
}

.logginInner{
    height: 100%;
    width: 100%;
    max-height: 550px;
    max-width:900px;
    min-width: 900px;
    border-radius: 5px;
    background-color:$blanco;

    &.special{max-height: 570px;}
}

.myScrollable{
  height: 100%;
  width: 50%;
  overflow: hidden;
  position: relative;
}

.wrapperLogin{
  height: 100%;
  width: 100%;
  position: relative;
  transition: transform 1s;
  &.ishThree{ transform: translateY(-100%); }
}

.login{
  
  width: 50%;
  padding:15px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;

  &::-webkit-scrollbar { width: 4px; }
  &::-webkit-scrollbar-button{display: none; }
  &::-webkit-scrollbar-track {display: none;} 
  &::-webkit-scrollbar-thumb { background-color:$grisNivel3; }

  &.loginFiller{ width: 100%;}
  
  &.pdd{ padding:15px 50px;}

  &.pdd2{ padding:15px 40px; }

  .labelForForm{
      font-family: "Poppins-Regular";
      font-size: 20px;
      height: 25px;
      color:$grisNivel5;
      margin: 0;
      &.mini{
        font-size: 18px;
        margin-top: -10px;
      }
  }
  .subLabelForForm{
    color: $naranjaNivel2;
    font-size: 16px;
    margin-top: -2px;
    &.mini{
      margin-top: 0px;
      margin-bottom: -10px;
    }
  }
  .itemBack{
    i{ font-size: 17px; svg{fill:$grisNivel3;} }
    span{ margin-left: 5px; font-size: 14px; color:$grisNivel3; transition: all .5s; }
    margin-bottom: 10px;
    opacity: .7;
    cursor: pointer;
    width: fit-content;
    &:hover{
      span{color:$naranjaNivel1;}
      svg {fill:$naranjaNivel1;transition: all .5s; }
    }
  }
  .iconSee{
    position: absolute;
    top: 50%;
    left: 90%;
    transform: translate(-50%,-50%);
    cursor: pointer;
    z-index: 2;
    svg {fill:$naranjaNivel1;}
    &.white{svg {fill:$blanco;}}
  }
  .bloqueAyuda{
    flex-direction: column;
    font-size: 14px;
    color:$grisNivel5;
    margin-bottom: 20px;

    span{
      font-family: "Poppins-Medium";
      opacity: .5;
    }
    label{
      font-family: "Poppins-Medium";
      font-weight: 600;
      cursor: pointer;
    }
    .myDivider{
      &:before{border-top-color: $grisNivel5;}
      &:after{border-top-color: $grisNivel5;}
      &.loginDivider{
        margin-bottom:20px;
      }
    }
  }
  .txtFoot{
    text-align: center;
    font-family: "Poppins-Medium"; 
    font-size: 12px;
    span{color:$naranjaNivel2;font-weight: bold;cursor: pointer;}
    margin: 0;
    &.black{span{color:$grisNivel5;font-weight: bold;cursor: pointer;}}
  }
  .inputBase{
      margin: 15px 0;
      background:none;
      padding: 0;
      position: relative;
      input{
        @include clearBorder;
        width: 100%;
        background-color: $grisNivel1;
        border-radius: 15px;
        padding: 5px 15px;
        font-family: "Poppins-Medium";
        font-size: 14px;
        color:$grisNivel5;
        &::placeholder{color:$grisNivel3;}
        &.mini::placeholder{font-size: 13px;}
      }
  }
  .linkChangeForm{
    font-family: "Poppins-Medium";
    text-align: center;
    color:$naranjaNivel1;
    font-size: 14px;
    cursor: pointer;
  }
}

.adjustLogin{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.sideTarjetaAuth{
    width: 50%;
    background-image:linear-gradient(to right, $naranjaNivel1 50%, $naranjaNivel2 );
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    border-radius: 5px 10px 10px 5px;
    //img{ margin: 10px 0; }
    .imgLogo{
        width:30%;
        margin-top: 30px;
        //border: 1px solid red;
    }
    .imgCard{
      margin-top: 30px;
      width: 80%;
      //border: 1px solid red;
    }
    &.sideTarjetaAuthRigth{border-radius: 10px 5px 5px 10px }
}

.inputBaseInliner{
  .inputBase{width: 65%;}
  .inputBaseTree{width: 30%;}
  height: 40px;
  margin: -5px 0;
}

.myDropdownClassName{
  max-height: 200px!important;
  max-width: 280px;
  .ant-select-tree-switcher{&.ant-select-tree-switcher_open,&.ant-select-tree-switcher_close{
      background: none;
      &:after{
        content: '';
        display: block;
        height: 100%;
        width: 280px;
        position: absolute;
        top: 0;
        z-index: 2;
      }
    }
  }
  .ant-select-tree-checkbox-checked{
    .ant-select-tree-checkbox-inner{
      //@include clearBorder;
      border-color: $naranjaNivel2;
      background: $naranjaNivel2;
    }
    &:after{border-color: $naranjaNivel2;}
  }
}

.inputBaseTree{
  
  .ant-select-selection{
    @include clearBorder;
    background-color: $grisNivel1;
    color: $grisNivel5;
    border-radius: 15px;
    font-family: "Poppins-SemiBold";
    display: flex;
    align-items: center;
  }
  svg{fill:$naranjaNivel2;}
  .ant-select-search__field__placeholder{color:$grisNivel3;}
  .ant-select-selection__rendered{width: 100%;}
  .ant-select-selection__placeholder{color:$grisNivel3;}
  input{ @include clearBorder; margin-left: 5px; }
}

.inputBase.ant-input-affix-wrapper{
  margin-top: 0!important;
  &.pss{
    .ant-input-suffix{
      font-size:15px;
      svg{fill: $naranjaNivel2;}
    }
  }
}

.btnBase{
  border-radius: 15px;
  font-size: 14px;
  padding: 8px 0;
  margin: 5px 0;
  cursor: pointer;
  position: relative;
  &.botonNaranja{
    background-color:$naranjaNivel2;
    color:$blanco;
  }
  &.botonNegro{
    background-color:$grisNivel5;
    color:$blanco;
  }
}
